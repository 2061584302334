import { useEffect } from 'react';
import CodeStore from 'stores/CodeStore';
import AnalysisStore from 'stores/AnalysisStore';

export const useStoreListeners = (selectedExcerptIdRef, selectRef, onNewExcerptId, onSelectText) => {
  useEffect(() => {
    function onChange() {
      if (selectedExcerptIdRef.current) {
        onNewExcerptId(selectedExcerptIdRef.current);
      } else if (selectRef.current) {
        onSelectText(
          selectRef.current.start, 
          selectRef.current.end, 
          selectRef.current.codeableId, 
          selectRef.current.codeableType, 
          selectRef.current.metadata
        );
      }
    }

    CodeStore.addChangeListener(onChange);
    AnalysisStore.addChangeListener(onChange);

    return () => {
      CodeStore.removeChangeListener(onChange);
      AnalysisStore.removeChangeListener(onChange);
    };
  }, [onNewExcerptId, onSelectText, selectedExcerptIdRef, selectRef]);
};
