import React, { useState, useRef } from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import {Button} from 'react-bootstrap'
import DescriptorComponent from './DescriptorComponent'
import { Scrollbars } from 'react-custom-scrollbars';
import GenericOnboardingMessage from './GenericOnboardingMessage';

var _ = require('underscore');

const EditDemographicView = ({ projectID, transcriptID, descriptors }) => {
  const [createDescriptor, setCreateDescriptor] = useState(false);
  const [saving, setSaving] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addAndSelectChoice = (descriptorID, choiceText) => {
    if (transcriptID) {
      QualCodeActions.createMultiChoiceAndSelect(transcriptID, descriptorID, choiceText);
    }
  };

  const createSelection = (choiceID) => {
    if (transcriptID) {
      QualCodeActions.createMultiChoiceSelection(transcriptID, choiceID);
    }
  };

  const updateDescriptor = (id, name, newPayload, updatePayload, deletePayload) => {
    QualCodeActions.updateDescriptor(id, name, newPayload, updatePayload, deletePayload);
  };

  const handleCreateDescriptor = (id, name, newPayload, updatePayload, deletePayload) => {
    if (projectID) {
      setSaving(true);
      QualCodeActions.createDescriptor(projectID, name, newPayload, updatePayload, deletePayload).then((data)=>{
        setCreateDescriptor(false);
        setSaving(false);
      })
    }
  };

  const handleDeleteDescriptor = (descriptorID) => {
    if (descriptorID && projectID) {
      QualCodeActions.deleteDescriptor(projectID, descriptorID);
    }
    setCreateDescriptor(false);
  };

  const handleAddDescriptor = () => {
    setCreateDescriptor(true);
    scrollToBottom();
  };

  const descriptorElements = descriptors.map((descriptor) => (
    <DescriptorComponent
      doneEditingCallback={updateDescriptor}
      key={descriptor.id}
      descriptor={descriptor}
      deleteCallback={handleDeleteDescriptor}
      createSelectionCallback={createSelection}
      addAndSelectChoiceCallback={addAndSelectChoice}
    />
  ));

  const renderContent = () => (
    <>
      <p>
        Add demographics and descriptors to the transcript in order to filter by it later.
      </p>
      {descriptorElements}

      {createDescriptor && (
        <DescriptorComponent
          descriptor={null}
          doneEditingCallback={handleCreateDescriptor}
          deleteCallback={handleDeleteDescriptor}
          addAndSelectChoiceCallback={addAndSelectChoice}
          disabled={saving}
        />
      )}

      <div style={{height:"200px", width:"10px"}}/>
      <div
        style={{ float:"left", clear: "both" }}
        ref={messagesEndRef}
      />
    </>
  );

  return (
    <div style={{padding:"10px", height:"100%"}}>
      <Scrollbars autoHide style={{height:"100%"}}>
        {(!descriptors.length && !createDescriptor) ? (
          <GenericOnboardingMessage
            header="Cut and splice data by descriptors"
            body="Assign descriptors in order to filter by them later."
            buttonText="Add A Descriptor"
            onClick={() => setCreateDescriptor(true)}
            intercomArticleId={process.env['INTERCOM_ARITCLE_DESCRIPTORS']}
            helpArticleText={'Learn more about descriptors'}
          />
        ) : renderContent()}
      </Scrollbars>

      {(descriptors.length > 0 || createDescriptor) && (
        <div style={{
          position: "absolute",
          left: "20px",
          bottom: "40px",
          zIndex: 10,
        }}>
          <Button
            style={{
              position: "relative",
              width: "280px"
            }}
            bsStyle="blue"
            onClick={handleAddDescriptor}
            disabled={createDescriptor}
          >
            <span style={{margin:"10px 50px"}}>Add Descriptor</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditDemographicView;
