import { v1 as uuidv1 } from 'uuid';

import {
  CREATE_CODE,
  ADD_CODE_TO_EXCERPT,
  DELETE_CODE_FROM_EXCERPT,
  CREATE_EXCERPT
} from 'constants/QualConstants';
import State from 'stores/State';
import asyncCodePromises from 'utils/AsyncCodePromises';
import descriptorReducer  from 'reducers/DescriptorReducer';
import transcriptMapper from 'mappers/TranscriptMapper';
import AppDispatcher from 'dispatcher/AppDispatcher';
import CodingActions from 'actions/CodingActions';

function reduceStateWithAction(actionType, data)
{
  const action = {
    actionType: actionType,
    data: data
  };

  State.set(descriptorReducer(action, State.get()));

  AppDispatcher.handleAction({
    actionType,
    data,
  })
}

var asyncUtil = {
  createCode: function(projectID, name) {
    const clientID = uuidv1();
    reduceStateWithAction(CREATE_CODE, {
        id: projectID,
        code: {
          name: name,
          id: clientID,
          count: 0,
          // Note: this will not be hard coded in the future
          parent_id: null,
          position: 1
        }
      });

    const addCodePromise = CodingActions.addCode({
      name: name,
      projectId: projectID,
      clientID: clientID
    });

    asyncCodePromises.setCode(clientID, addCodePromise);
    return clientID;
  },

  addCodeToExcerpt: function(codeID, excerptID, user_ids)
  {
    reduceStateWithAction(ADD_CODE_TO_EXCERPT, {
      code_id: codeID,
      excerpt_id: excerptID
    });

    return Promise.all([asyncCodePromises.getCode(codeID), asyncCodePromises.getExcerpt(excerptID)]).then((values)=>{
      const serverCodeID = values[0];
      const serverExcerptID = values[1];
      CodingActions.addCodeToExcerpt({
        id: serverExcerptID,
        code_id: serverCodeID,
        user_ids
      })
    });
  },

  removeCodeFromExcerpt: function(codeID, excerptID, user_ids)
  {
    reduceStateWithAction(DELETE_CODE_FROM_EXCERPT, {
      code_id: codeID,
      excerpt_id: excerptID,
      coders: user_ids
    });

    return Promise.all([asyncCodePromises.getCode(codeID), asyncCodePromises.getExcerpt(excerptID)]).then((values)=>{
      const serverCodeID = values[0];
      const serverExcerptID = values[1];
      CodingActions.deleteCodeFromExcerpt({
        code:{id:serverCodeID},
        excerpt:{id:serverExcerptID},
        user_ids
      })
    });
  },

  createNewExcerpt: function(codeID, transcriptID, codeableType, start, end, user_ids, text, metadata)
  {
    const excerptClientID = uuidv1();

    reduceStateWithAction(CREATE_EXCERPT, {
      code_id: codeID,
      transcript_id: transcriptID,
      excerpt: {
        id: excerptClientID,
        start: start,
        end: end,
        codeable_type: codeableType,
        ...metadata
      }
    })

    const excerptPromise = asyncCodePromises.getCode(codeID).then((serverCodeID)=>{
      const transcript = transcriptMapper(State.get(), transcriptID);
      if ( !transcript ) return null;

      return CodingActions.addExcerpt({
        start:start,
        end:end,
        code_id: serverCodeID,
        transcript_id: transcriptID,
        codeable_type: codeableType,
        text: text,
        clientID: excerptClientID,
        user_ids: user_ids || []
      });
    });

    // excerptPromise could be an empty {} if CodingActions throws an error
    // not sure if that will be a problem...
    // testing a error being thrown in cypress, just to see what the experience will be
    asyncCodePromises.setExcerpt(excerptClientID, excerptPromise);
    return [excerptClientID, excerptPromise];
  },

  deleteCode: function(codeID)
  {
    return asyncCodePromises.getCode(codeID).then((serverCodeID)=>{
      CodingActions.asyncDeleteCode(serverCodeID);
    });
  },

  renameCode: function(codeID, name)
  {
    return asyncCodePromises.getCode(codeID).then((serverCodeID)=>{
      CodingActions.asyncRenameCode(serverCodeID, name);
    });
  },

  getServerCode: function(codeID)
  {
    return asyncCodePromises.getCode(codeID);
  },

  getServerCodes: function(firstCodeId, secondCodeId) {
    return Promise.all([asyncCodePromises.getCode(firstCodeId),
                        asyncCodePromises.getCode(secondCodeId)]).
    then((values)=>{
      return {
        serverCodeId1: values[0],
        serverCodeId2: values[1]
      }
    });
  },

  nestCode: function(childCodeID, parentCodeID, position)
  {
    return Promise.all([asyncCodePromises.getCode(childCodeID), asyncCodePromises.getCode(parentCodeID)]).then((values)=>{
      const serverChildID = values[0];
      const serverParentID = values[1];
      CodingActions.asyncNestCode(serverChildID, serverParentID, position);
    });
  },
}




export default asyncUtil
