import React, { useState, useEffect } from 'react';
import ProjectsStore from 'stores/ProjectsStore';
import ShareStore from 'stores/ShareStore';
import UserStore from 'stores/UserStore';
import FeatureFlagStore from 'feature_flags/FeatureFlagStore';
import UserContext from 'contexts/UserContext';
import ProjectContext from 'contexts/ProjectContext';
import CollabActions from 'actions/CollabActions';
import QualCodeActions from 'actions/QualCodeActions';
import CodingActions from 'actions/CodingActions';

function getState(projectID) {
  if (!projectID) return {
    project: null,
    user: {
      ...(UserStore.getUser(projectID) || {}),
      featureFlagAICoding: FeatureFlagStore.hasAICoding(),
    },
  };

  return {
    project: ProjectsStore.getProject(projectID),
    user: {
      ...(UserStore.getUser(projectID) || {}),
      featureFlagAICoding: FeatureFlagStore.hasAICoding(),
    },
    ...ShareStore.getCollaborators(projectID),
  };
}

const ProjectInfoWrapper = ({ projectID, isSubscriptionActive, children, setProjectID }) => {
  const [state, setState] = useState(getState(projectID));

  const loadProject = (id) => {
    // Wrap each action in setTimeout to avoid nested dispatches
    setTimeout(() => {
      QualCodeActions.loadProject(id);
    }, 0);
    setTimeout(() => {
      CodingActions.loadCodes(id);
    }, 0);
    setTimeout(() => {
      CollabActions.getCollabList(id);
    }, 0);
    setTimeout(() => {
      QualCodeActions.loadCodeBook(id);
    }, 0);
    setTimeout(() => {
      QualCodeActions.loadCodeBar(id);
    }, 0);
  }
  
  useEffect(() => {
    if ( !projectID ) return;
    loadProject(projectID);      
  }, [projectID]);


  const onChange = () => {
    setState(getState(projectID));
  }


  useEffect(() => {
    ProjectsStore.addChangeListener(onChange);
    ShareStore.addChangeListener(onChange);
    UserStore.addChangeListener(onChange);
    FeatureFlagStore.addChangeListener(onChange);
    

    return () => {
      ProjectsStore.removeChangeListener(onChange);
      ShareStore.removeChangeListener(onChange);
      UserStore.removeChangeListener(onChange);
      FeatureFlagStore.removeChangeListener(onChange);
    };
  }, [projectID]);

  const role = state.project?.role;
  const roleIsEdit = role === 'edit';
  const hasEditPermission = roleIsEdit && isSubscriptionActive;

  return (
    <UserContext.Provider value={{
      user: state.user,
      hasEditPermission,
    }}>
      <ProjectContext.Provider
        value={{
          project: state.project,
          owner: state.owner,
          collaborators: state.collaborators,
          collaboratorsLoaded: state.collaboratorsLoaded,
          setProjectID: setProjectID
        }}
      >
        <div>
          {React.cloneElement(children, {
            project: state.project,
            user: state.user,
            hasEditPermission,
          })}
        </div>
      </ProjectContext.Provider>
    </UserContext.Provider>
  );
};

export default ProjectInfoWrapper;
