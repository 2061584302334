import React from 'react';
import ToTranscriptContentWrapper from './ToTranscriptContentWrapper';
import BeginCodingOnboardingWrapper from './BeginCodingOnboardingWrapper';

const CodeBookContainerContentWrapper = ({ hasCodes, children, hasEditPermission, onboardingState, height, projectID }) => {
    const {
        otherOnboarding
    } = onboardingState || {};

    const header = hasEditPermission ? "Code details in one glance, designed to help you spot patterns." : "This project is currently empty";
    const body = hasEditPermission ? "Explore your data—view your codebook or zoom into codes to see descriptions and snippets linked to transcripts." : "You're in view-only mode. As your team adds codes and transcripts, they will appear here.";
    const mediaType = "image";
    const mediaSrc = process.env['IMAGE_CODEBOOK_URL'];

    if (!!otherOnboarding) {    
        return (
            <ToTranscriptContentWrapper
                loaded={onboardingState.loaded}
                showOnboarding={otherOnboarding}
                header={header}
                body={body}
                mediaType="image"
                mediaSrc={process.env['IMAGE_CODEBOOK_URL']}
                height={height}
                hasEditPermission={hasEditPermission}
                projectID={projectID}
            >
                {children}
            </ToTranscriptContentWrapper>
        )
    }

    return <BeginCodingOnboardingWrapper
        loaded={onboardingState.loaded}
        showOnboarding={!hasCodes}
        header={header}
        body={body}
        mediaType={mediaType}
        mediaSrc={mediaSrc}
        height={height}
        hasEditPermission={hasEditPermission}
        projectID={projectID}
    >
        {children}
    </BeginCodingOnboardingWrapper>
}

export default CodeBookContainerContentWrapper;
