// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import DelveAccountBody from './DelveAccountBody'
import FieldGroup from './FieldGroup'
import DimensionsConstants from 'constants/DimensionsConstants'

const queryString = require('query-string');

class LoginDisplay extends React.Component {
  constructor () {
    super();
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this.state = {
      cachedEmail: null,
      email: "",
      password: "",
      ...AuthStore.getLoginState(),
    }
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    const parsed = queryString.parse(nextProps.location.search);
    const email = parsed.email;

    if ( email && email != prevState.cachedEmail)
    {
      return {
        ...prevState,
        cachedEmail: email,
        email: email
      }
    }
    return null;
  }

  _onChange () {
    this.setState({...AuthStore.getLoginState()})
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }

  onSubmit(e)
  {
    QualCodeActions.signIn(this.state.email, this.state.password);
    e.preventDefault()
  }

  onEmailChange (e) {
    this.setState({
      email: e.target.value
    });
  }

  onPasswordChange (e) {
    this.setState({
      password: e.target.value
    })
  }

  render () {
    return (
      <DelveAccountBody error={this.state.error.show ? {message:this.state.error.message, error:true} : {}} subHeader="Log In" success={this.state.success} reset signUp={SIGN_UP_FLAG}>
        <div style={{width:DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
          <form onSubmit={this.onSubmit} autoComplete="on">
            <FieldGroup
              id="formControlsEmail"
              type="email"
              name="email"
              label="Email address"
              value={this.state.email}
              onChange={this.onEmailChange}
              autoComplete="username email"
              placeholder="Enter email"
            />
            <FieldGroup 
              id="formControlsPassword"
              label="Password"
              type="password"
              name="password"
              onChange={this.onPasswordChange}
              value={this.state.password}
              autoComplete="current-password"
              placeholder="Enter password"
            />

            <div style={{width: "100%", marginTop:"30px"}}>
              <div style={{width: DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
                <Button bsStyle="blue" type="submit" style={{width:"100%"}}>Log In</Button>
              </div>
            </div>
          </form>
        </div>
      </DelveAccountBody>
    );
  }
}

export default LoginDisplay;