import React from 'react';
import ProjectInfoWrapper from '../ProjectInfoWrapper';
import SNavigation from '../SNavigation';

const TranscriptRoute = ({ transcriptID, projectID, page, subscriptionState, isSubscriptionActive, codeID, transcriptNotFoundError, rightSideBar }) => (
  <ProjectInfoWrapper
    projectID={projectID}
    isSubscriptionActive={isSubscriptionActive}
  >
    <SNavigation
      transcriptID={transcriptID}
      projectID={projectID}
      page={page}
      rightSideBar={rightSideBar}
      subscriptionState={subscriptionState}
      isSubscriptionActive={isSubscriptionActive}
      codeID={codeID}
      transcriptNotFoundError={transcriptNotFoundError}
    />
  </ProjectInfoWrapper>
);

export default TranscriptRoute;
