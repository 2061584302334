const NULL_STATE = {codes: []}
const ROOT_KEY = 'ROOT'
import codesHiearchyMapper from  'mappers/CodesHiearchyMapper'
import getDepth from 'mappers/nest/DepthMapper'
import recursiveBuild from 'mappers/codes/RecursiveCodesMapper'
import optimisticMapper from 'mappers/OptimisticMapper'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'
import topMarginMapper from 'mappers/codes/TopMarginMapper'
import bottomMarginMapper from 'mappers/codes/BottomMarginMapper'

const codesMapper = (state,
  projectID,
  dragCodeID,
  searchText
  ) => {
  try {
    state = optimisticMapper(state);
    dragCodeID = OptimisticMapperHelper.getCodeId(state, dragCodeID) || dragCodeID;
    const { mergeCodeId, mergeIntoCodeId } = state.mergeCodes;
    const mergeCode = OptimisticMapperHelper.getCode(state, mergeCodeId);
    const mergeCodeInto = OptimisticMapperHelper.getCode(state, mergeIntoCodeId);

    var parentMapper = codesHiearchyMapper(state, projectID);
    const dragCodeDepth = getDepth(parentMapper, dragCodeID)
    //const isTooDeep = dropCodeID !== newParentID;

    const collapseMapper = state.codeBook[projectID] || {};
    const collapseBarMapper = state.codeBar[projectID] || {};

    var findCodes = {match: false};

    const codes = state.entities.codes;

    const returnCodes = recursiveBuild(
      state,
      0,
      findCodes,
      codes,
      parentMapper,
      collapseMapper,
      collapseBarMapper,
      ROOT_KEY,
      dragCodeID,
      dragCodeDepth,
      false,
      searchText,
      mergeCode,
      mergeCodeInto,
      null,
      0,
      0
    );

    var children = returnCodes.children;

    if ( !findCodes['match'] && searchText && searchText.length > 0 )
    {
      children.unshift({ // new code
        name: searchText,
        newCode: true,
        filtered: false,
        ghostCode: false,
        id: -1,
        position: 0
      })
    }

    return {
      codes: children,
      topMargin: topMarginMapper(state, dragCodeID),
      bottomMargin: bottomMarginMapper(state, dragCodeID)
    };
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default codesMapper
