// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'
import SearchInfoScreen from 'search/SearchInfoScreen';

import {
  CONTENT_MAX_WIDTH,
  CONTENT_MIN_WIDTH
} from 'constants/DimensionsConstants';

function SearchBody({results, loadState}) {
  const searchParagraphs = (results || []).map((hit, index) =>
    <div key={index} style={{maxWidth:CONTENT_MAX_WIDTH - 200, minWidth: CONTENT_MIN_WIDTH}}>
      {hit['results'].map((match, resultIndex) =>
        {
          return (<SearchResult key={resultIndex}
                                match={match}
                                transcriptID={hit.transcript_id}
                                name={hit.name}
                                />);
        }
      )}
    </div>
  );

  if (searchParagraphs.length == 0) {
    return <SearchInfoScreen/>
  }

  return (<div>
      {searchParagraphs}

      {(searchParagraphs.length == 0 && this.props.loadState.loaded) &&
        <div>
          <p>
            Make sure your search was spelled correctly, or try searching different keywords.
          </p>
        </div>
      } 
    </div> 
  )

}

class ProjectSearch extends React.Component {
  render () {
    const height = 100;

    return (
      <div style={{height:"100%", paddingLeft:"30px", paddingBottom: 0, paddingTop:0, margin: 0, width: "100%"}}>
        <SearchBar onSubmit={this.props.onSubmit}
                  searchText={this.props.searchText}
                  searchTerm={this.props.searchTerm}
                  onSearchTextChange={this.props.onSearchTextChange}
                  count={this.props.count}
                  total={this.props.total}
                  loadState={this.props.loadState}/>
        <Scrollbars autoHide style={{height:(this.props.height - height), padding: 10, margin: 0, width: "100%"}}>
          <SearchBody results={this.props.results} loadState={this.props.loadState}/>
        </Scrollbars>
      </div>
    );
  }
}

export default ProjectSearch;
