// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import UserStore from 'stores/UserStore'
import CashierStore from 'stores/CashierStore'
import couponMapper from 'mappers/CouponMapper'
import couponErrorMapper from 'mappers/CouponErrorMapper'
import trackingUtil from 'utils/TrackingUtil'
import SessionAPI from 'apis/SessionAPI'
import sessionMapper from 'mappers/SessionMapper'
import SubscriptionPresentation from 'sign_up/SubscriptionPresentation'
import CashierActions from 'actions/CashierActions'
import CouponAPI from 'apis/CouponAPI'

const queryString = require('query-string');

class SubscriptionDisplay extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = {
      user: UserStore.getUser(),
      ...CashierStore.getPlan(),
      subscribeError: CashierStore.getSubscribeError(),
    }
    this.subscribe = this.subscribe.bind(this);
  }

  _onChange () {
    this.setState({
      user: UserStore.getUser(),
      ...CashierStore.getPlan(),
      ...CashierStore.getSubscriptions(),
      subscribeError: CashierStore.getSubscribeError(),
    })
  }

  async _createSession() {
    const coupon_id = this._getPromo(this.props);

    this.session = SessionAPI.createSession(
      sessionMapper({coupon_id})
    ).catch((error)=>{return {error}});

    this.yearSession = SessionAPI.createSession(
      sessionMapper({interval: 'year', coupon_id})
    ).catch((error)=>{return {error}});
  }

  _getPromo(props) {
    if ( !props ) return null;
    return props.promo;
  }

  checkPromo(props, prevProps) {
    const prevPromo  = this._getPromo(prevProps);
    const currentPromo = this._getPromo(props)
    if ( prevPromo !== currentPromo && !!currentPromo )
    {
      CouponAPI.getPromo(currentPromo).then((coupon)=>{
        const success = couponMapper(currentPromo, coupon);
        if ( !success ) return;
        this.setState({success})
        trackingUtil.updateAttribute('last_promo_code', currentPromo);
      }).catch((e)=>{
        const promoError = couponErrorMapper(currentPromo, e);
        if ( !promoError ) return;
        this.setState({promoError});
      });

      this._createSession()
    }
  }

  componentDidMount () {
    UserStore.addChangeListener(this._onChange);
    CashierStore.addChangeListener(this._onChange);
    trackingUtil.logAmplitudeEvent('subscribe_page_viewed');

    setTimeout(()=>{
      QualCodeActions.fetchUser();
      CashierActions.fetchPlans();
      this.checkPromo(this.props);
      this._createSession();
    }, 0);
  }

  componentWillUnmount () {
    // NOTE: the way the code is currently working, the call back is being called
    // but this is then unmounted. Becauser it's unmounted it unsubscribes.
    // however I guess the callbacks are still in the queue
    // Not sure if there is a better way to design this...
    // App.jsx is causing this to hapen I believe...
    UserStore.removeChangeListener(this._onChange);
    CashierStore.removeChangeListener(this._onChange);
  }

  signOut (e) {
    QualCodeActions.signOut();
  }

  async subscribe () {
    this.setState({
      loading: true
    })
    const interval = this.isYearly(this.props) ? 'year' : 'month';

    // TODO: may need some sort of verification that this was successful...
    const session = await (
      this.isYearly(this.props) ? this.yearSession : this.session)

    if ( session.error ) {
      this.setState({
        stripeError: 'Something went wrong. Please refresh and try again.'
      })
      return
    }

    var stripe = Stripe(STRIPE_PUBLISHABLE_KEY);

    stripe.redirectToCheckout({
      sessionId: session.data.id
    });
  }

  getPlan() {
    const yearly = this.isYearly(this.props);
    const plan = yearly ? this.state.annual_plan : this.state.plan;
    return plan;
  }

  isYearly(props) {
    const parsed = queryString.parse(props.search);
    return !!parsed.plan;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkPromo(this.props, prevProps);
  }

  getError(state) {
    if ( !!state.promoError && state.promoError.error )
      return state.promoError;

    if ( !!state.stripeError ) {
      return {
        error: true,
        message: state.stripeError
      }
    }

    return state.subscribeError;
  }

  render () {
    const error = this.getError(this.state);

    const yearly = this.isYearly(this.props);
    const plan = this.getPlan() || {};
    const planPrice = plan && plan.amount ? plan.amount : null;
    const discount = this.state.annual_plan &&
                      this.state.annual_plan.discount ?
                      this.state.annual_plan.discount : null;
    const annual_plan_amount = plan.annual_plan_amount || null;

    return (
      <SubscriptionPresentation
        error={error}
        success={this.state.success}
        planPrice={planPrice}
        discount={discount}
        yearly={yearly}
        annual_plan_amount={annual_plan_amount}
        userEmail={this.state.user.email}
        loading={this.state.loading}
        onSubscribe={this.subscribe}
        onSignOut={this.signOut}
      />
    );
  }
}

export default SubscriptionDisplay;
