import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import DragDropSurveyLayout from './DragDropSurveyLayout';
import FileDropzoneSquare from './FileDropzoneSquare';
import { useCSVFileUpload } from 'hooks/surveys';

function SurveyUploadingContainer({ projectID, height, toastManager }) {
  const { csvFileID } = useParams();
  const history = useHistory();

  const { data: csvFile, error } = useCSVFileUpload(csvFileID, projectID, {
    onSuccess: (data) => {
      if (data.status === 'completed') {
        history.push(`/surveys/${data.survey_id}`);
      } else if (data.status === 'failed') {
        toastManager.add(`Error uploading CSV: ${data.error_log}`, { appearance: 'error', autoDismiss: false });
        history.push(`/projects/${projectID}/surveys/new`);
      }
    },
    onError: () => {
      toastManager.add('Error uploading csv.', { appearance: 'error', autoDismiss: false });
      history.push(`/projects/${projectID}/surveys/new`);
    }
  })
  
  return (
    <DragDropSurveyLayout height={height}>
      <FileDropzoneSquare
          isHover={false}
          nonHoverText={'Uploading CSV...'}
          loading={true}
      />
    </DragDropSurveyLayout>
  );
}

export default withToastManager(SurveyUploadingContainer);
