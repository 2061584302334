import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntercomArticle } from 'projects/hooks/useIntercomArticle';

function HelpButton({intercomArticle, children, bsStyle="blue"}) {
  const handleClick = useIntercomArticle(intercomArticle);

  return (
    <Button
      bsStyle={bsStyle}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}

export default HelpButton;
