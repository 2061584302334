import { useRef, useEffect } from 'react';

export const useCodeableRefs = (selectedExcerptId, select) => {
  const selectedExcerptIdRef = useRef(selectedExcerptId);
  useEffect(() => {
    selectedExcerptIdRef.current = selectedExcerptId;
  }, [selectedExcerptId]);

  const selectRef = useRef(select);
  useEffect(() => {
    selectRef.current = select;
  }, [select]);
  
  return {
    selectedExcerptIdRef,
    selectRef
  };
};
