import React, { useState } from 'react';
import PropTypes from 'prop-types';

function MediaContent({ mediaType, mediaSrc, header, maxWidth }) {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);

  const mediaStyle = {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '60vh',
    borderRadius: '16px',
    padding: '25px',
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserDrag: 'none'
  };

  const containerStyle = {
    display: 'inline-block',
    borderRadius: '8px',
    boxShadow: isMediaLoaded && mediaType === 'video' ? '2px 2px 8px rgba(0, 0, 0, 0.25)' : 'none',
    background: '#fff',
    maxHeight: '100%',
    opacity: isMediaLoaded ? 1 : 0,
    transition: 'opacity 0.3s ease-in',
    maxWidth: mediaType === 'video' ? '100%' : maxWidth || '100%'
  };

  if (!['video', 'image'].includes(mediaType)) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {mediaType === 'video' ? (
        <video
          style={mediaStyle}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsMediaLoaded(true)}
        >
          <source src={mediaSrc} type="video/mp4" />
        </video>
      ) : (
        <img
          style={mediaStyle}
          src={mediaSrc}
          alt={header}
          onLoad={() => setIsMediaLoaded(true)}
        />
      )}
    </div>
  );
}

MediaContent.propTypes = {
  mediaType: PropTypes.oneOf(['video', 'image']),
  mediaSrc: PropTypes.string.isRequired,
  header: PropTypes.string
};

export default MediaContent;
