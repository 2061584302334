import ColorConstants from 'constants/ColorConstants'

export const LEFT_PADDING = "15px"
export const MENU_PADDING = "8px"

export const sharedMenuItemStyle = {
  paddingLeft: LEFT_PADDING,
  paddingTop: MENU_PADDING,
  fontSize: "1.1em",
  cursor: "pointer",
  paddingBottom: MENU_PADDING,
}

export const headerStyle = {
  ...sharedMenuItemStyle,
  width: "100%",
  borderBottom: "1px solid " + ColorConstants.LIGHT_GREY,
  background: ColorConstants.MEDIUM_GREY,
}

export const bottomStyle = {
    ...sharedMenuItemStyle,
    borderTop: "1px solid " + ColorConstants.LIGHT_GREY,
    background: ColorConstants.MEDIUM_GREY,
    width: "100%",
}

export const selectedStyle = {
  ...headerStyle,
  color: "white"
}

export const footerStyle = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "60px",
  paddingBottom: "5px",
}
