import React from 'react';
import MediaMessage from './MediaMessage';

function ContentWrapper({
  loaded,
  showOnboarding,
  header,
  body,
  secondBody,
  children,
  helpArticleText,
  intercomArticleId,
  mediaType,
  mediaSrc,
  height,
  buttonText,
  onClick
}) {
  if (!loaded)
    return <></>

  if (!showOnboarding) {
    return children;
  }

  return (<MediaMessage
    header={header}
    body={body}
    onClick={onClick}
    buttonText={buttonText}
    mediaType={mediaType}
    mediaSrc={mediaSrc}
    height={height}
    intercomArticle={intercomArticleId}
    subButtonText={helpArticleText}
    secondBody={secondBody}
    maxWidth="1000px"
  />)
}

export default ContentWrapper;
