import React from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import { bottomStyle } from 'left_side_bar/LeftSideBarStyles';
import ColorConstants from 'constants/ColorConstants';

const SupportDropdownButton = ({linkStyle}) => {
  return (
    <div style={{...bottomStyle, margin: 0, marginTop: 20}}>
      <DropdownButton
        style={{
          width: "100%",
          padding: 0,
          margin: 0
        }}
        bsStyle="link"
        noCaret
        title={<div style={{
          color: linkStyle.color,
          fontSize: linkStyle.fontSize,
          cursor: linkStyle.cursor,
          marginBottom: 0,
          marginTop: 0,
          marginLeft: 10,
          color: ColorConstants.LIGHT_GREY
        }}>Support</div>}
        id="supportDropdown"
        dropup
      >
        <MenuItem href="http://help.delvetool.com/" target="_blank">
          Help center
        </MenuItem>
        <MenuItem href="https://delvetool.com/trainingvideos" target="_blank">
          Training videos
        </MenuItem>
        <MenuItem id="delve_help_link">
          Chat with team
        </MenuItem>
      </DropdownButton>
    </div>
  );
};

export default SupportDropdownButton;
