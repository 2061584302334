import React, { useState, useEffect, useRef, useContext } from 'react';
import ProjectsStore from 'stores/ProjectsStore'
import ConversationActions from './ConversationActions';
import useStreamHandler from 'utils/useStreamHandler';
import AnalysisStore from 'stores/AnalysisStore';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import Message from './Message';
import {MemoModalProvider} from 'projects/components/modal/MemoModalProvider';
import TranscriptCodePageContainer from 'projects/components/TranscriptCodePageContainer'
import {filterExcerptsByContent} from './CitationCreation';
import ProjectContext from 'contexts/ProjectContext';
import {projectContextToUserObject} from './chat_collaborator_util';
import ChatInput from './ChatInput';
import {BetaModalProvider, useModal} from 'projects/components/modal/BetaModalProvider';
import UserContext from 'contexts/UserContext';
import ChatInfoScreen from './ChatInfoScreen';
import { Redirect } from 'react-router-dom';
import CodeableContext from 'contexts/CodeableContext';
import RequiresExcerptsOnboardingWrapper from 'projects/components/RequiresExcerptsOnboardingWrapper';

const ProjectChatBetaModal = (props) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (user.featureFlagAICoding === false) {
      openModal();
    } else if (user.featureFlagAICoding === true) {
      closeModal();
    }
  }, [user.featureFlagAICoding, openModal, closeModal]);

  return <></>
};

const MessageList = ({
  messages,
  projectID,
  hasEditPermission,
  excerpts,
  selectedExcerptId,
  onExcerptSelected,
  collaborators,
  streamConfig,
  streamData
}) => {
  if (!messages || messages.length === 0) {
    return <ChatInfoScreen/>
  }

  return messages.map((message, index) => {
    const content = streamConfig.message_id === message.id ? streamData : message.content;
    
    return <Message
      key={index}
      message={message}
      content={content}
      postfix={message.postfix}
      excerpts={filterExcerptsByContent(excerpts, content)}
      selectedExcerptID={selectedExcerptId}
      projectID={projectID}
      hasEditPermission={hasEditPermission}
      name={collaborators[message.user_id] ? collaborators[message.user_id].name : 'Unknown User'}
      onExcerptSelected={onExcerptSelected}
    />
  })
}


const ProjectChatContainer = (props) => {
  const [chatText, setChatText] = useState('');
  const [messages, setMessages] = useState([]);
  const [excerpts, setExcerpts] = useState({});
  const [streamConfig, setStreamConfig] = useState({ url: null, headers: null });
  const streamData = useStreamHandler(streamConfig.url, streamConfig.headers);
  const projectContext = useContext(ProjectContext);

  const {
    selectedExcerptId,
    onExcerptSelected,
    onDeselect
  } = useContext(CodeableContext);

  const messagesEndRef = useRef(null);

  const addMessageWithoutDuplicates = (newMessage) => {
    setMessages(prevMessages => {
      if (prevMessages.find(m => m.id === newMessage.id)) return prevMessages;
      return [...prevMessages, newMessage];
    })
  }

  const scrollToBottom = () => {
    if ( !messagesEndRef ) return;
    if ( !messagesEndRef.current ) return;
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const getConversationID = (props) => {
    return props.match && props.match.params ? props.match.params.conversationID : null;
  }

  const loadConversation = (props) => {
    const conversationID = getConversationID(props);
    if (!conversationID) {
      setMessages([]);
    } else {
      ConversationActions.getConversation(conversationID)
      .then((response) => {
        const conversation = response.data || {messages: []};
        const sortedMessages = conversation.messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        setMessages(sortedMessages);

        const conversationProjectID = conversation.project_id;
        if (!!conversationProjectID && conversationProjectID !== props.projectID) {
          props.history.push({
            pathname: `/projects/${conversationProjectID}/chat/${conversationID}`,
            search: props.location.search
          });
        }
      })
    }
  }

  const onChange = () => {
    setExcerpts(AnalysisStore.getExcerpts(props.projectID))
  }

  const createAIConversationAndUpdateState = (conversationID) => {
    const projectID = props.projectID;

    if (!projectID) return;

    ConversationActions.createAIConversation(projectID, conversationID).then((response) => {
      const data = response.data || {};
      const message = data.message || {};
      const stream = data.stream || {};

      // if message id is already in messages, no need to do anything
      if (messages.find(m => m.id === message.id)) return;
  
      addMessageWithoutDuplicates(message);

      setStreamConfig({
        url: stream.stream_url,
        headers: data.auth,
        message_id: message.id
      });    
    });
  }
  

  const onSubmit = (e) => {
    e.preventDefault();

    const conversationID = getConversationID(props);

    // TODO: this is a bit hacky
    // if streamConfig.message_id is not null, then save the streamData to corresponding message in messages
    if (streamConfig.message_id) {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        const messageIndex = newMessages.findIndex(message => message.id === streamConfig.message_id);
        if (messageIndex > -1) {
          newMessages[messageIndex].content = streamData;
        }
        return newMessages;
      });
    }

    if (conversationID) {
      ConversationActions.createMessage(conversationID, chatText).then((response) => {
        setChatText('');

        addMessageWithoutDuplicates(response.data);
        createAIConversationAndUpdateState(conversationID);
      })
    } else {
      ConversationActions.createConversation(props.projectID, chatText).then((response) => {
        setChatText('');
        setMessages(response.data.messages);
        props.history.push({
          pathname: `/projects/${props.projectID}/chat/${response.data.id}`,
          search: props.location.search
        });
        createAIConversationAndUpdateState(response.data.id);
      })  
    }
  }

  useEffect(() => {
    ProjectsStore.addChangeListener(onChange);
    CodedTranscriptStore.addChangeListener(onChange);
    AnalysisStore.addChangeListener(onChange);

    return () => {
      ProjectsStore.removeChangeListener(onChange);
      CodedTranscriptStore.removeChangeListener(onChange);
      AnalysisStore.removeChangeListener(onChange);
    }
  }, [props.projectID]); 

  useEffect(() => {
    loadConversation(props);

    setTimeout(() => {
      onDeselect();
    }, 0);
  }, [props.match.params.conversationID]); // Only re-run the effect if props.chatID changes

  useEffect(scrollToBottom, [messages, streamData]);

  const collaborators = projectContextToUserObject(projectContext);

  const onRejectTerms = () => {
    props.history.push(`/projects/${props.projectID}`);
  }

  if ( !process.env['AI_FEATURE_FLAG'] && props.projectID ) {
    return <Redirect to={`/projects/${props.projectID}`} />
  }

  return (
    <RequiresExcerptsOnboardingWrapper
      loaded={props.onboardingState.loaded}
      showOnboarding={props.onboardingState.otherOnboarding}
      hasExcerpts={props.onboardingState.hasExcerpts}
      header="Feel supported with Delve's AI as your peer debriefer."
      body="Ask questions about your coded snippets."
      secondBody="First, code a transcript."
      projectID={props.projectID}
      hasEditPermission={props.hasEditPermission}
      helpArticleText="Learn more about AI chat"
      intercomArticleId={process.env['INTERCOM_ARTICLE_AI_FEATURES']}
      mediaType="image"
      mediaSrc={process.env['IMAGE_AI_CHAT_URL']}
    >
      <BetaModalProvider onReject={onRejectTerms}>
        <ProjectChatBetaModal/>
        <MemoModalProvider>
          <TranscriptCodePageContainer
            projectID={props.projectID}
            hasEditPermission={props.hasEditPermission}
          />

          <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%' }}
                className='chat'
                onClick={() => onDeselect()}>
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
              <MessageList
                messages={messages}
                projectID={props.projectID}
                hasEditPermission={props.hasEditPermission}
                excerpts={excerpts}
                selectedExcerptId={selectedExcerptId}
                collaborators={collaborators}
                streamConfig={streamConfig}
                streamData={streamData}
                onExcerptSelected={onExcerptSelected}
              />
              <div ref={messagesEndRef} /> 
            </div>
            <ChatInput
              projectID={props.projectID}
              onSubmit={onSubmit}
              chatText={chatText}
              setChatText={setChatText}
            />

          </div>
        </MemoModalProvider>
      </BetaModalProvider>
    </RequiresExcerptsOnboardingWrapper>
  )
}

export default ProjectChatContainer;
