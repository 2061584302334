import React, { useCallback } from 'react';
import {Button} from 'react-bootstrap'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import {ButtonToolbar} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import { CONTEST_MAX_CHARACTERS } from 'constants/GenericConstants'
import { CONTENT_MAX_WIDTH } from 'constants/DimensionsConstants'
import FileUtil from 'utils/FileUtil'
import FileDropzoneText from './FileDropzoneText';
import { useTranscriptDraft } from 'hooks/useTranscriptDraft';

const PADDING = 30;
const BOTTOM_PADDING = 150;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function NewTranscript({handleSubmit, height, isHover, projectID}) {
  const {
    transcriptName,
    transcriptDescription,
    handleNameChange,
    handleDescriptionChange,
    clearDraft
  } = useTranscriptDraft(projectID);

  const submit = useCallback((event) => {
    event.preventDefault();
    clearDraft();
    handleSubmit(transcriptName, transcriptDescription);
  }, [handleSubmit, transcriptName, transcriptDescription, clearDraft]);

  const diff = transcriptDescription.length - CONTEST_MAX_CHARACTERS;
  const tooLong = diff > 0;
  const tooShort = !transcriptDescription || transcriptDescription.length === 0;
  const copyAndPasting = transcriptName.length != '' || transcriptDescription != '';

  return (
    <div style={{paddingLeft:"30px", paddingTop:PADDING + "px", height:"100%", maxWidth: CONTENT_MAX_WIDTH + "px"}}>
        <form style={{height:"100%"}}>
                   <FormGroup controlId="formtranscriptName">
                     <ControlLabel style={{fontSize:".80em"}}>Transcript Name</ControlLabel>
                     <FormControl
                       componentClass="input"
                       placeholder=""
                       value={transcriptName}
                       onChange={handleNameChange}
                     />
                   </FormGroup>
                   <div style={{
                       position: "relative",
                     }}>
                     <FormGroup controlId="formControlsTextarea" style={{height:height - BOTTOM_PADDING - PADDING}}>
                       <ControlLabel style={{fontSize:".80em"}}>Add Text</ControlLabel>
                       <FormControl componentClass="textarea"
                           placeholder=""
                           style={{
                             height:height - BOTTOM_PADDING - PADDING - 20,
                             resize:'none',
                             background:isHover ? ColorConstants.LIGHT_BLUE : null,
                             borderColor:isHover ? ColorConstants.ACTIVE_BUTTON_BLUE : null
                           }}
                           value={transcriptDescription}
                           onChange={handleDescriptionChange}/>
                     </FormGroup>
                     {!copyAndPasting &&
                        <FileDropzoneText
                          isHover={isHover}
                          header={!isHover ? "Copy and paste a transcript" : "Drop files here."}
                          subheader={!isHover ? `Or drag and drop ${FileUtil.fileTypesOrCopy()} files` : null}
                        />
                     }
                   </div>
           <ButtonToolbar style={{width: "100%"}}>
             {tooLong &&
               <div style={{float:"right", color:ColorConstants.ERROR_TEXT}}>
                 {`${numberWithCommas(diff)}`} characters over the {`${numberWithCommas(CONTEST_MAX_CHARACTERS) }`} limit
               </div>
             }
             <Button 
               onClick={(e)=>{submit(e)}} 
               type="submit" 
               bsStyle="blue" 
               disabled={tooLong || tooShort}
               style={{width: "100%"}}
             >
                 Add Transcript
             </Button>
           </ButtonToolbar>

       </form>
    </div>
  );
} 

export default NewTranscript;
