import { useState, useEffect, useRef } from 'react';
import _ from 'underscore';
import LocalAPI from 'utils/LocalAPI';

export function useTranscriptDraft(projectID) {
  const [transcriptName, setTranscriptName] = useState('');
  const [transcriptDescription, setTranscriptDescription] = useState('');
  const debouncedSaveRef = useRef(null);

  useEffect(() => {
    const draft = LocalAPI.getTranscriptDraft(projectID);
    setTranscriptName(draft.name);
    setTranscriptDescription(draft.description);
  }, [projectID]);

  useEffect(() => {
    debouncedSaveRef.current = _.debounce((projectID, name, description) => {
      LocalAPI.saveTranscriptDraft(projectID, name, description);
    }, 500);
    
    return () => {
      if (debouncedSaveRef.current) {
        debouncedSaveRef.current.cancel();
      }
    };
  }, [projectID]);

  const handleNameChange = (event) => {
    setTranscriptName(event.target.value);
    debouncedSaveRef.current?.(projectID, event.target.value, transcriptDescription);
  };

  const handleDescriptionChange = (event) => {
    setTranscriptDescription(event.target.value);
    debouncedSaveRef.current?.(projectID, transcriptName, event.target.value);
  };

  const clearDraft = () => {
    if (debouncedSaveRef.current) {
      debouncedSaveRef.current.cancel();
    }
    LocalAPI.clearTranscriptDraft(projectID);
  };

  return {
    transcriptName,
    transcriptDescription,
    handleNameChange,
    handleDescriptionChange,
    clearDraft
  };
}
