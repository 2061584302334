import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from './ContentWrapper';
import OnboardingActions from 'actions/OnboardingActions';

const ToTranscriptContentWrapper = ({ 
  projectID, 
  hasEditPermission, 
  children, 
  loaded, 
  showOnboarding,
  ...rest 
}) => {
  const history = useHistory();

  const handleGetStarted = () => {
    if (hasEditPermission) {
      OnboardingActions.getStarted()
      history.push(`/projects/${projectID}/transcripts/new`);
    }
  };

  return (
    <ContentWrapper
      {...rest}
      loaded={loaded}
      showOnboarding={showOnboarding}
      buttonText={hasEditPermission ? "Add a transcript" : null}
      onClick={hasEditPermission ? handleGetStarted : null}
    >
      {children}
    </ContentWrapper>
  );
};

export default ToTranscriptContentWrapper;
