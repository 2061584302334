import React from 'react';
import SignupLeftContent from './SignupLeftContent';

const PQLLeftContainer = () => (
    <SignupLeftContent
        header="First, a few questions to tailor things for you."
        quote="“Overall, DELVE was a game changer for my qualitative research project. Its user-friendly interface, efficient data management, powerful analysis tool, seamless collaboration features and responsive support made it a must-have tool for me.”"
        author="Melynie T."
    />
);

export default PQLLeftContainer