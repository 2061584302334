import React from 'react';
import ColorConstants from 'constants/ColorConstants';

const DemographicDescriptorItem = ({ descriptor }) => {
  const selectedMultiChoices = (descriptor.selected_multi_choice || {}).choice;
  
  return (
    <div style={{margin:'25px 15px'}}>
      <p><strong>{descriptor.name}</strong></p>
      {selectedMultiChoices ?
        <p>
          {selectedMultiChoices}
        </p> :
        <p style={{color:ColorConstants.LIGHT_GREY}}>
          <em>Non specified</em>
        </p>
      }
    </div>
  );
};

export default DemographicDescriptorItem;
