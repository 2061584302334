import React, {useState, useEffect, useCallback} from 'react';
import FiltersDropdown from './FiltersDropdown';
import DescriptorDropdown from './DescriptorDropdown';
import {ButtonToolbar} from 'react-bootstrap';
import AnalysisStore from 'stores/AnalysisStore';
import FilterStore from 'stores/FilterStore';
import FilterActions from 'actions/FilterActions';
import { useSurveyList } from 'hooks/surveys/queries';

import {
    OP_DEFAULT
} from 'constants/OpConstants'

const FilterComponent = ({
  showLess,
  dropdownAbove,
  projectID,
  hideDescriptors,
  codeFilterButtonText,
  codeSubHeader,
  transcriptFilterButtonText,
  transcriptSubHeader,
  source,
  hideSurveys,
}) => {

    const [transcripts, setTranscripts] = useState([]);
    const [codes, setCodes] = useState([]);
    const [descriptors, setDescriptors] = useState([]);
    const [checkedCodeNames, setCheckedCodeNames] = useState([]);
    const [op, setOp] = useState(OP_DEFAULT);
    const [surveysWithMetadata, setSurveysWithMetadata] = useState([]);

    const { data: surveys, isLoading: surveysLoading } = useSurveyList(projectID);

    useEffect(() => {
      if (surveys && projectID) {
        setSurveysWithMetadata(AnalysisStore.getAnalysisSurveys(surveys, projectID).surveys);
      }
    }, [surveys, projectID]);

    const applyFilter = () => {
      FilterActions.filterByCodes(projectID, source);
    }
  
    const demographicFilters = (descriptors || []).map((descriptor, index) => {
        return (
            <DescriptorDropdown
                key={index}
                name={descriptor.name}
                codes={descriptor.multi_choices.map((choice) => {
                    return {
                        name: choice.choice,
                        id: choice.id,
                        count: choice.count,
                        checked: choice.checked
                    }
                })}
                onChecked={(id, checked) => onDemographicChecked(descriptor.id, id, checked)}
                onClearChecked={() => onDemographicCleared(descriptor.id)}
                applyFilter={applyFilter}
                dropdownAbove={dropdownAbove}
            />
        );
    });

    const onChecked = (codeID, checked) =>
    {
      if ( !projectID ) return;
  
      if ( checked )
      {
        FilterActions.filterUncheckCodeId(codeID, projectID, source);
      }
      else {
        FilterActions.filterCheckCodeId(codeID, projectID, source);
      }
    }

    const onClearChecked = () =>
    {
      if ( !projectID ) return;
  
      FilterActions.filterClearCheckedCodes(projectID, source);
      if ( showLess ) showLess();
    }

    const onTranscriptCleared = () =>
    {
      if ( !projectID ) return;
  
      FilterActions.transcriptCleared(projectID, source);
      if ( showLess ) showLess();
    }
  
    const onTranscriptChecked = (transcriptID, checked) =>
    {
      if ( !projectID ) return;
  
      if ( checked )
      {
        FilterActions.transcriptUnchecked(transcriptID, projectID, source);
      }
      else {
        FilterActions.transcriptChecked(transcriptID, projectID, source);
      }
  
      if ( showLess ) showLess();
    }
  
    const onDemographicCleared = (descriptorID) =>
    {
      if ( !projectID ) return;
      FilterActions.filterCleared(descriptorID, projectID, source);
      if ( showLess ) showLess();
    }
  
    const onDemographicChecked = (descriptorID, choiceID, checked) =>
    {
      if ( !projectID ) return;
  
      if ( checked )
      {
        FilterActions.filterUnchecked(descriptorID, choiceID, projectID, source);
      }
      else {
        FilterActions.filterChecked(descriptorID, choiceID, projectID, source);
      }
    }

    const onSurveyCleared = () =>
    {
      if ( !projectID ) return;
    
      FilterActions.surveyCleared(projectID, source);
      if ( showLess ) showLess();
    }
    
    const onSurveyChecked = (surveyID, checked) =>
    {
      if ( !projectID ) return;
    
      if ( checked )
      {
        FilterActions.surveyUnchecked(surveyID, projectID, source);
      }
      else {
        FilterActions.surveyChecked(surveyID, projectID, source);
      }
    
      if ( showLess ) showLess();
    }

    const anyClick = (e) => {
        if ( !projectID ) return;
        FilterActions.codeOpAllAction(projectID, source);
    }
    
    const allClick = (e) => {
        if ( !projectID ) return;

        FilterActions.codeOpAnyAction(projectID, source);
    }

    const onChange = useCallback(() => {
      if (!projectID) return;

      const codePayload = AnalysisStore.getCodesWithExcerptCounts(projectID);
      setCheckedCodeNames(codePayload.checkedCodeNames);
      setCodes(codePayload.codes);
      setOp(codePayload.op);
      setTranscripts(AnalysisStore.getAnalysisTranscripts(projectID).transcripts);
      setDescriptors(AnalysisStore.getAnalysisDescriptors(projectID).descriptors);

      if (surveys) {
        setSurveysWithMetadata(AnalysisStore.getAnalysisSurveys(surveys, projectID).surveys);
      }
    }, [projectID, surveys]);

    useEffect(() => {
      onChange()
      AnalysisStore.addChangeListener(onChange);
      FilterStore.addChangeListener(onChange);
  
      return () => {
          AnalysisStore.removeChangeListener(onChange);
          FilterStore.removeChangeListener(onChange);
      }
    }, [onChange]);

    return (
        <ButtonToolbar>
            <FiltersDropdown
                name="Codes"
                checkedCodeNames={checkedCodeNames}
                codes={codes}
                op={op}
                onChecked={onChecked}
                onClearChecked={onClearChecked}
                applyFilter={applyFilter}
                anyClick={anyClick}
                allClick={allClick}
                dropdownAbove={dropdownAbove}
                filterButtonText={codeFilterButtonText}
                subHeader={codeSubHeader}
            />

            <DescriptorDropdown
                name="Transcripts"
                codes={transcripts}
                onChecked={onTranscriptChecked}
                onClearChecked={onTranscriptCleared}
                applyFilter={applyFilter}
                dropdownAbove={dropdownAbove}
                filterButtonText={transcriptFilterButtonText}
                subHeader={transcriptSubHeader}
            />

            {!hideDescriptors && demographicFilters}

            {!hideSurveys &&
              <DescriptorDropdown
                  name="Surveys"
                  codes={surveysWithMetadata}
                  onChecked={onSurveyChecked}
                  onClearChecked={onSurveyCleared}
                  applyFilter={applyFilter}
                  dropdownAbove={dropdownAbove}
                  filterButtonText="Apply Filter"
                  subHeader="Filter by Surveys"
              />
            }
        </ButtonToolbar>
    );
}

export default FilterComponent;