import React, { useState, useEffect } from 'react';
import ToTranscriptContentWrapper from './ToTranscriptContentWrapper';
import BeginCodingOnboardingWrapper from './BeginCodingOnboardingWrapper';

const RequiresExcerptsOnboardingWrapper = ({
    loaded,
    showOnboarding,
    hasExcerpts,
    header,
    body,
    secondBody,
    mediaType,
    mediaSrc,
    height,
    hasEditPermission,
    projectID,
    helpArticleText,
    intercomArticleId,
    children
}) => {
    const [hadExcerpts, setHadExcerpts] = useState(hasExcerpts);

    useEffect(() => {
        if (hasExcerpts) {
            setHadExcerpts(true);
        }
    }, [hasExcerpts]);

    const WrapperComponent = showOnboarding ? ToTranscriptContentWrapper : BeginCodingOnboardingWrapper;

    return (
        <WrapperComponent
            loaded={loaded}
            showOnboarding={showOnboarding ? showOnboarding : !hadExcerpts}
            header={header}
            body={body}
            secondBody={secondBody}
            mediaType={mediaType}
            mediaSrc={mediaSrc}
            height={height}
            hasEditPermission={hasEditPermission}
            projectID={projectID}
            helpArticleText={helpArticleText}
            intercomArticleId={intercomArticleId}
        >
            {children}
        </WrapperComponent>
    );
};

export default RequiresExcerptsOnboardingWrapper;
