import { normalize, schema } from 'normalizr';

import {
    // PARSE FILES
    PARSE_FILES,
    PARSE_FILES_ERROR,
    PARSED_FILE,
    PARSED_FILE_ERROR,
    PARSE_FILES_SHOW,
    PARSE_FILES_HIDE,
    PARSE_FILE_CLEAR
  } from 'constants/FileConstants'
  
import InitialState from 'constants/InitialState';
import {
FILES__TOO_BIG,
FILES__TYPE_UNSUPPORTED
} from 'constants/ErrorConstants'
  

function parsedFileReducer(action, state = InitialState) {
  switch (action.actionType) {
    case PARSE_FILES:
    {
        const file_schema = new schema.Entity('files')
        const files_schema = new schema.Entity('project', {
            files:[file_schema]
        });

        var normalizedData = normalize(action.data, files_schema);
        return {
        ...state,
        dragDrop:{
            show: true,
            files: {
                ...state.dragDrop.files,
                ...normalizedData.entities.files
            }
        }
        }
    }
    case PARSE_FILES_ERROR:
    {
        let error = null;
        if ( action.error ) {
        error = {
            message: action.error.message,
            type: action.error.name
        }
        }

        return {
        ...state,
        dragDrop:{
            ...state.dragDrop,
            error: error,
        }
        }
    }
    break;
    case PARSED_FILE:
    {
        const fileData = action.data;
        const originalFile = state.dragDrop.files[fileData.id];
        if ( !originalFile ) return state;

        return {
        ...state,
        dragDrop: {
            ...state.dragDrop,
            files: {
            ...state.dragDrop.files,
            [fileData.id]:{
                ...originalFile,
                loaded: true,
                transcript_id: fileData.transcript_id
            }
            }
        }
        }
    }
    break;
    case PARSED_FILE_ERROR:
    {
        const fileData = action.data;
        const error = action.error;
        const originalFile = state.dragDrop.files[fileData.id];
        if ( !originalFile ) return state;

        let errorMessage = 'Something went wrong uploading this file';
        if ( error &&
                        ( error.name == FILES__TOO_BIG.name ||
                        error.name == FILES__TYPE_UNSUPPORTED.name ))
        errorMessage = error.message;

        return {
        ...state,
        dragDrop: {
            ...state.dragDrop,
            files: {
            ...state.dragDrop.files,
            [fileData.id]:{
                ...originalFile,
                error: {
                message: errorMessage,
                type: error.name || null
                },
                loaded: false,
            }
            }
        }
        }
    }
    break;
    case PARSE_FILES_SHOW:
        return {
        ...state,
        dragDrop: {
            ...state.dragDrop,
            show: true
        }
        }
        return state;
    break;
    case PARSE_FILES_HIDE:
        const remainingFiles = Object.fromEntries(
            Object.entries(state?.dragDrop?.files || []).filter(([_, file]) => !file?.loaded)
        );
        return {
            ...state,
            dragDrop: {
                ...state.dragDrop,
                show: false,
                files: remainingFiles
            }
        }
    break;
    case PARSE_FILE_CLEAR:
        return {
        ...state,
        dragDrop: {
            show: false,
            files: {},
        }
        }
    break;
    default:
        return null;
  }
}

export default parsedFileReducer;
