import React, { useCallback, useContext } from 'react';
import { MenuItem } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ProjectContext from 'contexts/ProjectContext';
import { useSurveyWithDependencies, useDeleteSurvey } from 'hooks/surveys';
import SubscriptionHeaderFrame from './SubscriptionHeaderFrame';
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import DeleteSurveyModal from 'surveys/DeleteSurveyModal';
import useModal from 'modals/useModal';

function SurveyHeader({
  hasEditPermission,
  subscriptionState,
  onShowCollabModal,
  showCollabModal,
  onRenew,
  roleIsEdit,
  isActive,
  history
}) {
  const { project } = useContext(ProjectContext);
  const projectId = project?.id;
  
  // Get IDs from URL params
  const { surveyID, surveyResponseID, surveyQuestionID } = useParams();

  // Use our new composite hook to get all survey data
  const { 
    survey,
    surveyResponse,
    surveyQuestion,
    isLoading,
    effectiveSurveyId
  } = useSurveyWithDependencies({
    surveyId: surveyID,
    responseId: surveyResponseID,
    questionId: surveyQuestionID
  });

  // Use our mutation hook
  const deleteMutation = useDeleteSurvey({
    onSuccess: () => {
      history.push(`/projects/${projectId}`);
    }
  });

  const onConfirmDelete = useCallback(() => {
    if (effectiveSurveyId) {
      deleteMutation.mutate(effectiveSurveyId);
    }
  }, [effectiveSurveyId, deleteMutation]);

  const {
    showModal,
    onShowModal,
    onCancelModal,
    onConfirmModal,
  } = useModal(onConfirmDelete);

  const onSurveyClicked = useCallback(() => {
    if (effectiveSurveyId) {
      history.push(`/surveys/${effectiveSurveyId}`);
    }
  }, [effectiveSurveyId, history]);

  if (isLoading) {
    return null;
  }

  const breadCrumb = (
    <Breadcrumb
      onClick={onSurveyClicked}
      first={survey?.title}
      second={surveyResponse?.response_text || surveyQuestion?.question_text}
    />
  );

  return (
    <div>
      <DeleteSurveyModal
        showModal={showModal}
        cancelDelete={onCancelModal}
        confirmDelete={onConfirmModal}
        isDeleting={deleteMutation.isPending}
      />

      <SubscriptionHeaderFrame
        name={breadCrumb}
        hasEditPermission={hasEditPermission}
        subscriptionState={subscriptionState}
        onShowCollabModal={onShowCollabModal}
        showCollabModal={showCollabModal}
        isActive={isActive}
        onRenew={onRenew}
        roleIsEdit={roleIsEdit}
        projectId={projectId}
        dropdownOptions={[
          <MenuItem 
            key="delete" 
            eventKey="1" 
            onClick={onShowModal}
            disabled={deleteMutation.isPending}
          >
            {deleteMutation.isPending ? 'Deleting...' : 'Delete Survey'}
          </MenuItem>
        ]}
      >
        <Header>{breadCrumb}</Header>
      </SubscriptionHeaderFrame>
    </div>
  );
}

export default SurveyHeader;