// app/javascript/projects/components/FullPageMessage.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import HelpButton from './collab/HelpButton'

const SubButton = ({to, text, intercomArticle}) => {
  if (intercomArticle) {
    return <HelpButton bsStyle="white" intercomArticle={intercomArticle}>
      {text}
    </HelpButton>
  }

  return (
    <LinkContainer to={to}>
      <Button bsStyle="white">
        {text}
      </Button>
    </LinkContainer>
  )
}

const ButtonBar = ({onClick, buttonText, subButtonTo, subButtonText, intercomArticle}) => {
  if (!buttonText || !onClick) return null;

  if (subButtonTo || intercomArticle) {
    return (
      <div style={{marginTop:"30px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Button onClick={onClick} bsStyle="blue" style={{width:"80%", marginRight: "10px"}}>
          {buttonText}
        </Button>
        <SubButton to={subButtonTo} text={subButtonText} intercomArticle={intercomArticle} />
      </div>
    );
  }

  return (
    <div style={{marginTop:"30px", textAlign: "center"}}>
      <Button onClick={onClick} bsStyle="blue" style={{width:"80%"}}>
        {buttonText}
      </Button>
    </div>
  );
}

const FullPageMessage = ({ header, body, secondBody, onClick, buttonText, subButtonTo, subButtonText, intercomArticle }) => {
  return (
    <div style={{textAlign:"center", maxWidth:'500px', margin:"0px auto", padding: "20px 20px", fontFamily: "Libre Baskerville, serif"}}>
      <h3>{header}</h3>
      <p style={{fontSize:"1.2em", marginTop:"30px"}}>
        {body}
      </p>

      {secondBody && <p style={{fontSize:"1.2em", marginTop:"30px"}}>
        {secondBody}
      </p>}


      <ButtonBar 
        onClick={onClick} 
        buttonText={buttonText} 
        subButtonTo={subButtonTo} 
        subButtonText={subButtonText} 
        intercomArticle={intercomArticle} 
      />
    </div>
  );
};

export default FullPageMessage;
