// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import PropTypes from 'prop-types';
import CloseModalButton from './CloseModalButton'

const LEFT_PADDING = 45;

const RowModal = ({
  bsSize,
  show,
  onHide,
  header,
  errorMessage,
  children,
  secondaryButtonText,
  secondaryButtonAction,
  buttonText,
  buttonAction,
  buttonDisabled
}) => {
  return (
    <div>
      <Modal bsSize={bsSize} show={show} onHide={onHide}>
          <Modal.Body style={{padding: 0, margin: 0}}>
          <div style={{margin:`15px 15px 15px ${LEFT_PADDING}px`}}>
            <div style={{float:"left", marginTop:'25px'}}>
              {header}
            </div>

            <CloseModalButton onHide={onHide}/>

            {errorMessage &&
              <Alert bsStyle='danger' style={{marginRight:25}}>{errorMessage}</Alert>
            }
          </div>
          <div style={{clear:"both"}}>
            {children}
          </div>

            <div style={{margin:25, clear:"both"}}>
              <div style={{float:"right", marginRight:20, marginBottom:15}}>
                {secondaryButtonText && secondaryButtonAction && 
                  <Button onClick={secondaryButtonAction} bsStyle="white" style={{marginRight: 10}}>{secondaryButtonText}</Button>
                }
                {buttonText && buttonAction && 
                  <Button onClick={buttonAction} bsStyle="blue" disabled={buttonDisabled}>{buttonText}</Button>
                }
              </div>
              <div style={{clear:"both"}}></div>
            </div>
          </Modal.Body>
      </Modal>
    </div>
  );
}

RowModal.propTypes = {
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
  header: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
}

RowModal.defaultProps = {
  buttonText: null,
  buttonAction: null,
  buttonDisabled: false,
  secondaryButtonText: null,
  secondaryButtonAction: null,
}

export default RowModal;
