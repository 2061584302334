import React from 'react';

// Stores
import CodeStore from 'stores/CodeStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import ProjectsStore from 'stores/ProjectsStore'
import CodeBook from './CodeBook'
import CodeBookContainerContentWrapper from './CodeBookContainerContentWrapper'

class CodeBookContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      codes: [],
    }

    this._onChange = this._onChange.bind(this);
  }

  _loadState(props) {
    const results = CodeStore.getCodes(props.projectID);
    this.setState({
      codes: results,
    });
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    CodeStore.addChangeListener(this._onChange);
    CodedTranscriptStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount() {
    CodeStore.removeChangeListener(this._onChange);
    CodedTranscriptStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    if ( nextProps.projectID != this.props.projectID ) {
      this._loadState(nextProps);
    }
  }

  render () {
    return (
      <CodeBookContainerContentWrapper
        onboardingState={this.props.onboardingState}
        height={this.props.height}
        hasEditPermission={this.props.hasEditPermission}
        projectID={this.props.projectID}
        hasCodes={this.props.onboardingState.hasCodes}
      >
        <CodeBook
          codes={this.state.codes}
          height={this.props.height}
          projectID={this.props.projectID}
          codeID={this.props.codeID}
          hasEditPermission={this.props.hasEditPermission}
        />
      </CodeBookContainerContentWrapper>
    );
  }
}

export default CodeBookContainer;
