import InitialState from 'constants/InitialState'
var _ = require('underscore');

import OnboardingConstants from 'constants/OnboardingConstants'

function onboardingReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case OnboardingConstants.ONBOARDING_GET_STARTED:
    {
      return {
        ...state,
        onboardingState:{
          getStarted: true,
        }
      };
    }
    break;
    default:
      return state;
  }
}

export default onboardingReducer
