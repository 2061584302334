// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState, useCallback, useEffect } from 'react';
import RowModal from './RowModal'
import FileErrorModal from './FileErrorModal'
import GenericConstants from 'constants/GenericConstants'
import { withRouter } from 'react-router';
import FileRow from './FileRow'
import ProjectsStore from 'stores/ProjectsStore'
import QualCodeActions from 'actions/QualCodeActions'

const FileLoadingModal = ({ history }) => {
  const [state, setState] = useState(ProjectsStore.getFiles());

  const handleStartCoding = useCallback(() => {
    const firstTranscript = state.files.find(file => file.transcript_id);
    if (firstTranscript) {
      onHide();
      history.push(`/transcripts/${firstTranscript.transcript_id}`);
    }
  }, [state.files, history]);

  const onClear = useCallback(() => {
    QualCodeActions.clearFileUpload();
  }, []);

  const onHide = useCallback(() => {
    QualCodeActions.dismissFile(true);
  }, []);

  const beforeUnloadFunction = useCallback((e) => {
    if (!state || !state.loading) {
      return;
    }
    e.preventDefault();
    e.returnValue = 'Files have not finished uploading';
    return 'Files have not finished uploading';
  }, [state]);

  const onChange = useCallback(() => {
    setState(ProjectsStore.getFiles());
  }, []);

  useEffect(() => {
    ProjectsStore.addChangeListener(onChange);
    window.addEventListener("beforeunload", beforeUnloadFunction);

    return () => {
      ProjectsStore.removeChangeListener(onChange);
      window.removeEventListener("beforeunload", beforeUnloadFunction);
    };
  }, [onChange, beforeUnloadFunction]);

  let errorMessage = null;
  if (state.hasTooBig || state.errorMessage) {
    errorMessage = state.errorMessage;
    if (state.hasTooBig) {
      errorMessage = `${errorMessage}. Files need to be under ${GenericConstants.CONTEST_MAX_CHARACTERS/1000},000 characters.`;
    }
  }

    return (
      <div>
        <RowModal
          show={state.show}
          onHide={onHide}
          buttonText='Start coding'
          buttonAction={handleStartCoding}
          buttonDisabled={!state.files.some(file => file.transcript_id)}
          secondaryButtonText='Add more transcripts'
          secondaryButtonAction={onHide}
          header={<h4 style={{marginTop:0}}>{state.message}</h4>}
          errorMessage={errorMessage}
        >
          {state.files.map((file, index) => {
            const hasError = !!file.error;
            return <FileRow
              name={file.fileName}
              loaded={!!file.loaded}
              transcript_id={file?.transcript_id}
              error={hasError}
              errorMessage={hasError ? file.error.message : null}
              onHide={onHide}
              file={file}
              key={index}
            />;
          })}
        </RowModal>
        <FileErrorModal
          type={state.errorParsing}
          onClear={onClear}
        />
      </div>
    );
};

export default withRouter(FileLoadingModal);
