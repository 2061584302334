import React from 'react';
import GenericOnboardingMessage from './GenericOnboardingMessage';
import {
    LIGHT_GREY
} from 'constants/ColorConstants'

const ArrowUp = () => (
    <div style={{ textAlign: 'center', marginBottom: '0px', marginTop: '20px' }}>
        <svg width="16" height="144" viewBox="0 0 16 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 2L2 10H7V142H9V10H14L8 2Z" fill={LIGHT_GREY}/>
        </svg>
    </div>
);

const SingleCodeEmptyState = () => (
    <GenericOnboardingMessage
            header="Highlight a quote, then click a code to apply it."
            helpArticleText='Watch video on how to code'
            intercomArticleId={process.env['INTERCOM_ARTICLE_GET_STARTED_VIDEO']}
        />
);

const NoCodeEmptyState = () => (
    <>
        <ArrowUp />
        <GenericOnboardingMessage
            header="Add a code."
            body="Highlight a quote and type the code name above."
            helpArticleText='Watch video on how to code'
            intercomArticleId={process.env['INTERCOM_ARTICLE_GET_STARTED_VIDEO']}
        />
    </>
);

const isCoded = (codes) => {
    if ( codes.length === 0 ) return false;
    const code = codes[0];
    return code.count > 0;
}

const shouldHideEmptyState = (codes, searchText) => {
    if ( searchText ) return true;
    const code_count = codes.length;
    if ( code_count > 1 ) return true;
    return isCoded(codes);
}

const CodeListEmptyState = ({codes, searchText, showEmptyStateOnboarding}) => {
    if ( !showEmptyStateOnboarding ) return null;

    const code_count = codes.length;
    if ( shouldHideEmptyState(codes, searchText) ) return null;

    const component = code_count === 1 ? <SingleCodeEmptyState/> : <NoCodeEmptyState/>
    
    return (<div
        style={{
            padding: '0px 20px'
        }}
    >
        {component}
    </div>)
  };

export default CodeListEmptyState;
