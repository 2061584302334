import React from 'react';

const SignupLeftContent = ({
    header,
    subheader,
    paragraph,
    quote,
    author
}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '2rem',
    color: 'white'
  }}>
    <div>
      <h1 style={{
        fontFamily: 'Libre Baskerville, serif',
        fontSize: '4rem',
        marginBottom: '1rem'
      }}>
        {header}
      </h1>
      {subheader && <h2 style={{
        fontFamily: 'Libre Baskerville, serif',
        fontSize: '3.5rem',
        marginBottom: '1.5rem'
      }}>
        {subheader}
      </h2>}
      {paragraph && <p style={{
        fontSize: '2rem',
        fontFamily: 'Libre Baskerville, serif',
      }}>
        {paragraph}
      </p>}
    </div>
    
    <div style={{
      border: '1px solid rgba(255, 255, 255, 0.3)',
      padding: '3rem',
      borderRadius: '0.5rem',
      marginTop: 'auto',
      marginBottom: '3rem',
      backgroundColor: 'transparent'
    }}>
      <p style={{
        fontSize: '1.3 rem',
        lineHeight: 1.5,
        marginBottom: '1rem'
      }}>
        {quote}
      </p>
      <p style={{
        fontWeight: 500
      }}>
        {author}
      </p>
    </div>
  </div>
);

export default SignupLeftContent;