// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap'

import ColorConstants from 'constants/ColorConstants'
var _ = require('underscore');
import { v1 as uuidv1 } from 'uuid';


class EditDescriptorComponent extends React.Component {
  constructor () {
    super();
    this.deleteClicked = this.deleteClicked.bind(this);
    this.doneClicked = this.doneClicked.bind(this);
    this.addChoice = this.addChoice.bind(this);
    this.updateNewChoiceString = this.updateNewChoiceString.bind(this);
    this.updateCurrentChoiceString = this.updateCurrentChoiceString.bind(this);
    this.updateDescriptorName = this.updateDescriptorName.bind(this);
    this.deleteNewChoice = this.deleteNewChoice.bind(this);
    this.deleteChoice = this.deleteChoice.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    this.state = {
      newChoicesIndices: [],
      newChoices: {},
      currentChoicesIndices: [],
      currentChoices: {},
      deleteChoices: [],
      descriptorName: ""
    }
  }

  _setupState()
  {
    const descriptor = this.props.descriptor || {};
    const multi_choices = descriptor.multi_choices || [];
    var currentChoicesIndices = multi_choices.map((choice) => {return choice.id});
    var currentChoices = multi_choices.reduce((accumulator, choice)=>{
      accumulator[choice.id] = Object.assign({}, choice);
      return accumulator
    }, {});

    var newChoices = {};
    var newChoicesIndices = [];

    if ( !this.props.descriptor )
    {
      while ( newChoicesIndices.length < 1 )
      {
        const newChoice = this._createNewChoice();
        newChoices[newChoice.id] = newChoice;
        newChoicesIndices.push(newChoice.id);
      }
    }

    this.setState({
      currentChoicesIndices: currentChoicesIndices,
      currentChoices: currentChoices,
      newChoicesIndices: newChoicesIndices,
      newChoices: newChoices,
      deleteChoices: [],
      descriptorName: this._getDescriptorName()
    });
  }

  _getDescriptorName()
  {
    return this.props.descriptor && this.props.descriptor.name ? this.props.descriptor.name : "";
  }

  _createNewChoice()
  {
    const id = uuidv1();
    return {id: id, choice: ""};
  }

  addChoice(e)
  {
    const newChoice = this._createNewChoice();
    var newChoicesIndices = this.state.newChoicesIndices;
    newChoicesIndices.push(newChoice.id);
    var newChoices = this.state.newChoices;
    newChoices[newChoice.id] = {id:newChoice.id, choice: ""};

    this.setState({
      newChoices: newChoices,
      newChoicesIndices: newChoicesIndices
    })
  }

  deleteClicked(e)
  {
    const descriptorID = this.props.descriptor ? this.props.descriptor.id : null;
    this.props.deleteCallback(descriptorID)
    e.preventDefault();
  }

  doneClicked(e)
  {
    e.preventDefault();

    const deletePayload = this.state.deleteChoices.map((choiceID) => {
      return {id: choiceID}
    });

    const descriptor = this.props.descriptor || {};
    const multi_choices = descriptor.multi_choices || [];

    const updatePayload = multi_choices.filter((choice) => {
      return !this.state.deleteChoices.includes(choice.id) &&
                this.state.currentChoices[choice.id].choice != choice.choice &&
                this.state.currentChoices[choice.id].choice.length > 0;
    }).map((choice) => {
      return this.state.currentChoices[choice.id];
    });

    const newPayload = this.state.newChoicesIndices.map((choiceID) => {
      return this.state.newChoices[choiceID];
    }).filter((choice)=> {return choice.choice.length > 0});

    const name = this._getDescriptorName() != this.state.descriptorName ? this.state.descriptorName : null;

    const descriptorID = this.props.descriptor ? this.props.descriptor.id : null;
    this.props.doneEditingCallback(descriptorID,
                                      name,
                                      newPayload,
                                      updatePayload,
                                      deletePayload
                                    )

    this.setState({
      edit: false
    });
  }

  updateNewChoiceString(e, choiceID)
  {
    var newChoices = this.state.newChoices;
    newChoices[choiceID].choice = e.target.value;
    this.setState({
      newChoices: newChoices
    })
  }

  deleteNewChoice(e, choiceID)
  {
    var newChoices = this.state.newChoices;
    delete newChoices[choiceID]
    var newChoicesIndices = this.state.newChoicesIndices.filter((newChoiceID) => newChoiceID != choiceID);
    this.setState({
      newChoices: newChoices,
      newChoicesIndices: newChoicesIndices
    })
  }

  componentDidMount() {
    this._setupState();
  }

  deleteChoice(e, choiceID)
  {
    var deleteChoices = this.state.deleteChoices;
    deleteChoices.push(choiceID);
    this.setState({
      deleteChoices: deleteChoices
    })
  }

  updateCurrentChoiceString(e, choiceID)
  {
    var currentChoices = this.state.currentChoices;
    currentChoices[choiceID].choice = e.target.value;
    this.setState({
      currentChoices: currentChoices
    });
  }

  updateDescriptorName(e)
  {
    this.setState({
      descriptorName: e.target.value
    })
  }

  getNonDeletedChoices()
  {
    return this.state.currentChoicesIndices.filter((choiceID) => {
      return !this.state.deleteChoices.includes(choiceID);
    });
  }

  onKeyUp(e) {
    if ( e.key === 'Enter' )
      this.addChoice();
  }

  editDisplay()
  {
    const mainStyle = {
      backgroundColor:ColorConstants.ULTRA_LIGHT_GREY,
      padding:'10px',
      width: "350px"
    };

    const inputStyle={marginLeft:"50px", marginRight:"10px", marginTop:"10px"};

    const nonDeletedChoices = this.getNonDeletedChoices();

    const options = nonDeletedChoices.map((choiceIndex, index) => {
      const choice = this.state.currentChoices[choiceIndex];
      return <div key={index}>
        <input style={inputStyle}
                key={index}
                value={choice.choice}
                onChange={(e)=>this.updateCurrentChoiceString(e, choice.id)}
                placeholder={`Option ${index + 1}`}
                onKeyUp={this.onKeyUp}
                />
              <Glyphicon glyph="remove" onClick={(e)=>this.deleteChoice(e, choice.id)}/>
      </div>
    });

    const newOptions = this.state.newChoicesIndices.map((choiceIndex, index) =>{
      const choice = this.state.newChoices[choiceIndex];
      return <div key={index}>
        <input style={inputStyle}
                key={index}
                value={choice.choice}
                onChange={(e)=>this.updateNewChoiceString(e, choice.id)}
                autoFocus={this.state.descriptorName.length > 0}
                placeholder={`Option ${options.length + index + 1}`}
                onKeyUp={this.onKeyUp}/>
              <Glyphicon glyph="remove" onClick={(e)=>this.deleteNewChoice(e, choice.id)}/>
      </div>
    });

    return (
      <div style={mainStyle}>
        <div style={{float:"left"}}>
          <input value={this.state.descriptorName}
                  onChange={this.updateDescriptorName}
                  placeholder="Descriptor Name"
                  autoFocus={this.state.descriptorName && this.state.descriptorName.length == 0}/>
          <div>
            {options}
            {newOptions}
            <div>
              <Button bsStyle="link" style={inputStyle} onClick={this.addChoice} disabled={this.props.disabled}>
                + Add Option
              </Button>
            </div>
          </div>
        </div>

        <div style={{float:"right", paddingLeft:"10px"}}>
          <div style={{clear:"both"}}>
            <Button bsSize="small" bsStyle="red" onClick={this.deleteClicked} block disabled={this.props.disabled}>Delete</Button>
            <Button bsSize="small" bsStyle="blue" onClick={this.doneClicked} block disabled={this.props.disabled}>Done</Button>
          </div>
        </div>

        <div style={{clear:"both"}}/>
    </div>)
  }

  render () {
    return (<div>{this.editDisplay()}</div>);
  }
}

export default EditDescriptorComponent;
