import React, {useCallback} from 'react';
import SurveyQuestionText from './SurveyQuestionText';
import { useSurveySelectCallbacks } from './surveySelectCallbacks';
import { useSurveyAnswer } from 'hooks/surveys/queries'; // Add this import

const SurveyResponseQuestion = ({ 
  responseId,
  questionText,
  questionType,
  questionId,
  surveyAnswer: initialSurveyAnswer, // Rename to indicate it's the initial data
  excerpts,
  selectedExcerptId,
  select,
  onMouseDown,
  disabled,
  onExcerptSelected,
  onDeselect,
  onGotoResponse,
  color,
  onSelectText,
  hasEditPermission
}) => {
  // Use the answer ID from the initial data to get the latest version from cache
  const answerId = initialSurveyAnswer?.id;
  
  // Get the answer from the cache if available, otherwise use the initial data
  const { data: cachedAnswer } = useSurveyAnswer(answerId);
  
  // Use the cached version if available, otherwise fall back to the prop
  const surveyAnswer = cachedAnswer || initialSurveyAnswer;
  
  const {
    onSurveyAnswerSelected,
    onParagraphClick
  } = useSurveySelectCallbacks(onSelectText, responseId, surveyAnswer?.id, questionId);

  const onGotoResponseWithId = useCallback(() => {
    if ( responseId ) 
      onGotoResponse(responseId);
  }, [onGotoResponse, responseId]);

  const textAnswer = surveyAnswer?.body;
  if ( !textAnswer ) {
    return null;
  }

  return (
    <SurveyQuestionText
      questionText={questionText}
      questionType={questionType}
      surveyQuestionId={questionId}
      surveyAnswer={surveyAnswer}
      excerpts={excerpts}
      selectedExcerptId={selectedExcerptId}
      select={select}
      onParagraphClick={onParagraphClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      onExcerptSelected={onExcerptSelected}
      onDeselect={onDeselect}
      onSurveyAnswerSelected={onSurveyAnswerSelected}
      onTitleClick={onGotoResponseWithId}
      color={color}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default SurveyResponseQuestion;