// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ProjectsStore from 'stores/ProjectsStore'

import QualCodeActions from 'actions/QualCodeActions'
import SideBarButton from './SideBarButton'
import SupportDropdownButton from './SupportDropdownButton'
import ColorConstants from 'constants/ColorConstants'
import { withRouter } from 'react-router';

import PageConstants from 'constants/PageConstants'
import { Scrollbars } from 'react-custom-scrollbars';

import ProjectPickerModal from './ProjectPickerModal'
import FileLoadingModal from './FileLoadingModal'
import AccountFooter from './AccountFooter'
import TranscriptLinks from 'left_side_bar/LeftSideTranscripts';
import LeftSideProject from 'left_side_bar/LeftSideProject';
import LeftSideSurveyList from 'left_side_bar/LeftSideSurveyList';

import { LEFT_PADDING, headerStyle, selectedStyle, footerStyle } from 'left_side_bar/LeftSideBarStyles';

const NULL_PROJECT = {transcripts:[], id:null, name:""};


class LeftSideBar extends React.Component {
  constructor () {
    super();
    this.state = {
      project:NULL_PROJECT,
      editableProjectTitle: null,
      footerHeight:5,
    };

    this._onChange = this._onChange.bind(this);
    this.showProjectModal = this.showProjectModal.bind(this);
    this.footerRef = null;
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  showProjectModal(e)
  {
    QualCodeActions.loadProjects();
    QualCodeActions.showProjectModal();
  }

  _onChange () {
    this._loadState(this.props);
  }

  _getProjectFromParam (props) {
    // const page = PageParserUtil.parse(props.match, props.location);
    var projectID = props.projectID;
    var transcriptID = props.transcriptID;

    const nullState = {};
    if ( !projectID && !transcriptID ) return nullState;
    var project = ProjectsStore.getProject(projectID);
    project = project ? project : NULL_PROJECT;
    return {project: project, showProjectModal: false};
  }

  _loadState(props) {
    var projectTranscriptState = this._getProjectFromParam(props);

    const footerHeight = this.footerRef ? this.footerRef.clientHeight : 60;

    this.setState({
      ...projectTranscriptState,
      footerHeight: footerHeight,
    });
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    if ( this.props.transcriptID != nextProps.transcriptID ||
          this.props.projectID != nextProps.projectID ||
          this.props.page != nextProps.page )
    {
      this._loadState(nextProps);
    }
  }

  getHeaderStyle(page) {
    return this.props.page == page ? selectedStyle : headerStyle;
  }

  onLinkClick (to) {
    this.props.history.push(to);
  }

  render () {
    const isOnUserPage = this.props.page === PageConstants.USER_PAGE;
    const projectName = isOnUserPage ? null : this.state.project.name;

    return (
      <div style={{background:ColorConstants.DARK_GREY, color:ColorConstants.LIGHT_GREY, height:"100%", maxWidth: "100%"}}>
      <ProjectPickerModal
        subscriptionState={this.props.subscriptionState}
        onCanceledNewProject={this.props.onCanceledNewProject}
      />
      <FileLoadingModal/>

      <Scrollbars autoHide style={{height: `calc(100% - ${this.state.footerHeight}px)`, maxWidth: "100%", marginRight: "0px"}}>
        <div style={{display: "flex", flexDirection: "column", minHeight: "100%"}}>
          <div style={{flex: 1}}>
            <LeftSideProject
              hasEditPermission={this.props.hasEditPermission}
              isOnUserPage={isOnUserPage}
              project={this.state.project}
              projectName={projectName}
              leftPadding={LEFT_PADDING}
              showProjectModal={this.showProjectModal}
            />

            <div style={{clear:"both"}}></div>

            {(this.props.hasEditPermission || isOnUserPage) &&
              <SideBarButton
                to={`/projects/` + this.state.project.id + `/search`}
                onClick={this.showProjectModal}
                isOnUserPage={isOnUserPage}
                linkStyle={this.getHeaderStyle(PageConstants.SEARCH_PAGE)}
              >
                Search
              </SideBarButton>
            }

            {(this.props.hasEditPermission || isOnUserPage) && !!process.env['AI_FEATURE_FLAG'] &&
              <SideBarButton
                to={`/projects/` + this.state.project.id + `/chat`}
                onClick={this.showProjectModal}
                isOnUserPage={isOnUserPage}
                linkStyle={this.getHeaderStyle(PageConstants.CHAT_PAGE)}
              >
                AI Chat +
              </SideBarButton>
            }


            <SideBarButton
              className="codesTab"
              to={`/projects/` + this.state.project.id + `/codes`}
              onClick={this.showProjectModal}
              isOnUserPage={isOnUserPage}
              linkStyle={this.getHeaderStyle(PageConstants.CODE_BOOK_PAGE)}
            >
              Codes
            </SideBarButton>

            {(isOnUserPage || this.props.hasEditPermission) &&
              <SideBarButton
                to={`/projects/` + this.state.project.id + `/analysis`}
                onClick={this.showProjectModal}
                isOnUserPage={isOnUserPage}
                linkStyle={this.getHeaderStyle(PageConstants.ANALYSIS_PAGE)}
              >
                Snippets
              </SideBarButton>
            }

            {(isOnUserPage || this.props.hasEditPermission) &&
              <SideBarButton
                to={`/projects/` + this.state.project.id + `/transcripts/new`}
                onClick={this.showProjectModal}
                isOnUserPage={isOnUserPage}
                linkStyle={this.getHeaderStyle(PageConstants.NEW_TRANSCRIPT_PAGE)}
              >
                Transcripts  +
              </SideBarButton>
            }

            <TranscriptLinks
              transcripts={(this.state.project || {}).transcripts}
              project={this.state.project}
              isOnUserPage={isOnUserPage}
              page={this.props.page}
              transcriptID={this.props.transcriptID}
              onLinkClick={this.onLinkClick}
            />

            {(isOnUserPage || this.props.hasEditPermission) &&
              <SideBarButton
                to={`/projects/` + this.state.project.id + `/surveys/new`}
                onClick={this.showProjectModal}
                isOnUserPage={isOnUserPage}
                linkStyle={this.getHeaderStyle(PageConstants.NEW_SURVEY_PAGE)}
              >
                Surveys +
              </SideBarButton>
            }

            <LeftSideSurveyList
              projectId={this.state.project.id}
              isOnUserPage={isOnUserPage}
              survey_id={this.props.survey_id}
              onLinkClick={this.onLinkClick}
            />
          </div>

          <SupportDropdownButton
            linkStyle={this.getHeaderStyle()}
            isOnUserPage={isOnUserPage}
          />
        </div>
        </Scrollbars>

        <div style={{...footerStyle, height:this.state.footerHeight}}>
          <div ref={(input) => {this.footerRef=input}}>
            <AccountFooter name={this.props.user.name} initials={this.props.user.initials}/>
          </div>
        </div>
    </div>
    );
  }
}

export default withRouter(LeftSideBar);
