import React, { useState, useEffect } from 'react';
import PageParserUtil from 'utils/PageParserUtil'
import { useHistory } from 'react-router-dom'
import newestTranscriptMapper from 'mappers/NewestTranscriptMapper'
import ProjectsStore from 'stores/ProjectsStore'
import PageLoadingComponent from './PageLoadingComponent'
import { useSurveyList } from '../../hooks/surveys/queries'

const ProjectBody = ({ match, location }) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [transcriptId, setTranscriptId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  
  const page = PageParserUtil.parse(match, location);
  const { data: surveys = [], isSuccess: surveysLoaded, isFetching: surveysRefetching } = useSurveyList(page.params.projectID);

  const redirect = () => {
    const page = PageParserUtil.parse(match, location);
    const pageProjectId = page.params.projectID;
    
    const mappedState = ProjectsStore.getStateWithMapper(
      (state) => newestTranscriptMapper(state, pageProjectId)
    );

    setLoaded(mappedState.loaded);
    setTranscriptId(mappedState.transcript?.id);
    setProjectId(mappedState.project?.id);
  };

  useEffect(() => {
    ProjectsStore.addChangeListener(redirect);
    redirect();
    
    return () => {
      ProjectsStore.removeChangeListener(redirect);
    };
  }, [match, location]);

  useEffect(() => {
    if (loaded && transcriptId) {
      history.push(`/transcripts/${transcriptId}`);
    }
  }, [loaded, transcriptId, history]);

  useEffect(() => {
    if ( !loaded ) return;
    if ( !surveysLoaded ) return;
    if ( !projectId ) return;
    if ( transcriptId ) return;
    if ( surveysRefetching ) return;

    if (surveys.length > 0) {
      history.push(`/surveys/${surveys[0].id}`);
    } else {
      history.push(`/projects/${projectId}/transcripts/new`);
    }
  }, [loaded, transcriptId, surveysLoaded, projectId, surveys, history, surveysRefetching]);

  return <PageLoadingComponent />;
};

export default ProjectBody;
