import React from 'react';
import SignupLeftContent from './SignupLeftContent';

const SubscriptionLeftContent = () => (
    <SignupLeftContent
        header="Almost there."
        subheader="Your 14-day free trial starts after this step."
        paragraph="We require a credit card—but don't worry, you can cancel anytime."
        quote="Because the pricing is monthly I could pay for the time I needed without being locked in for longer than required."
        author="Bev C."
    />
);

export default SubscriptionLeftContent;