import React, { useState, useCallback, useEffect } from 'react';
import QualHeader from './header/QualHeader'
import QualLeftSideBar from './QualLeftSideBar'
import QualRightSideBar from './QualRightSideBar'
import QualContent from './QualContent'
import ColorConstants from 'constants/ColorConstants'
import TermsOfServiceModal from './TermsOfServiceModal'
import SubscriptionModal from './SubscriptionModal'
import PageConstants from 'constants/PageConstants'
import HeaderSubscribeModal from './HeaderSubscribeModal'
import { withRouter } from 'react-router';
import CodeableContextProvider from 'codeable/CodeableContextProvider';
import { useOnboardingState } from 'hooks/useOnboardingState';
import { useRightSideBar } from 'hooks/useRightSideBar';

import { Redirect } from 'react-router-dom'
import {
  UNCANCEL_SUBSCRIPTION,
  BLOCKER,
  PROJECT_BLOCKER
} from 'constants/AccountPageConstants'

import {
  HEADER_HEIGHT,
  LEFT_WIDTH,
  RIGHT_WIDTH,
  CONTENT_MIN_WIDTH
} from 'constants/DimensionsConstants'

const allStyle = {
  margin:0,
  border:0,
  position:"relative",
  // height:"500px", // this is set set by react
}

const leftStyle = {
    left:0,
    top:0,
    padding:0,
    margin:0,
    width:LEFT_WIDTH + "px",
    height:"100%",
    background:ColorConstants.DARK_GREY,
    position:"absolute",
    margin:"0px",
    boxSizing: "border-box",
}

const mainStyle = {
  marginLeft:LEFT_WIDTH + "px",
  marginRight:"0px",
  padding:"0px",
  height:"100%",
  //background:"blue",
  minWidth:LEFT_WIDTH + RIGHT_WIDTH + CONTENT_MIN_WIDTH + "px",
  boxSizing: "border-box",
}

const withoutRightSideBar = {
  ...mainStyle,
  minWidth: LEFT_WIDTH + CONTENT_MIN_WIDTH + "px",
}

const headerStyle = {
  //background:"orange",
  height: 50 + "px",
  width:"100%",
  borderBottom: "1px solid lightGray",
    boxSizing: "border-box",
}

const bodyStyle = {
  width:"100%",
  // height: "450px", set dynamically
  position:"relative",
  boxSizing: "border-box",
}

const contentStyle = {
    //background:"yellow",
    marginRight:RIGHT_WIDTH + "px",
    height:"100%",
    minWidth: CONTENT_MIN_WIDTH + "px",
    boxSizing: "border-box",
}

const rightStyle = {
  //background:"green",
  width:RIGHT_WIDTH + "px",
  position:"absolute",
  right:0,
  top:0,
  height:"100%",
  borderLeft: "1px solid lightGray",
  boxSizing: "border-box",
  paddingTop:"0px",
  marginTop:"0px",
}

const wrapperStyle = {
  padding:"10px 10px 10px 30px"
}

const SNavigation = (props) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(null);
  const onboardingState = useOnboardingState(props.projectID);

  const updateWindowDimensions = useCallback(() => {
    setDimensions({ 
      width: window.innerWidth, 
      height: window.innerHeight 
    });
  }, []);

  const onRenew = useCallback(() => {
    setShowBillingModal(UNCANCEL_SUBSCRIPTION);
  }, []);

  const onCanceledNewProject = useCallback(() => {
    setShowBillingModal(PROJECT_BLOCKER);
  }, []);

  const isActive = useCallback(() => {
    return !props.project || props.project.is_active === undefined || props.project.is_active;
  }, [props.project]);

  const isPastDue = useCallback(() => {
    return props.project && props.subscriptionState && props.subscriptionState.isPastDue;
  }, [props.project, props.subscriptionState]);

  const onHide = useCallback(() => {
    setShowBillingModal(null);

    if (isActive() === false || isPastDue()) {
      props.history.push('/users');
    }
  }, [isActive, isPastDue, props.history]);

  const isProjectNotFoundError = useCallback(() => {
    return !!props.projectNotFoundError && !!props.projectNotFoundError.error;
  }, [props.projectNotFoundError]);

  const isTranscriptNotFoundError = useCallback(() => {
    return !!props.transcriptNotFoundError && !!props.transcriptNotFoundError.error;
  }, [props.transcriptNotFoundError]);

  const shouldRedirect = useCallback(() => {
    if (!props.project) return false;
    if (!props.project.role) return false;
    if (!props.page) return false;
      
    if (
      props.project.role === 'view_only' ||
      !!props.subscriptionState.isCancelled
    ) {
      switch (props.page) {
        case PageConstants.TRANSCRIPT_PAGE:
        case PageConstants.DEMOGRAPHICS_PAGE:
        case PageConstants.PROJECT_PAGE:
        case PageConstants.USER_PAGE:
        case PageConstants.ROOT_PAGE:
        case PageConstants.CODE_BOOK_CODE_PAGE:
        case PageConstants.CODE_BOOK_PAGE:
        case PageConstants.SUBSCRIBE_PAGE:
        case PageConstants.CODED_BY_ME_PAGE:
        case PageConstants.COMPARISON_PAGE:
          return false;
        default:
          return true;
      }
    }
  }, [props.project, props.page, props.subscriptionState?.isCancelled]);

  const onShowCollabModal = useCallback((show) => {
    setShowCollabModal(show);
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  const allStyleUpdated = {
    ...allStyle,
    height: dimensions.height
  };

  const bodyHeight = dimensions.height - HEADER_HEIGHT;

  const bodyStyleUpdated = {
    ...bodyStyle,
    height: bodyHeight
  };

  const transcriptNotFoundError = isTranscriptNotFoundError();
  const projectNotFoundError = isProjectNotFoundError();

  const isArchived = (props.project || {}).status === 'archived';
  const notFoundError = transcriptNotFoundError || projectNotFoundError || isArchived;

  const rightSideBar = useRightSideBar({
    rightSideBarProp: props.rightSideBar,
    onboardingState,
    requiresExcerpts: props.requiresExcerpts,
    requiresCodes: props.requiresCodes,
    notFoundError,
    projectID: props.projectID
  });
  
  const role = (props.project || {}).role;
  const roleIsEdit = role === 'edit';
  const hasEditPermission = roleIsEdit && props.isSubscriptionActive;
  const isActiveValue = isActive();
  const isPastDueValue = isPastDue();
  const isSubscriptionOwner = (props.subscriptionState || {}).isOwner;
  const isProjectOwner = (props.project || {}).isOwner;

  return (
    <div style={allStyleUpdated} height={dimensions.height}>
      {shouldRedirect() &&
        <Redirect to={`/projects/${props.projectID}/codes`}/>
      }
      <SubscriptionModal page={props.page}/>
      <HeaderSubscribeModal
        show={!!showBillingModal || isActiveValue === false || !!isPastDueValue}
        subscriptionState={props.subscriptionState}
        page={(isActiveValue) ?
          showBillingModal : BLOCKER
        }
        hideBackButton={true}
        onHide={onHide}
        isSubscriptionOwner={isSubscriptionOwner}
      />
      <TermsOfServiceModal/>

      <div style={{...leftStyle, height:dimensions.height}}>
        <QualLeftSideBar 
          projectID={props.projectID}
          transcriptID={props.transcriptID}
          page={props.page}
          hasEditPermission={hasEditPermission}
          subscriptionState={props.subscriptionState}
          onCanceledNewProject={onCanceledNewProject}
        />
      </div>
      <div style={rightSideBar ? mainStyle : withoutRightSideBar}>
        <div style={headerStyle}>
          <div style={wrapperStyle}>
            <QualHeader
              notFoundError={notFoundError}
              hasEditPermission={hasEditPermission}
              subscriptionState={props.subscriptionState}
              isActive={isActiveValue}
              roleIsEdit={roleIsEdit}
              onRenew={onRenew}
              showCollabModal={showCollabModal}
              onShowCollabModal={onShowCollabModal}
            />
          </div>
        </div>
        <CodeableContextProvider
          projectId={props.projectID}
          codeableId={props.transcriptID}
        >
          <div style={bodyStyleUpdated}>
            <div style={rightSideBar ? contentStyle : {
              ...contentStyle,
              marginRight: "0px"
            }}>
              <QualContent
                projectID={props.projectID}
                transcriptID={props.transcriptID}
                codeID={props.codeID}
                page={props.page}
                height={bodyHeight}
                style={{width:"100%"}}
                projectNotFoundError={projectNotFoundError}
                transcriptNotFoundError={transcriptNotFoundError}
                hasEditPermission={hasEditPermission}
                subscriptionState={props.subscriptionState}
                isArchived={isArchived}
                isProjectOwner={isProjectOwner}
                onShowCollabModal={onShowCollabModal}
                onboardingState={onboardingState}
              />
            </div>
            {rightSideBar &&
              <div style={rightStyle}>
                <QualRightSideBar 
                  projectID={props.projectID}
                  transcriptID={props.transcriptID}
                  height={bodyHeight}
                  hasEditPermission={hasEditPermission}
                />
              </div>
            }
          </div>
        </CodeableContextProvider>
      </div>
    </div>
  );
}

export default withRouter(SNavigation);
