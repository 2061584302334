import React from 'react';
import {
  ACTIVE_BUTTON_BLUE
} from 'constants/ColorConstants';

const SplitContainer = ({ 
    leftContent,
    children,
    backgroundColor = ACTIVE_BUTTON_BLUE,
    leftWidthPercentage = 45
}) => {
    return (
      <div style={{
        height: '100vh',
        width: '100%',
        overflowY: 'auto'
      }}>
        <style>
          {`
            @media (max-width: 1000px) {
              .split-container {
                flex-direction: column;
              }
              .left-side {
                display: none;
              }
              .right-side {
                width: 100% !important;
              }
            }
          `}
        </style>
        <div 
          className="split-container"
          style={{
            display: 'flex',
            width: '100%',
            minHeight: '100%'
          }}
        >
          <div 
            className="left-side"
            style={{
              width: `${leftWidthPercentage}%`,
              backgroundColor: backgroundColor,
              paddingTop: '150px',
              paddingLeft: '100px',
              paddingRight: '100px',
              color: 'white'
            }}
          >
            {leftContent}
          </div>
          <div 
            className="right-side"
            style={{
              width: `${100 - leftWidthPercentage}%`,
              padding: '48px',
              backgroundColor: 'white',
              position: 'relative'
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );
};

export default SplitContainer;