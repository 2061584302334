import { useState, useCallback } from 'react';
import CodeStore from 'stores/CodeStore';

const useSelectedExcerpt = (initialExcerptId) => {
  const [excerptId, setExcerptId] = useState(initialExcerptId);
  const [excerpt, setExcerpt] = useState(null);
  const [select, setSelect] = useState(null);
  const [selectedExcerptVisible, setSelectedExcerptVisible] = useState(true);

  const setSafeSelect = useCallback((newSelect) => {
    if (newSelect) {
      setSelect({
        start: parseInt(newSelect.start),
        end: parseInt(newSelect.end),
        codeableId: parseInt(newSelect.codeableId),
        codeableType: newSelect.codeableType,
        metadata: newSelect.metadata,
      })
    } else {
      setSelect(null);
    }
  }, []);

  const onNewExcerptId = useCallback((excerpt_id) => {
    const selectedExcerpt = CodeStore.getExcerpt(excerpt_id);

    setExcerptId(excerpt_id ? (selectedExcerpt?.id || excerpt_id).toString() : null)
    setExcerpt(selectedExcerpt || null);
    setSafeSelect(selectedExcerpt ? {
      start: selectedExcerpt.start,
      end: selectedExcerpt.end,
      codeableId: selectedExcerpt.transcript_id,
      codeableType: selectedExcerpt.codeable_type
    } : null);
    setSelectedExcerptVisible(true);
  }, []);

  const onDeselect = useCallback(() => {
    setExcerptId(null);
    setExcerpt(null);
    setSelect(null);
    setSelectedExcerptVisible(true);
  }, []);

  const onSelectText = useCallback((start, end, codeableId, codeableType, metadata) => {
    const newExcerpt = CodeStore.getExcerptWithStartAndEnd(start, end, codeableId, codeableType);

    if ( !!newExcerpt?.id ) {
      onNewExcerptId(newExcerpt.id);
      return;
    }
    
    setExcerptId(null);
    setExcerpt(null);
    setSafeSelect({ start, end, codeableId, codeableType, metadata });
    setSelectedExcerptVisible(true);
  }, []);

  return {
    excerptId,
    excerpt,
    select,
    onNewExcerptId,
    onDeselect,
    onSelectText,
    selectedExcerptVisible,
    setSelectedExcerptVisible,
  };
};

export default useSelectedExcerpt;