import { useCallback } from 'react';
import asyncUtil from 'utils/AsyncUtil';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';

export const useCodeOperations = (
  projectId, 
  selectedExcerptIdRef, 
  selectRef, 
  answerRef,
  codingUserId, 
  onNewExcerptId
) => {
  // adding a code to an excerpt
  const addCodeToExcerpt = useCallback((code_id) => {
    // if there is no excerpt selected or no text selected nothing to do
    if (!selectedExcerptIdRef.current && !selectRef.current) return null;
  
    const {
      start,
      end,
      codeableId,
      codeableType,
      metadata
    } = selectRef.current || {};
  
    const user_ids = codingUserId ? [codingUserId] : null;
  
    // if there is an excerpt selected add the code to that excerpt
    if (!!selectedExcerptIdRef.current)
      asyncUtil.addCodeToExcerpt(code_id, selectedExcerptIdRef.current, user_ids);
    // if there is no excerpt selected but there is text selected create a new excerpt
    else if (!!selectRef.current && codeableId && start !== undefined && end !== undefined) {
      // Get text differently based on the codeable type
      let text;
      if (codeableType === "SurveyAnswer") {
        const body = answerRef.current?.body;
        text = !!body ? body.substring(start, end) : "";

        if (!text) {
          throw new Error("Could not find text for excerpt");
        }
      } else { // Default for Transcript and other types
        text = CodedTranscriptStore.getSelectedTextWithId(codeableId, start, end);
      }
  
      const [excerptClientID, promise] = asyncUtil.createNewExcerpt(
        code_id,
        codeableId,
        codeableType,
        start,
        end,
        user_ids,
        text,
        metadata || {}
      );
  
      // select that particular excerpt
      onNewExcerptId(excerptClientID);
    }
  }, [codingUserId, onNewExcerptId, selectedExcerptIdRef, selectRef, answerRef]);
  
  // when a new code is created
  const onNewCode = useCallback((codename) => {
    if (!projectId) return null;
    // create the code
    const clientID = asyncUtil.createCode(projectId, codename);
    // add the code to the excerpt
    addCodeToExcerpt(clientID);
  }, [projectId, addCodeToExcerpt]);

  const onRemoveCode = useCallback((code_id) => {
    if (!selectedExcerptIdRef.current) return null;
    const user_ids = codingUserId ? [codingUserId] : null;
    asyncUtil.removeCodeFromExcerpt(code_id, selectedExcerptIdRef.current, user_ids);
  }, [codingUserId, selectedExcerptIdRef]);
  
  // Handle excerpt selection
  const onExcerptSelected = useCallback((excerpt_id) => {
    onNewExcerptId(excerpt_id);
  }, [onNewExcerptId]);
  
  return {
    onExcerptSelected,
    onNewCode,
    addCodeToExcerpt,
    onRemoveCode
  };
};
