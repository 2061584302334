// SubscriptionPresentation.jsx
import React from 'react';
import { Button } from 'react-bootstrap';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ColorConstants from 'constants/ColorConstants';
import LoadingButton from 'projects/components/LoadingButton';
import DelveAccountBody from 'projects/components/DelveAccountBody';
import SubscriptionBorder from 'projects/components/SubscriptionBorder';
import SubscriptionRedirectComponent from 'projects/components/SubscriptionRedirectComponent';
import SplitContainer from './SplitContainer';
import SubscriptionLeftContent from './SubscriptionLeftContent';

const SubscriptionPresentation = ({
  error,
  success,
  planPrice,
  discount,
  yearly,
  annual_plan_amount,
  userEmail,
  loading,
  onSubscribe,
  onSignOut
}) => (
  <div>
    <style type="text/css">{`
      .btn-left, .btn-left:hover, .btn-left:focus, .btn-left:active {
          background-color: "white";
          color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
          border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
      }
      .btn-right, .btn-right:hover, .btn-right:focus, .btn-right:active {
          background-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
          color: #ffffff;
          border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
      }
    `}</style>

    <SplitContainer
        leftContent={<SubscriptionLeftContent />}
    >
        <DelveAccountBody
            error={error}
            success={success}
            onSignOut={onSignOut}
            currentStep={3}
        >
        <SubscriptionRedirectComponent />
        {planPrice && (
            <SubscriptionBorder>
                <div style={{ marginLeft: 105, marginTop: 15, width: 400 }}>
                {discount && (
                    <div
                    style={{
                        color: !yearly
                        ? ColorConstants.LIGHT_GREY
                        : ColorConstants.ACTIVE_BUTTON_BLUE,
                        marginLeft: '30px',
                        marginBottom: '5px',
                        fontSize: '0.8em',
                    }}
                    >
                    Save {discount}
                    </div>
                )}
                <div>
                    <ButtonToolbar>
                    <ButtonGroup bsSize="small">
                        <LinkContainer
                        activeClassName=""
                        to="/subscribe?plan=yearly"
                        style={{ cursor: 'pointer' }}
                        >
                        <Button bsStyle={!yearly ? 'left' : 'right'}>
                            Billed Annually
                        </Button>
                        </LinkContainer>
                        <LinkContainer to="/subscribe" style={{ cursor: 'pointer' }}>
                        <Button bsStyle={!yearly ? 'right' : 'left'}>
                            Billed Monthly
                        </Button>
                        </LinkContainer>
                    </ButtonGroup>
                    </ButtonToolbar>
                </div>
                </div>

                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 20,
                        height: 80,
                        textAlign: 'center',
                    }}
                >
                    <h2>${planPrice}</h2>
                    <p style={{ fontSize: '.9em' }}>per team member per month</p>
                    {annual_plan_amount && (
                        <p
                        style={{
                            fontSize: '.8em',
                            color: ColorConstants.LIGHT_GREY,
                        }}
                        >
                        <em>billed as ${annual_plan_amount} annually</em>
                        </p>
                    )}
                </div>

                {!!userEmail && (
                        <div style={{ barginTomp: 50 }}>
                            <LoadingButton
                            width={300}
                            centered
                            bsStyle="blue"
                            loading={loading}
                            onClick={onSubscribe}
                            >
                                Start Your 14 Day Free Trial
                            </LoadingButton>
                        </div>
                )}

                <div style={{marginBottom: 30}}/>


                <div style={{ borderBottom: '1px solid lightGray' }} />

                <div style={{ margin: 30 }}>
                    <h4 style={{textAlign:'center'}}>Simple qualitative analysis to help you hit your deadlines</h4>
                    <ul style={{paddingLeft: 30, paddingTop: 10, listStyleType: 'disc'}}>
                        <li style={{ margin: 5 }}>Intuitive coding interface</li>
                        <li style={{ margin: 5 }}>Unlimited projects, transcripts & codes</li>
                        <li style={{ margin: 5 }}>Keep ownership of your data</li>
                        <li style={{ margin: 5 }}>Easily export your research</li>
                        <li style={{ margin: 5 }}>Chat customer support</li>
                    </ul>
                </div>
            </SubscriptionBorder>
        )}
        </DelveAccountBody>
    </SplitContainer>
  </div>
);

export default SubscriptionPresentation;