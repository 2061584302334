import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import SurveyAPI from 'apis/SurveyAPI';

// Add survey answers to the query keys
export const surveyKeys = {
    all: ['surveys'],
    lists: () => [...surveyKeys.all, 'list'],
    list: (projectId) => [...surveyKeys.lists(), projectId],
    details: () => [...surveyKeys.all, 'detail'],
    detail: (id) => [...surveyKeys.details(), id],
    responses: () => [...surveyKeys.all, 'responses'],
    response: (id) => [...surveyKeys.responses(), id],
    questions: () => [...surveyKeys.all, 'questions'],
    question: (id) => [...surveyKeys.questions(), id],
    csvFiles: () => [...surveyKeys.all, 'csvFiles'],
    csvFile: (id) => [...surveyKeys.csvFiles(), id],
    questionResponses: () => [...surveyKeys.questions(), 'responses'],
    questionResponse: (id, offset = 0) => [...surveyKeys.questionResponses(), id, offset],
    // New key pattern for survey answers
    answers: () => [...surveyKeys.all, 'answers'],
    answer: (id) => [...surveyKeys.answers(), id],
};

// Cache extraction helper function
const extractAndCacheAnswers = (queryClient, data) => {
  // Check for survey_rows with nested answers
  if (data?.survey_rows?.length) {
    data.survey_rows.forEach(row => {
      if (row.answer) {
        // Cache individual answer
        queryClient.setQueryData(
          surveyKeys.answer(row.answer.id), 
          row.answer
        );
      }
    });
  }
  
  // Check for survey_answers array
  if (data?.survey_answers?.length) {
    data.survey_answers.forEach(answer => {
      // Cache individual answer
      queryClient.setQueryData(
        surveyKeys.answer(answer.id), 
        answer
      );
    });
  }
  
  // Return the original data unchanged
  return data;
};

// Modified base query hooks to extract and cache answers
export function useSurveyData(surveyId) {
  const queryClient = useQueryClient();
  
  return useQuery({
    queryKey: surveyKeys.detail(surveyId),
    queryFn: () => SurveyAPI.getSurvey(surveyId)
      .then(response => extractAndCacheAnswers(queryClient, response.data)),
    enabled: !!surveyId,
  });
}

export function useSurveyResponse(responseId) {
  const queryClient = useQueryClient();
  
  return useQuery({
    queryKey: surveyKeys.response(responseId),
    queryFn: () => SurveyAPI.getSurveyRow(responseId)
      .then(response => extractAndCacheAnswers(queryClient, response.data)),
    enabled: !!responseId,
    retry: (failureCount, error) => {
      // Don't retry on 404 or 403 errors
      if (error?.response?.status === 404 || 
          error?.response?.status === 403 ||
          error?.status === 404 ||
          error?.status === 403) {
        return false;
      }
      
      // For other errors, retry up to 3 times
      return failureCount < 3;
    }    
  });
}

export function useSurveyQuestionResponses(questionId, offset = 0) {
  const queryClient = useQueryClient();
  
  return useQuery({
    queryKey: surveyKeys.questionResponse(questionId, offset),
    queryFn: () => SurveyAPI.getSurveyQuestionResponses(questionId, offset)
      .then(response => extractAndCacheAnswers(queryClient, response?.data || {})),
    enabled: !!questionId,
    retry: (failureCount, error) => {
      // Don't retry on 404 or 403 errors
      if (error?.status === 404 || error?.status === 403) {
        return false;
      }
      
      // For other errors, retry up to 3 times
      return failureCount < 3;
    }
  });
}

export function useSurveyAnswer(answerId) {
  const queryClient = useQueryClient();
  
  // Use React Query's hook with enabled: false for null values
  // This ensures proper hook behavior for React
  const query = useQuery({
    queryKey: surveyKeys.answer(answerId || 'null'),
    queryFn: () => {
      // Only execute this if we have a valid answerId
      if (answerId == null) {
        return null;
      }
      
      // Always check for the most up-to-date cached data
      const cachedData = queryClient.getQueryData(surveyKeys.answer(answerId));
      
      if (cachedData) {
        return cachedData;
      }
      
      // If we don't have the data, return a placeholder
      return null;
    },
    // Only enable if we have a valid answerId
    enabled: answerId != null,
    // Critical settings to prevent refetching
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    // Set a very long stale time to prevent auto-refetching
    staleTime: 1000 * 60 * 60, // 1 hour
    // Don't remove from cache
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
    // Prevent background refetches
    structuralSharing: false,
    // This makes the hook return null immediately when disabled
    placeholderData: null
  });
  
  // Return the query result
  return query;
}

// The rest of your existing hooks can remain unchanged
export function useSurveyQuestion(questionId) {
  return useQuery({
    queryKey: surveyKeys.question(questionId),
    queryFn: () => SurveyAPI.getSurveyQuestion(questionId)
      .then(response => response.data),
    enabled: !!questionId,
  });
}

export function useSurveyList(projectId) {
  return useQuery({
    queryKey: surveyKeys.list(projectId),
    queryFn: () => SurveyAPI.getSurveys(projectId)
      .then(response => ((response?.data || {}).surveys || [])),
    enabled: !!projectId,
  });
}

// Composite hooks can remain mostly unchanged
export function useSurveyWithDependencies({ surveyId, responseId, questionId }) {
  const { 
    data: surveyResponse,
    isLoading: isLoadingResponse 
  } = useSurveyResponse(responseId);

  const {
    data: surveyQuestion,
    isLoading: isLoadingQuestion
  } = useSurveyQuestion(questionId);

  // Determine effective survey ID
  const effectiveSurveyId = surveyId || 
    surveyResponse?.survey_id || 
    surveyQuestion?.survey_id;

  const {
    data: survey,
    isLoading: isLoadingSurvey
  } = useSurveyData(effectiveSurveyId);

  return {
    survey,
    surveyResponse,
    surveyQuestion,
    isLoading: isLoadingSurvey || 
      (!!responseId && isLoadingResponse) || 
      (!!questionId && isLoadingQuestion),
    effectiveSurveyId
  };
}

// Mutations
export function useDeleteSurvey(options = {}) {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (surveyId) => SurveyAPI.destroySurvey(surveyId),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(surveyKeys.lists());
      if (options.onSuccess) {
        options.onSuccess(...args);
      }
    }
  });
}

export function useCSVFileUpload(csvFileId, projectId, options = {}) {
  const queryClient = useQueryClient();
  
  return useQuery({
    queryKey: surveyKeys.csvFile(csvFileId),
    queryFn: () => SurveyAPI.getCSVFile(csvFileId),
    enabled: !!csvFileId && !!projectId,
    refetchInterval: (data, query) => {
      // If we have an error and it's a 404, stop polling
      const status404 = query?.state?.fetchFailureReason?.status === '404';
      
      if (status404) {
        return false; 
      }
    
      // Otherwise, if data is missing or the status isn't terminal, keep polling
      if (!data || (data.status !== 'completed' && data.status !== 'failed')) {
        return 10000; // Poll every 10s
      }
    
      // If completed or failed, also stop
      return false;
    },
    refetchIntervalInBackground: true, 
    retry: (failureCount, error) => {
      // Don't retry on 404 errors
      if (error?.response?.status === 404 || error?.status === 404) {
        return false;
      }

      // For other errors, retry up to 6 times
      return failureCount < 6;
    },
    onSuccess: (data) => {
      if (data.status === 'completed') {
        queryClient.invalidateQueries(surveyKeys.list(projectId));
      }
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: (error) => {
      if (options.onError) {
        options.onError(error);
      }
    }
  });
}