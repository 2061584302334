import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from './ContentWrapper';

const BeginCodingOnboardingWrapper = ({children, projectID, hasEditPermission, ...rest}) => {

    const history = useHistory();

    const handleGetStarted = () => {
        if (hasEditPermission) {
            history.push(`/projects/${projectID}`);
        }
    };

    return <ContentWrapper
        {...rest}
        projectID={projectID}
        buttonText={hasEditPermission ? "Begin coding" : null}
        onClick={handleGetStarted}
    >
        {children}
    </ContentWrapper>
};

export default BeginCodingOnboardingWrapper;
