// app/javascript/projects/components/AnalysisDisplay.jsx

import {Button} from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import React from 'react';

import FilterActions from 'actions/FilterActions'

import Excerpt from './Excerpt'

import PageLoadingComponent from './PageLoadingComponent'
import FilterComponent from './FilterComponent'

class AnalysisDisplay extends React.Component {
  constructor () {
    super();

    this.state = {
      refHeight: 100
    }

    this.hideModal = this.hideModal.bind(this);
    this.filterRef = null;
    this.onClick = this.onClick.bind(this);
    this.showMore = this.showMore.bind(this);
    this.showLess = this.showLess.bind(this);
    this.onExcerptClicked = this.onExcerptClicked.bind(this);
  }

  showMore(e) {
    const projectID = this.props.projectID;
    if ( !projectID ) return;
    FilterActions.nextFilterPage(projectID, 'snippets');
  }

  showLess(e) {
    this.setState({
      showMore: false
    })
  }

  onClick(e) {
    this.props.onDeselect()
  }

  setModal(show) {
    this.setState({showModal: show})
  }

  hideModal (e) {
    this.setModal(false);
  }

  showModal () {
    this.setModal(true);
  }

  onExcerptClicked(excerptId) {
    this.props.onExcerptClicked(excerptId);
  }

  render () {
    const filterHeight = this.filterRef ? this.filterRef.clientHeight : 100;

    var excerptParagraphs = <div></div>
    const excerptsExist = (this.props.excerpts || []).length > 0;

    if ( excerptsExist )
    {
      excerptParagraphs = this.props.excerpts.map((excerpt, index) =>
        {
          return <Excerpt  key={excerpt.id}
                    excerpt={excerpt}
                    selected={excerpt.id==this.props.selectedExcerptID}
                    onTextClicked={this.onExcerptClicked}
                    hasEditPermission={this.props.hasEditPermission}
                    />
        }
      );
    }

    const moreToShow = this.props.loadingState.showMore;


    var loadingText = "";

    if ( !!this.props.loadingState.loading )
      loadingText = "";
    else if ( this.props.loadingState.count > 0 )
      loadingText = this.props.loadingState.count + " Snippets";
    else if ( this.props.loadingState.count == 0 )
      loadingText = "No Snippets Found"

    return (
        <div style={{height:this.props.height}}>
          <div style={{padding: "10px 30px"}} ref={(input) => {this.filterRef=input}}>
              <p><strong>Filter snippets by:</strong></p>
              <FilterComponent
                  projectID={this.props.projectID}
                  showLess={this.showLess}
                  source="snippets"
              />
          </div>

        <Scrollbars autoHide style={{height:this.props.height - filterHeight}}>
            {(excerptsExist || this.props.loadingState.loaded) &&
              <div onClick={this.onClick} style={{marginLeft:30, marginRight:30}} className='analysis-display'>
                <p style={{margin:0}}>{loadingText}</p>
                {excerptParagraphs}
                {moreToShow &&
                  <div style={{textAlign:'center', marginBottom:"20px"}}>
                    <Button bsStyle="white"
                      onClick={this.showMore}
                      disabled={!!this.props.loadingState.loading}
                      >Show More</Button>
                  </div>
                }
              </div>
            }
            {this.props.loadingState.loading && !excerptsExist &&
              <PageLoadingComponent/>
            }
        </Scrollbars>
        </div>
    );
  }
}

export default AnalysisDisplay;
