import React, { useState, useCallback } from 'react';

import FileDropZone from 'projects/components/FileDropZone';
import { withToastManager } from 'react-toast-notifications';
import SurveyAPI from 'apis/SurveyAPI';
import DragDropSurveyLayout from './DragDropSurveyLayout';
import FileDropzoneSquare from './FileDropzoneSquare';
import ContentWrapper from 'projects/components/ContentWrapper';

function DragDropSurveyContainer({ projectID, height, onboardingState, history, toastManager }) {
  const [isHover, setIsHover] = useState(false);
  const [getStartedClick, setGetStartedClick] = useState(false);

  const onGetStartedClick = useCallback(() => {
    setGetStartedClick(true);
  }, []);
  
  const MAX_FILE_SIZE_MB = parseInt(process.env.MAX_CSV_FILE_SIZE, 10) || 5;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const onDrop = useCallback((acceptedFiles) => {
    setIsHover(false);

    if (acceptedFiles.length > 1) {
      toastManager.add("Please upload one csv at a time.", { appearance: 'error', autoDismiss: true });
      return;  
    }

    const file = acceptedFiles[0];

    // check if file is a csv
    if (file.type !== 'text/csv') {
      toastManager.add("Please upload a csv file.", { appearance: 'error', autoDismiss: true });
      return;
    }

    // check if file size is within the limit
    if (file.size > MAX_FILE_SIZE_BYTES) {
      toastManager.add(`File size should be less than ${MAX_FILE_SIZE_MB}MB.`, { appearance: 'error', autoDismiss: true });
      return;
    }

    return SurveyAPI.uploadCSVFile(projectID, file).then((data) => {
      history.push(`/projects/${projectID}/surveys/new/${data.id}`);
    }).catch((error) => {
      const errorMessage = error.response?.data?.errors?.[0] || `Upload failed with error: ${error.message}`;
      toastManager.add(errorMessage, { appearance: 'error', autoDismiss: true });
    });

    //TranscriptActions.uploadManyTranscripts(projectID, acceptedFiles);
  }, [projectID, history, toastManager]);

  const isHovering = useCallback((hoverState) => {
    setIsHover(hoverState);
  }, []);

  return (
    <ContentWrapper
      loaded={onboardingState.loaded}
      showOnboarding={onboardingState.otherOnboarding && !getStartedClick}
      header="Analyze survey responses with in depth coding"
      body="Survey coding done right—go beyond basic tags with nested codes and rich analysis."
      onClick={onGetStartedClick}
      buttonText="Add a survey"
      helpArticleText="Watch 2 minute video"
      mediaType="image"
      mediaSrc={process.env['IMAGE_SURVEY_URL']}
      intercomArticleId={process.env['INTERCOM_ARTICLE_GET_STARTED_VIDEO']}
      height={height}
    >
      <DragDropSurveyLayout
        height={height}
      >
        <FileDropZone onDrop={onDrop} isHovering={isHovering}>
          <FileDropzoneSquare
            isHover={isHover}
            hoverText={'Drop .csv file here'}
            nonHoverText={'Drag and Drop a .csv file'}  
          />
        </FileDropZone>
      </DragDropSurveyLayout>
    </ContentWrapper>
  );
}

export default withToastManager(DragDropSurveyContainer);
