import React, { useMemo } from 'react';
import CodeableContext from 'contexts/CodeableContext';
import useSelectedExcerpt from './useSelectedExcerpt';
import useResolvedUserId from 'codeable/useResolvedUserId';
import {withRouter} from 'react-router';
import { getSelectedCodeIds } from 'utils/CodeUtils';
import { useCodeableRefs } from 'hooks/codeable/useCodeableRefs';
import { useSurveyAnswerRef } from 'hooks/useSurveyAnswerRef';
import { useStoreListeners } from 'hooks/codeable/useStoreListeners';
import { useCodeOperations } from 'hooks/codeable/useCodeOperations';

const CodeableContextProvider = ({children, projectId, location, includeAllUsers}) => {
  const {
    excerptId: selectedExcerptId,
    excerpt: selectedExcerpt,
    select,
    onNewExcerptId,
    onDeselect,
    onSelectText,
    selectedExcerptVisible,
    setSelectedExcerptVisible,
  } = useSelectedExcerpt(null);

  const {codingUserId} = useResolvedUserId(location, includeAllUsers);

  const { selectedExcerptIdRef, selectRef } = useCodeableRefs(selectedExcerptId, select);

  const {
    codeableId,
    codeableType,
  } = select || {};

  const { answerRef } = useSurveyAnswerRef(codeableType, codeableId);

  // Use the extracted hook for code operations
  const { 
    onExcerptSelected, 
    onNewCode,
    addCodeToExcerpt, 
    onRemoveCode 
  } = useCodeOperations(
    projectId,
    selectedExcerptIdRef,
    selectRef,
    answerRef,
    codingUserId,
    onNewExcerptId
  );

  // Using the extracted hook instead of the inline useEffect
  useStoreListeners(selectedExcerptIdRef, selectRef, onNewExcerptId, onSelectText);

  const codeIds = getSelectedCodeIds(selectedExcerpt, codingUserId);
    
  const contextValue = useMemo(() => ({
    selectedExcerptId,
    select,
    onExcerptSelected,
    onDeselect,
    onSelectText,
    selectedCodeIds: codeIds,
    onNewCode,
    onAddCode: addCodeToExcerpt,
    onRemoveCode,
    selectedExcerptVisible,
    setSelectedExcerptVisible,
  }), [selectedExcerptId, select, codeIds, onNewCode, addCodeToExcerpt, onRemoveCode]);
  
  return (
    <CodeableContext.Provider value={contextValue}>
      {children}
    </CodeableContext.Provider>
  );
};

export default withRouter(CodeableContextProvider);
