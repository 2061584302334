import { useCallback, useState, useEffect } from 'react';

export const useRightSideBar = ({
  rightSideBarProp,
  onboardingState,
  requiresExcerpts,
  requiresCodes,
  notFoundError,
  projectID
}) => {
    const [hasExcerpts, setHasExcerpts] = useState(onboardingState.hasExcerpts);
    const [hasCodes, setHasCodes] = useState(onboardingState.hasCodes);

    useEffect(() => {
        if (projectID !== null) {
            setHasExcerpts(false);
            setHasCodes(false);
        }
    }, [projectID]);

    useEffect(() => {
        if (onboardingState.hasExcerpts) {
            setHasExcerpts(true);
        }
        if (onboardingState.hasCodes) {
            setHasCodes(true);
        }
    }, [onboardingState.hasExcerpts, onboardingState.hasCodes]);

    if (!rightSideBarProp) return false;
    if (notFoundError) return false;
    if (onboardingState.loaded && onboardingState.otherOnboarding) return false;
    if (requiresExcerpts && !hasExcerpts) return false;
    if (requiresCodes && !hasCodes) return false;

    return rightSideBarProp;
};
