import React from 'react';
import SignupLeftContent from './SignupLeftContent';

const SignUpLeftContainer = () => (
    <SignupLeftContent
        header="Turn transcripts into insights with coding software that works like your brain."
        paragraph="14 Day Free Trial. Cancel Anytime."
        quote="“This tool cut HOURS of time sorting through qualitative data! I love how easy it made analyzing themes for my dissertation!”"
        author="Indyasia F."
    />
);

export default SignUpLeftContainer;