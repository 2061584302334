import OptimisticMapperHelper from './OptimisticMapperHelper';

// LOAD_PROJECT_DETAILS_RESULT: waiting on this

const onboardingMapper = (state, projectID) => {
  const loadingState = state.loadingState.projectDetails[projectID];
  const loaded = loadingState ? loadingState.loaded : false;
  const project = state.entities.projects[projectID] || {};
  const transcripts = project.transcripts || [];
  const gotStarted = state.onboardingState.getStarted;

  const codes = project.codes || [];
  const hasCodes = codes.length > 0;

  const hasExcerpts = codes.some(codeId => {
    const code = OptimisticMapperHelper.getCode(state, codeId);
    return code && code.count > 0;
  });

  return {
    loaded: loaded,
    newTranscriptOnboarding: loaded && transcripts.length == 0 && !gotStarted,
    otherOnboarding: loaded && transcripts.length == 0,
    hasCodes,
    hasExcerpts,
  };
}

export default onboardingMapper;
