import React, { useState, useEffect } from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import TermsBlurb from './TermsBlurb'
import DelveAccountBody from './DelveAccountBody'
import DimensionsConstants from 'constants/DimensionsConstants'
import FieldGroup from './FieldGroup'
import LoadingButton from './LoadingButton'
import trackingUtil from 'utils/TrackingUtil'
import SplitContainer from 'sign_up/SplitContainer';
import SignUpLeftContainer from 'sign_up/SignUpLeftContainer';

const SignUpDisplay = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const isFormValid = () => {
    return name.trim() !== '' && email.trim() !== '' && password.trim() !== '';
  };

  useEffect(() => {
    const onChange = () => {
      const signupState = AuthStore.getSignupState();
      setShow(signupState.show);
      setMessage(signupState.message);
    };

    AuthStore.addChangeListener(onChange);
    trackingUtil.logAmplitudeEvent('sign_up_page_viewed');

    return () => AuthStore.removeChangeListener(onChange);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    QualCodeActions.signUp(name, email, password).then(() => {
      setLoading(false);
    });
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <SplitContainer
      leftContent={<SignUpLeftContainer/>}
    >
      <DelveAccountBody
        error={show ? {message, error: true} : {}}
        subHeader="Create an Account"
        login
        currentStep={1}
      >
        <div style={{width:DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
          <form autoComplete="on">
            <FieldGroup
              id="formControlsName"
              type="text"
              name="name"
              label="Name"
              placeholder="Enter name"
              value={name}
              onChange={onNameChange}
              autoComplete="name"
            />
            <FieldGroup
              id="formControlsEmail"
              type="email"
              name="email"
              label="Email address"
              placeholder="Enter email"
              value={email}
              onChange={onEmailChange}
              autoComplete="email"
            />
            <FieldGroup 
              id="formControlsPassword"
              name="password"
              label="Password"
              type="password"
              placeholder="Enter Password"
              onChange={onPasswordChange}
              value={password}
              autoComplete="new-password"
            />

            <TermsBlurb/>

            <div style={{width: "100%", marginTop:"20px"}}>
              <div style={{width: DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
                <LoadingButton 
                  bsStyle="blue"
                  loading={!!loading && !show}
                  disabled={!isFormValid() || (!!loading)}
                  width="100%"
                  onClick={onSubmit}
                >
                  Sign Up
                </LoadingButton>
              </div>
            </div>
          </form>

          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <p style={{ textAlign: 'center', fontSize: '.9em' }}>
              Are you a student? Sign up with your university email to qualify for an education discount.{' '}
              <a
                href="https://intercom.help/delvetool/en/articles/3805420-education-pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </a>
            </p>
          </div>
        </div>
      </DelveAccountBody>
    </SplitContainer>
  );
};

export default SignUpDisplay;