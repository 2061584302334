// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { ACTIVE_BUTTON_BLUE } from 'constants/ColorConstants';
import { useIntercomArticle } from 'projects/hooks/useIntercomArticle';

function HelpLink({ intercomArticle, glyph = 'question-sign', children }) {
  const handleClick = useIntercomArticle(intercomArticle);

  return (
    <div className="help-link">
      <Glyphicon
        style={{color: ACTIVE_BUTTON_BLUE}} 
        glyph={glyph}
      />
      {' '}<a href="#" onClick={handleClick}>
        {children}
      </a>
    </div>
  )
}

export default HelpLink;