import { useState, useCallback, useEffect } from 'react';
import ProjectsStore from 'stores/ProjectsStore';
import OnboardingStore from 'stores/OnboardingStore';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import { useSurveyList } from './surveys/queries';

/*
    {
        // loaded is true when the project is loaded
        loaded:true,
        // newTranscriptOnboarding is true when the onboarding should be shown
        // once the user clicks Add Transcript this is set to false
        newTranscriptOnboarding:true,
        // otherOnboarding is true when there are no transcripts
        // below it is combined with hasSurveys
        otherOnboarding: true
    }
*/

export const useOnboardingState = (projectID) => {
  const [projectOnboardingState, setProjectOnboardingState] = useState(ProjectsStore.getOnboardingState(projectID));
  const { data: surveys = [], isSuccess } = useSurveyList(projectID);

  const queryOnboardingState = useCallback(() => {
    if (!projectID) return;
    const onboardingState = ProjectsStore.getOnboardingState(projectID);
    setProjectOnboardingState(onboardingState);
  }, [projectID]);

  const onChange = useCallback(() => {
    queryOnboardingState();
  }, [queryOnboardingState]);

  useEffect(() => {
    queryOnboardingState();
    ProjectsStore.addChangeListener(onChange);
    OnboardingStore.addChangeListener(onChange);
    CodedTranscriptStore.addChangeListener(onChange);
    return () => {
      ProjectsStore.removeChangeListener(onChange);
      OnboardingStore.removeChangeListener(onChange);
      CodedTranscriptStore.removeChangeListener(onChange);
    };
  }, [queryOnboardingState, onChange]);

  const hasSurveys = surveys.length > 0;

  return {
    loaded: isSuccess && projectOnboardingState.loaded,
    newTranscriptOnboarding: projectOnboardingState.newTranscriptOnboarding,
    otherOnboarding: !hasSurveys && projectOnboardingState.otherOnboarding,
    hasCodes: projectOnboardingState.hasCodes,
    hasExcerpts: projectOnboardingState.hasExcerpts,
  };
};
