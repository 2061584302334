/**
 * Extract code IDs from a selected excerpt, filtered by coding user ID if provided
 * @param {Object} selectedExcerpt - The currently selected excerpt containing codes
 * @param {String|Number|null} codingUserId - Optional user ID to filter codes by
 * @returns {Array} Array of code IDs
 */
const getSelectedCodeIds = (selectedExcerpt, codingUserId) => {
  let codeIds = [];
  
  if (!!selectedExcerpt && !!selectedExcerpt.codes) {
    codeIds = selectedExcerpt.codes
      .map((code) => ({
        ...code,
        coders: code.coders.filter((coder) => 
          !codingUserId || coder.id === codingUserId)
      }))
      .filter((code) => code.coders.length > 0)
      .map((code) => code.id);
  }
  
  return codeIds;
};

export { getSelectedCodeIds };
// Also provide a default export for backward compatibility
export default { getSelectedCodeIds };
