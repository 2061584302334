import { useRef, useEffect } from 'react';
import { useSurveyAnswer } from 'hooks/surveys/queries';

export const useSurveyAnswerRef = (codeableType, codeableId) => {
  // Get survey answer if needed
  const { data: answer, isLoading } = useSurveyAnswer(
    codeableType === 'SurveyAnswer' ? codeableId : null
  );
  
  const answerRef = useRef(null);
  useEffect(() => {
    answerRef.current = answer;
  }, [answer]);
  
  return {
    answerRef,
    isLoading
  };
};
