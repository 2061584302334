// app/javascript/quotes/components/App.jsx
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
} from 'react-router-dom'


import { DndProvider } from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend';
import { ToastProvider } from 'react-toast-notifications';

import AppBody from './AppBody'
import * as Sentry from '@sentry/browser';
import DelveBootstrapStyles from './DelveBootstrapStyles'
import ReactGA from 'react-ga';
import ErrorBoundary from './ErrorBoundary'
import SubscriberWrapper from './SubscriberWrapper'

ReactGA.initialize(GOOGLE_ANALYTICS_ID, {gaOptions: {
    allowLinker: true
}});

ReactGA.ga('require', 'linker');
ReactGA.ga('linker:autoLink', ['delvetool.com'] );

import 'sanitize.css'

Sentry.init({
  dsn: SENTRY_DNS
});

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
      retry: 2,                 // Retry failed requests 2 times
      refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    },
  },
});

class App extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  render ()
  {
    return (
      <div>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <ToastProvider>
                <DelveBootstrapStyles/>
                <Router>
                  <SubscriberWrapper>
                    <AppBody/>
                  </SubscriberWrapper>
                </Router>
              </ToastProvider>
            </DndProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </div>
    );
  }
}

export default App
