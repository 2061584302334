// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import ProjectSearch from './ProjectSearch'
import ToTranscriptContentWrapper from './ToTranscriptContentWrapper'

// Stores
import SearchStore from 'stores/SearchStore'
import ProjectsStore from 'stores/ProjectsStore'

class ProjectSearchContainer extends React.Component {
  constructor () {
    super();
    const searchResults = SearchStore.getSearchResults(null);
    this.state = {
      ...searchResults,
      searchText:searchResults.searchTerm,
      results:[],
    }

    this._onChange = this._onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  onSearchTextChange(event)
  {
    this.setState({
      searchText:event.target.value
    })
  }

  onSubmit (e) {
    e.preventDefault();
    QualCodeActions.searchTranscripts({
        project_id:this.props.projectID,
        query:this.state.searchText,
        count:this.state.count,
      })
  }

  _loadState(props)
  {
    const searchResults = SearchStore.getSearchResults(props.projectID);
    this.setState({
      ...searchResults,
      searchText:searchResults.searchTerm
    });
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    SearchStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount() {
    SearchStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    if ( nextProps.projectID != this.props.projectID )
    {
      this._loadState(nextProps);
    }
  }

  render () {
    return (
      <ToTranscriptContentWrapper
        loaded={this.props.onboardingState.loaded}
        showOnboarding={this.props.onboardingState.otherOnboarding}
        header="Find what you're looking for"
        body="Search words or phrases across all transcripts in your project."
        height={this.props.height}
        intercomArticleId={process.env['INTERCOM_ARTICLE_SEARCH']}
        helpArticleText="Learn more about search"
        hasEditPermission={this.props.hasEditPermission}
        projectID={this.props.projectID}
      >
        <ProjectSearch
          results={this.state.results}
          loadState={this.state.loadState}
          count={this.state.count}
          total={this.state.total}
          height={this.props.height}
          onSubmit={this.onSubmit}
          searchTerm={this.state.searchTerm}
          searchText={this.state.searchText}
          onSearchTextChange={this.onSearchTextChange}
          projectID={this.props.projectID}
          hasEditPermission={this.props.hasEditPermission}
        />
      </ToTranscriptContentWrapper>
    );
  }
}

export default ProjectSearchContainer;
