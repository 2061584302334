import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'
import mergeReducer from './MergeReducer'
import codeReducer from './CodeReducer';
import codeBarReducer from './CodeBarReducer';
import codePageShowReducer from './CodePageShowReducer';
import createExcerptReducer from './CreateExcerptReducer';
import mergeExcerptsReducer from './MergeExcerptsReducer';
import parsedFileReducer from './ParsedFileReducer';

import { v1 as uuidv1 } from 'uuid';

import {
    FETCH_DESCRIPTORS_RESULT,
    CREATE_MULTI_CHOICE,
    CREATE_MULTI_CHOICE_RESULT,
    UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT,
    CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT,
    DESTROY_MULTI_CHOICE_DESCRIPTOR,
    FETCH_SELECTIONS_RESULT,
    FETCH_PROJECT_SELECTIONS_RESULT,
    CREATE_MULTI_CHOICE_SELECTION_RESULT,
    DELETE_MULTI_CHOICE_SELECTION_RESULT,
    // PROJECTS
    LOADED_PROJECTS,
    // LOAD PROJECT
    LOAD_PROJECT_DETAILS,
    LOAD_PROJECT_DETAILS_RESULT,
    NEW_PROJECT_RESULT,
    TRANSCRIPT_CREATE_RESULT,
    TRANSCRIPT_CREATE_CABLE,
    UPDATE_PROJECT_NAME,
    PROJECT_CABLE,
    // FILTERS
    // FETCH EXCERPTS
    FETCH_EXCERPTS_RESULT,
    // UPDATE TRANSCRIPT TEXT
    UPDATE_TRANSCRIPT_TEXT,
    UPDATE_TRANSCRIPT_TEXT_RESULT,
    UPDATE_TRANSCRIPT_TEXT_ERROR,
    // TRANSCRIPTS
    DELETE_TRANSCRIPT,
    DELETE_TRANSCRIPT_CABLE,
    UPDATE_TRANSCRIPT_RESULT,
    UPDATE_TRANSCRIPT_CABLE,
    LOAD_TRANSCRIPT_RESULTS,
    // CODES
    SHOW_NEW_PROJECT_MODAL,
    HIDE_NEW_PROJECT_MODAL,
    // USER
    FETCH_USER_RESULT,
    // TERMS OF SERVICE
    ACCEPT_TERMS_OF_SERVICE_RESULT,
    // EDIT PARAGRAPH
    EDIT_PARAGRAPH,
    CANCEL_EDIT_PARAGRAPH,
  } from 'constants/QualConstants'

import {
  // FILTER api call
  FILTER_CODE_EXCERPTS_RESULT
} from 'constants/CodePageConstants'

import {
  FILTER_EXCERPTS_RESULT,
} from 'constants/FilterConstants'

import {
  LOADING_STATE,
  LOADED_STATE
} from 'constants/LoadingConstants'

var _ = require('underscore');

/*************************\
*
* Project Schema
*
\*************************/
const multi_choices_schema = new schema.Entity('multi_choices');

const descriptors_schema = new schema.Entity('descriptors', {
  multi_choices: [multi_choices_schema]
});

const descriptor_schema = new schema.Entity('multi_choice_descriptor', {
  multi_choice: multi_choices_schema
});

const project_schema = new schema.Entity('projects', {
  descriptors: [descriptors_schema]
});

const project_new_descriptor_schema = new schema.Entity('projects', {
  multi_choice_descriptor: descriptors_schema
});

/**************************\
*
* Transcript Schema
*
\**************************/

const selection_schema = new schema.Entity('selections')

const transcript_selection_schema = new schema.Entity('transcripts', {
  selections:[selection_schema]
});

const project_selection_schema = new schema.Entity('projects', {
  transcripts: [transcript_selection_schema]
});

const multi_choice_selection_schema = new schema.Entity('multi_choice_selection')

const transcript_multi_choice_selection_schema = new schema.Entity('transcripts', {
    multi_choice_selection:multi_choice_selection_schema
});

/**************************\
*
* Project Schema
*
\**************************/

const transcript_schema = new schema.Entity('transcripts')
const user_schema = new schema.Entity('users')
const project_detail_schema = new schema.Entity('projects', {
  owner: user_schema,
  transcripts: [transcript_schema]
});

const project_list_scehma = new schema.Entity('projects_list', {
  projects:[project_detail_schema]
});

const create_transcript_schema = new schema.Entity('project',{
    transcript: transcript_schema
});

/***************************\
*
* Codes Schema
*
\***************************/


/*
* Excerpt Schema
*/

const code_schema = new schema.Entity('codes')
const memo_schema = new schema.Entity('memos')

const excerpt_schema = new schema.Entity('excerpt', {
  codes: [code_schema],
  memos: [memo_schema]
})

const transcript_excerpts_schema = new schema.Entity('transcripts', {
  excerpts: [excerpt_schema]
})

function combineStateWithEntity(newEntityList, stateEntities, newEntities)
{
  var returnDict = Object.assign({}, stateEntities);
  newEntityList.map((index)=>{
    returnDict[index] = {
      ...stateEntities[index],
      ...newEntities[index]
    }
  });
  return returnDict;
}

function descriptorReducer(action, state = InitialState)
{
  if ( !action ) return state;

  if ( !state.session_id )
    state['session_id'] = uuidv1();

  const reducers = [mergeReducer, createExcerptReducer, codeReducer, codeBarReducer, codePageShowReducer, parsedFileReducer];

  for (let reducer of reducers) {
    let result = reducer(action, state);
    if (result) return result;
  }

  switch (action.actionType)
  {
    case FETCH_DESCRIPTORS_RESULT:
    {
      var normalizedData = normalize(action.data, project_schema);

      const projectKey = normalizedData.result;
      return {
        ...state,
        entities: {
          ...state.entities,
          multi_choices: {
            ...state.entities.multi_choices,
            ...normalizedData.entities.multi_choices
          },
          descriptors: {
            ...state.entities.descriptors,
            ...normalizedData.entities.descriptors
          }
        },
        projectToDescriptor:{
          ...state.projectToDescriptor,
          [projectKey]:{
            ...normalizedData.entities.projects[projectKey],
            descriptors:normalizedData.entities.projects[projectKey].descriptors ? normalizedData.entities.projects[projectKey].descriptors : [],
            isLoading:false
          }
        }
      }
    }
    break;
    case CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT:
      var normalizedData = normalize(action.data, project_new_descriptor_schema);
      const projectKey = normalizedData.result;
      const existingDescriptors = state.projectToDescriptor[projectKey] ?
                                    state.projectToDescriptor[projectKey].descriptors.slice() :
                                    [];

      return {
        ...state,
        entities: {
          ...state.entities,
          multi_choices: {
            ...state.entities.multi_choices,
            ...normalizedData.entities.multi_choices
          },
          descriptors: {
            ...state.entities.descriptors,
            ...normalizedData.entities.descriptors
          }
        },
        projectToDescriptor:{
          ...state.projectToDescriptor,
          [projectKey]:{
            ...state.projectToDescriptor[projectKey],
            id: normalizedData.entities.projects[projectKey].id,
            descriptors: [].concat(existingDescriptors, [normalizedData.entities.projects[projectKey].multi_choice_descriptor])
          }
        }
      }
    break;
    case CREATE_MULTI_CHOICE:
      var normalizedData = normalize(action.data, descriptor_schema);
      const descriptorKey = normalizedData.result;

      var newChoices = state.entities.descriptors[descriptorKey].multi_choices.slice();
      newChoices.push(normalizedData.entities.multi_choice_descriptor[descriptorKey].multi_choice);

      return {
        ...state,
        entities: {
          ...state.entities,
          multi_choices: {
            ...state.entities.multi_choices,
            ...normalizedData.entities.multi_choices
          },
          descriptors: {
            ...state.entities.descriptors,
            [descriptorKey]: {
              ...state.entities.descriptors[descriptorKey],
              multi_choices:newChoices
            }
          }
        }
      }
    case CREATE_MULTI_CHOICE_RESULT:
    {
      const response = action.data.response;
      const tmpID = action.data.multi_choice_temp_id;
      const newChoices = Object.assign({}, state.entities.multi_choices);
      delete newChoices[tmpID];

      const normalizedData = normalize(response, descriptor_schema);

      const descriptorKey = normalizedData.result;
      const newChoiceIDs = _.filter(state.entities.descriptors[descriptorKey].multi_choices,
                                    function(choiceID){return choiceID != tmpID});
      newChoiceIDs.push(normalizedData.entities.multi_choice_descriptor[descriptorKey].multi_choice);

      return {
        ...state,
        entities: {
          ...state.entities,
          multi_choices: {
            ...newChoices,
            ...normalizedData.entities.multi_choices
          },
          descriptors: {
            ...state.entities.descriptors,
            [descriptorKey]: {
              ...state.entities.descriptors[descriptorKey],
              multi_choices:newChoiceIDs
            }
          }
        }
      }
      return state;
    }
    break
    case UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT:
      {
        const normalizedData = normalize(action.data, descriptors_schema);
        const descriptorKey = normalizedData.result;

        var choices = Object.assign({}, state.entities.multi_choices);
        if ( descriptorKey in state.entities.descriptors)
        {
          const deleteChoiceKeys = _.difference(state.entities.descriptors[descriptorKey].multi_choices,
            normalizedData.entities.descriptors[descriptorKey].multi_choices
          );
          for ( var index = 0; index < deleteChoiceKeys.length; index ++ )
          {
            var key = deleteChoiceKeys[index];
            delete choices[key];
          }
        }

        return {
          ...state,
          entities: {
            ...state.entities,
            multi_choices: {
              ...choices,
              ...normalizedData.entities.multi_choices
            },
            descriptors: {
              ...state.entities.descriptors,
              ...normalizedData.entities.descriptors
            }
          }
        }
      }
    break
    case DESTROY_MULTI_CHOICE_DESCRIPTOR:
    {
      const descriptorID = action.data.id;
      const projectID = action.data.project_id;

      var descriptors = Object.assign({}, state.entities.descriptors);
      delete descriptors[descriptorID]

      const descriptorKeys = state.projectToDescriptor[projectID].descriptors.filter((x) => {return x!= descriptorID});
      const deleteChoiceKeys = state.entities.descriptors[descriptorID].multi_choices;
      var choices = Object.assign({}, state.entities.multi_choices);

      for ( var index = 0; index < deleteChoiceKeys.length; index ++ )
      {
        var key = deleteChoiceKeys[index];
        delete choices[key];
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          descriptors: descriptors,
          multi_choices: choices
        },
        projectToDescriptor: {
          ...state.projectToDescriptor,
          [projectID]: {
            ...state.projectToDescriptor[projectID],
            descriptors: descriptorKeys
          }
        }
      }
    }
    break;
    case FETCH_SELECTIONS_RESULT:
    {
      var normalizedData = normalize(action.data, transcript_selection_schema);
      const transcriptKey = normalizedData.result;
      return {
        ...state,
        entities: {
          ...state.entities,
          selections:
          {
            ...state.entities.selections,
            ...normalizedData.entities.selections
          }
        },
        transcriptToSelection:{
          ...state.transcriptToSelection,
          [transcriptKey]:{
            ...normalizedData.entities.transcripts[transcriptKey],
          }
        }
      }
    }
    break;
    case FETCH_PROJECT_SELECTIONS_RESULT:
    {
      var normalizedData = normalize(action.data, project_selection_schema);
      const projectKey = normalizedData.result;

      return {
        ...state,
        entities: {
          ...state.entities,
          selections:
          {
            ...state.entities.selections,
            ...normalizedData.entities.selections
          },
          projects:
          {
            ...state.entities.projects,
            [projectKey]: {
              ...state.entities.projects[projectKey],
              ...normalizedData.entities.projects[projectKey]
            }
          },
        },
        transcriptToSelection:{
          ...state.transcriptToSelection,
          ...normalizedData.entities.transcripts
        }
      }
    }
    break;
    case CREATE_MULTI_CHOICE_SELECTION_RESULT:
    {
      var normalizedData = normalize(action.data, transcript_multi_choice_selection_schema);

      const transcriptKey = normalizedData.result;
      const existingSelections = state.transcriptToSelection[transcriptKey] ?
                                    state.transcriptToSelection[transcriptKey].selections.slice() :
                                    [];

      return {
        ...state,
        entities: {
          ...state.entities,
          selections:
          {
            ...state.entities.selections,
            ...normalizedData.entities.multi_choice_selection
          }
        },
        transcriptToSelection:{
          ...state.transcriptToSelection,
          [transcriptKey]:{
            ...state.transcriptToSelection[transcriptKey],
            id: normalizedData.entities.transcripts[transcriptKey].id,
            selections: _.uniq([].concat([normalizedData.entities.transcripts[transcriptKey].multi_choice_selection],
                                  existingSelections)),
          }
        }
      }
    }
    break;
    case DELETE_MULTI_CHOICE_SELECTION_RESULT:
    {
      const selectionID = action.data.id;
      const transcriptID = action.data.transcript_id;

      var selections = Object.assign({}, state.entities.selections);
      delete selections[selectionID];

      const selectionIDs = state.transcriptToSelection[transcriptID].selections.filter((x) => {return x!= selectionID});

      return {
        ...state,
        entities: {
          ...state.entities,
          selections: selections
        },
        transcriptToSelection:{
          ...state.transcriptToSelection,
          [transcriptID]:{
            ...state.transcriptToSelection[transcriptID],
            selections: selectionIDs
          }
        }
      }
    }
    break;
    case LOADED_PROJECTS:
    {
      var normalizedData = normalize(action.data, project_list_scehma);

      const projectList = normalizedData.entities.projects_list.undefined.projects;

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: combineStateWithEntity(projectList, state.entities.projects, normalizedData.entities.projects),
          users: {
            ...state.entities.users,
            ...normalizedData.entities.users,
          }
        },
        projectsList: {
          isLoading: false,
          loaded: true,
          recent_project_id:normalizedData.entities.projects_list.undefined.recent_project_id,
          projects: projectList
        }
      }
    }
    break;
    case LOAD_PROJECT_DETAILS:
    {
      const projectID = action.data.projectID;
      return {
        ...state,
        loadingState:
        {
          ...state.loadingState,
          projectDetails:
          {
            ...state.loadingState.projectDetails,
            [projectID]: LOADING_STATE,
          }
        }
      }
    }
    case LOAD_PROJECT_DETAILS_RESULT:
    {
      var normalizedData = normalize(action.data, project_detail_schema);
      const projectKey = normalizedData.result;


      var projects = state.projectsList.projects ? state.projectsList.projects.slice() : [];
      if ( !projects.includes(projectKey) )
        projects.push(projectKey);

      return {
        ...state,
        entities: {
          ...state.entities,
          transcripts: combineStateWithEntity(
            normalizedData.entities.projects[projectKey].transcripts,
            state.entities.transcripts,
            normalizedData.entities.transcripts
          ),
          projects: combineStateWithEntity(
            [projectKey],
            state.entities.projects,
            normalizedData.entities.projects),
          users:{
            ...state.entities.users,
            ...normalizedData.entities.users,
          }
        },
        projectsList: {
          ...state.projectsList,
          projects: projects
        },
        loadingState: {
          ...state.loadingState,
          projectDetails: {
            ...state.loadingState.projectDetails,
            [projectKey]: LOADED_STATE,
          }
        }
      }
    }
    break;
    case TRANSCRIPT_CREATE_CABLE:
    case UPDATE_TRANSCRIPT_CABLE:
    {
      var normalizedData = normalize(action.data, create_transcript_schema);
      const projectKey = normalizedData.result;

      var transcripts = [];
      if ( state.entities.projects[projectKey] && state.entities.projects[projectKey].transcripts)
        transcripts = state.entities.projects[projectKey].transcripts.slice();

      const transcriptKey = normalizedData.entities.project[projectKey].transcript;
      if ( !transcripts.includes(transcriptKey))
        transcripts.push(transcriptKey);

      const transcript = normalizedData.entities.transcripts[transcriptKey];

      return {
        ...state,
        entities: {
          ...state.entities,
          transcripts: {
            ...state.entities.transcripts,
            [transcriptKey]:
            {
              ...state.entities.transcripts[transcriptKey],
              ...transcript,
            }
          },
          projects: {
            ...state.entities.projects,
            [projectKey]: {
              ...state.entities.projects[projectKey],
              transcripts: transcripts,
            }
          }
        },
      }
      return state;
    }
    break;
    case TRANSCRIPT_CREATE_RESULT:
    {
      var normalizedData = normalize(action.data, create_transcript_schema);
      const projectKey = normalizedData.result;

      var transcripts = [];
      if ( state.entities.projects[projectKey] && state.entities.projects[projectKey].transcripts)
        transcripts = state.entities.projects[projectKey].transcripts.slice();

      const transcriptKey = normalizedData.entities.project[projectKey].transcript;
      if ( !transcripts.includes(transcriptKey) )
        transcripts.push(transcriptKey);

      const newTranscript = normalizedData.entities.transcripts[transcriptKey];

      var project = Object.assign({},
                              normalizedData.entities.project[projectKey],
                              {transcripts: transcripts});
      delete project['transcript']

      return {
        ...state,
        entities: {
          ...state.entities,
          transcripts: {
            ...state.entities.transcripts,
            [transcriptKey]:
            {
              // TODO: this should be managed in a mapper, this is a hack
              project_id: projectKey,
              ...newTranscript
            }
          },
          projects: {
            ...state.entities.projects,
            [projectKey]: {
              ...state.entities.projects[projectKey],
              ...project
            }
          }
        },
      }
      return state;
    }
    break;
    case NEW_PROJECT_RESULT:
    {
      var normalizedData = normalize(action.data, project_detail_schema);
      const projectKey = normalizedData.result;
      const projects = state.projectsList.projects.slice();
      projects.push(projectKey);

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            ...normalizedData.entities.projects
          },
          users: {
            ...state.entities.users,
            ...normalizedData.entities.users,
          }
        },
        projectsList: {
          ...state.projectsList,
          projects: projects
        },
        newProject: {
          show: false,
          id: projectKey
        }
      }
      return state;
    }
    break;
    case FILTER_EXCERPTS_RESULT: {
      const code_schema = new schema.Entity('codes');
      const memo_schema = new schema.Entity('memos');

      const excerpts_schema = new schema.Entity('excerpts', {
        codes: [code_schema],
        memos: [memo_schema]
      })
      const projects = new schema.Entity('project', {
        excerpts: [excerpts_schema]
      })

      var normalizedData = normalize(action.data, projects);

      const mergedExcerpts = mergeExcerptsReducer(state, normalizedData.entities.excerpts);

      return {
        ...state,
        entities: {
          ...state.entities,
          excerpts: mergedExcerpts.entities.excerpts,
          memos: {
            ...state.entities.memos || {},
            ...normalizedData.entities.memos || {}
          }
        },
        mappers:
        {
          ...state.mappers,
          excerpts: mergedExcerpts.mappers.excerpts,
        }
      }
    }
    break;
    case FILTER_CODE_EXCERPTS_RESULT:
    {
      // NOTE: This sends down code info, but does not set it
      // That is fine
      const memo_schema = new schema.Entity('memos');
      const excerpts_schema = new schema.Entity('excerpts', {
        memos: [memo_schema]
      })

      const code_schema = new schema.Entity('code', {
        excerpts: [excerpts_schema]
      })

      var normalizedData = normalize(action.data, code_schema);

      const mergedExcerpts = mergeExcerptsReducer(state, normalizedData.entities.excerpts);

      return {
        ...state,
        entities: {
          ...state.entities,
          excerpts: mergedExcerpts.entities.excerpts,
          memos: {
            ...state.entities.memos || {},
            ...normalizedData.entities.memos || {}
          }
        },
        mappers:
        {
          ...state.mappers,
          excerpts: mergedExcerpts.mappers.excerpts,
        }
      }
    }
    break;
    // TODO: NOT SURE IF THIS CODE IS BEING USED ANYMORE
    case FETCH_EXCERPTS_RESULT:
    {
      const projectID = action.data.projectID;
      const code_schema = new schema.Entity('codes');
      const excerpts_schema = new schema.Entity('excerpts', {
        codes: [code_schema]
      })
      const projects = new schema.Entity('project', {
        excerpts: [excerpts_schema]
      })

      var normalizedData = normalize(action.data, projects);

      const mergedExcerpts = mergeExcerptsReducer(state, normalizedData.entities.excerpts);

      return {
        ...state,
        entities: {
          ...state.entities,
          excerpts: mergedExcerpts.entities.excerpts,
        },
        mappers:
        {
          ...state.mappers,
          excerpts: mergedExcerpts.mappers.excerpts,
        },
      }
    }
    break;
    case DELETE_TRANSCRIPT_CABLE:
    {
      const projectID = action.data.id;
      const transcriptID = action.data.transcript.id;
      var transcripts = {...state.entities.transcripts}
      delete transcripts[transcriptID];

      const project = state.entities.projects[projectID];
      const projectTranscripts = project && project.transcripts ?
                                    project.transcripts.filter((id)=>id!=transcriptID) : [];

      return {
        ...state,
        entities:
        {
          ...state.entities,
          projects:{
            ...state.entities.projects,
            [projectID]:
            {
              ...project,
              transcripts: projectTranscripts,
            }
          },
          transcripts:transcripts
        }
      }
    }
    case DELETE_TRANSCRIPT:
    {
      const transcriptID = action.data.id;


      const projects = state.projectsList.projects;
      var projectID = null;
      if ( projects )
      {
        var filteredProjects = projects.filter((pIndex)=>{
          if ( !state.entities.projects[pIndex] ) return false;
          if ( !state.entities.projects[pIndex].transcripts ) return false;
          return state.entities.projects[pIndex].transcripts.includes(transcriptID);
        });
        if ( filteredProjects.length > 0)
          projectID = filteredProjects[0];
      }

      var projectObject = {};
      if ( projectID )
      {
        var transcriptIDs = state.entities.projects[projectID].transcripts.filter((tIndex=>{
          return tIndex != transcriptID;
        }))

        projectObject = {
          [projectID]: {
            ...state.entities.projects[projectID],
            transcripts: transcriptIDs,
          }
        }
      }

      var transcripts = Object.assign({}, state.entities.transcripts);
      delete transcripts[transcriptID];

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            ...projectObject
          },
          transcripts: transcripts
        },
      }
    }
    break;
    case UPDATE_TRANSCRIPT_TEXT:
    {
      const normalizedData = normalize(action.data, transcript_schema);
      const transcriptKey = normalizedData.result;

      const d = new Date();
      return {
        ...state,
        editTranscript: {
          ...state.editTranscript,
          saving: true,
        },
      }
    }
    break;
    case UPDATE_TRANSCRIPT_TEXT_RESULT:
    {
      const normalizedData = normalize(action.data, transcript_schema);
      const transcriptKey = normalizedData.result;

      const d = new Date();
      return {
        ...state,
        editTranscript: {
          paragraphIndex: null,
          saving: false,
        },
      }
    }
    break;
    case UPDATE_TRANSCRIPT_TEXT_ERROR:
    {
      const errors = action.errors ? action.errors : [];
      const error = errors[0] ? errors[0] : {};
      const code = error.code;

      return {
        ...state,
        editTranscript: {
          ...state.editTranscript,
          saving: false,
        },
        errorDict:{
          ...state.errorDict,
          [action.actionType]:{
            errorCode:error.code ? error.code : null,
            message: null,
            error: true,
          }
        }
      }
    }
    break;
    case UPDATE_TRANSCRIPT_RESULT:
    {
      const normalizedData = normalize(action.data, transcript_schema);
      const transcriptKey = normalizedData.result;


        return {
          ...state,
          entities: {
            ...state.entities,
            transcripts: {
              ...state.entities.transcripts,
              [transcriptKey]: {
                ...state.entities.transcripts[transcriptKey],
                ...normalizedData.entities.transcripts[transcriptKey]
              }
            }
          }
        }
    }
    break;
    case UPDATE_PROJECT_NAME:
    case PROJECT_CABLE:
    {
      const project = action.data;

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            [project.id]: {
              ...state.entities.projects[project.id],
              id: project.id,
              ...(project.name && {name: project.name}),
              ...(project.status && {status: project.status})
            }
          }
        }
      }
    }
    break;
    case SHOW_NEW_PROJECT_MODAL:
    {
      return {
        ...state,
        newProject: {
          show:true
        }
      }
    }
    break;
    case HIDE_NEW_PROJECT_MODAL:
    {
      return {
        ...state,
        newProject: {
          show:false
        }
      }
    }
    break;
    case FETCH_USER_RESULT:
    case ACCEPT_TERMS_OF_SERVICE_RESULT:
    {
      const user = action.data;
      return {
        ...state,
        entities: {
          ...state.entities,
          user: user,
          users:{
            ...state.entities.users,
            [user.id]:user,
          }
        }
      }
    }
    break;
    case LOAD_TRANSCRIPT_RESULTS:
    {
      const normalizedData =  normalize(action.data, transcript_excerpts_schema);
      const transcriptID = normalizedData.result;
      const projectID = normalizedData.entities.transcripts[transcriptID].project_id;

      // TODO: keeping project_id in here until I create a mapper
      // delete normalizedData.entities.transcripts[transcriptID].project_id;

      var project = state.entities.projects[projectID] ? state.entities.projects[projectID] : {};
      var transcriptIDs = project.transcripts ? project.transcripts.slice() : [];
      if ( !transcriptIDs.includes(transcriptID))
        transcriptIDs.push(transcriptID);

      const mergedExcerpts = mergeExcerptsReducer(state, normalizedData.entities.excerpt);

      project = {
        ...project,
        transcripts: transcriptIDs
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          transcripts:
          {
            ...state.entities.transcripts,
            ...normalizedData.entities.transcripts,
          },
          excerpts: mergedExcerpts.entities.excerpts,
          projects:
          {
            ...state.entities.projects,
            [projectID]: project
          },
          memos: {
            ...state.entities.memos,
            ...normalizedData.entities.memos,
          }
        },
        loadingState:
        {
          ...state.loadingState,
          transcriptDetails:{
            ...state.loadingState.transcriptDetails,
            [transcriptID]:
            {
              isLoading: false,
              loaded: true
            }
          }
        },
        mappers: {
          ...state.mappers,
          excerpts: mergedExcerpts.mappers.excerpts,
        }
      }

      return state;
    }
    break;
    case EDIT_PARAGRAPH:
    {
      if ( action.data.edit && action.data.transcript_id != null && action.data.paragraph_id != null)
      {
        return {
          ...state,
          editTranscript:
          {
            ...state.editTranscript,
            paragraphIndex:{
              transcript_id: action.data.transcript_id,
              paragraph_id: action.data.paragraph_id,
            }
          }
        }
      }
    }
    case CANCEL_EDIT_PARAGRAPH:
    {
      return {
        ...state,
        editTranscript:
        {
          ...state.editTranscript,
          paragraphIndex: null,
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default descriptorReducer
