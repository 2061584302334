import React from 'react';
import SurveyLink from './SurveyLink';
import { useSurveyList, useSurveyWithDependencies } from 'hooks/surveys';
import { useParams } from 'react-router-dom';

const LeftSideSurveyList = ({ projectId, isOnUserPage, onLinkClick }) => {
  if (!!isOnUserPage || !projectId) {
    return null;
  }

  const { data: surveys = [], isLoading } = useSurveyList(projectId);
  const { surveyID, surveyResponseID, surveyQuestionID } = useParams();

  const { effectiveSurveyId } = useSurveyWithDependencies({
    surveyId: surveyID,
    responseId: surveyResponseID,
    questionId: surveyQuestionID
  });
  
  return surveys.map((survey) => (
    <SurveyLink
      key={survey.id}
      surveyId={survey.id}
      selected={survey.id === Number(effectiveSurveyId)}
      onLinkClick={onLinkClick}
    >
      {survey.title}
    </SurveyLink>
  ));
};

export default LeftSideSurveyList;