// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback} from 'react';

import NewTranscript from './NewTranscript'
import ContentWrapper from './ContentWrapper'
import OnboardingActions from 'actions/OnboardingActions'
import TranscriptActions from 'actions/TranscriptActions'

function NewTranscriptContainer({projectID, height, isHover, onboardingState, history}) {
  const handleSubmit = useCallback((name, body) => {
    if ( ! projectID ) return;

    TranscriptActions.createTranscript({
      project_id:projectID,
      body: body,
      name: name
    }).then((data)=>{
      if ( !data ) return;
      const transcript = data.transcript || {};
      if ( !transcript.id ) return;
      if ( !history ) return;
      history.push("/transcripts/" + transcript.id);
    });
  }, [projectID, history]);

  const getStartedClick = () => {
    OnboardingActions.getStarted();
  }

  return (
    <ContentWrapper
      loaded={onboardingState.loaded}
      showOnboarding={onboardingState.newTranscriptOnboarding}
      header='Add transcripts and start coding in seconds— as simple as highlighting on paper'
      body='Stay focused on insights and hit your deadlines.'
      onClick={getStartedClick}
      buttonText='Add Transcript'
      intercomArticle={process.env['INTERCOM_ARITCLE_GET_STARTED_VIDEO']}
      helpArticleText='Watch 2 minute video'
      mediaType='video'
      mediaSrc={process.env['VIDEO_TRANSCRIPT_URL']}
      height={height}
      intercomArticleId={process.env['INTERCOM_ARTICLE_GET_STARTED_VIDEO']}
    >
      <div>
        <NewTranscript
          height={height}
          isHover={isHover}
          handleSubmit={handleSubmit}
          projectID={projectID}
        />
      </div>
    </ContentWrapper>
  );
}

export default NewTranscriptContainer;
