// app/javascript/projects/components/CodeNestedDisplay.jsx

import { List } from 'react-virtualized';
import React from 'react';

import MergeCodeActions from 'actions/MergeCodeActions'
import hashString from 'utils/HashString'

import CodeLabelDropZone from './CodeLabelDropZone'
import CodingActions from '../../actions/CodingActions';
import { withToastManager } from 'react-toast-notifications';
import TopMarginDropZone from './TopMarginDropZone'
import BottomCode from './BottomCode'
import {
  BOTTOM_DROP_ZONE_HEIGHT,
  CODE_PADDING_LEFT
} from 'constants/DimensionsConstants'
import CodeListEmptyState from './CodeListEmptyState';

const EMPTY_STATE_CODE_THRESHOLD = 1;

class CodeNestedDisplay extends React.Component {
  constructor () {
    super();
    this.state = {
      dragging: false,
      mergeId: null
    };
    this.startDrag = this.startDrag.bind(this);
    this.endDrag = this.endDrag.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.cancelMerge = this.cancelMerge.bind(this);
    this.confirmMerge = this.confirmMerge.bind(this);
    this.onShowMerge = this.onShowMerge.bind(this);
    this.renameCode = this.renameCode.bind(this);
    this.rejectNesting = this.rejectNesting.bind(this);
  }

  rejectNesting () {
    this.props.toastManager.add(
      'Codes cannot be nested more than three layers', {
       appearance: 'warning',
       autoDismiss: true,
       autoDismissTimeout: 7000
     });
  }

  startDrag (codeId) {
    this.props.startDrag(codeId);
    this.setState({dragging: true})
  }

  endDrag() {
    this.setState({dragging: false});
    this.props.startDrag(null);
  }

  onShowMerge(mergeId) {
    this.setState({
      mergeId: mergeId
    })

    if ( this.list )
      this.list.recomputeRowHeights();
  }

  cancelMerge() {
    this.setState({
      mergeId: null
    })
    MergeCodeActions.cancelMergeCode();
    if ( this.list )
      this.list.recomputeRowHeights();
  }

  confirmMerge() {
    this.setState({
      mergeId: null
    })
    MergeCodeActions.mergeCode();
    if ( this.list )
      this.list.recomputeRowHeights();
  }

  renameCode(code_id, name) {
    CodingActions.renameCode(code_id, name);
  }

  rowHeight({index}) {
    if ( index === 0 )
      return 10;

    index = index - 1;
    const NORMAL_HEIGHT = 52;
    const MERGE_HEIGHT = NORMAL_HEIGHT * 2 - 14;
    const code = this.props.codes[index];
    if ( !code ) return NORMAL_HEIGHT;
    const mergeParent = code.id === this.state.mergeId;
    const showConfirmMerge = code.showConfirmMerge;
    const height = (mergeParent || showConfirmMerge ? MERGE_HEIGHT : NORMAL_HEIGHT);

    if ( index === this.props.codes.length - 1) {
      return BOTTOM_DROP_ZONE_HEIGHT + height;
    }

    return height;
  }

  rowRenderer ({
    key,         // Unique key within array of rows
    index,       // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible,   // This row is visible within the List (eg it is not an overscanned row)
    style        // Style object to be applied to row (to position it)
  }) {
      if ( index === 0 ) {
        return (<div style={style} key={'top'}>
          <TopMarginDropZone
            topMargin={this.props.topMargin}
          />
        </div>)
      }

      const code = this.props.codes[index - 1];

      if ( !code ) return <div style={style} key={key}></div>

      const codeLabelDropZone = <CodeLabelDropZone
        initiateMergeCode={this.props.initiateMergeCode}
        fadeIn={this.props.searchedCodeIndex === index - 1}
        rejectNesting={this.rejectNesting}
        position={code.position}
        count={this.props.hideCodeCounts ? 0 : code.count}
        newCode={!!code.newCode}
        mergeParent={code.mergeParent}
        showMerge={code.showMerge}
        barCollapsed={code.barCollapsed}
        parentId={code.parent_id}
        level={code.level}
        name={code.name}
        mergeName={code.mergeName || ''}
        id={code.id}
        dropParent={code.dropParent}
        dropCodeId={code.dropCodeId}
        showConfirmMerge={code.showConfirmMerge}
        childrenCount={code.childCount || 0}
        // TODO: this should be included in codes, this logic is extra
        selected={(this.props.selectedCodes || []).includes(code.id)}
        onClick={!!code.newCode ? this.props.addNewCode : this.props.onClick}
        disabled={this.props.disabled}
        deleteCode={this.props.deleteCode}
        ghost={code.ghostCode}
        startDrag={this.startDrag}
        endDrag={this.endDrag}
        dragging={this.state.dragging}
        draggingEnabled={this.props.draggingEnabled}
        onCollapseClicked={this.props.onCollapseClicked}
        cancelMerge={this.cancelMerge}
        confirmMerge={this.confirmMerge}
        onShowMerge={this.onShowMerge}
        allowDropdown={!this.props.dragCode}
        isDragging={!!this.props.dragCode}
        renameCode={this.renameCode}
        marginDepth={code.marginDepth}
        marginDropCodeId={code.marginDropCodeId}
        marginDropPositon={code.marginDropPositon}
        marginNoOp={code.marginNoOp}
        canDropOnMargin={code.canDropOnMargin}
        canDropOnParent={code.canDropOnParent}
        searchedCodeUnmounted={this.props.searchedCodeUnmounted}
      />

      if ( index === this.props.codes.length ) {
        const bottomCodePosition = Math.max(
          ...(this.props.codes || []).filter(
            (code)=>code.level === 1).map((code)=>code.position)
        )

        return (
          <div style={style} key='bottom'>
            <BottomCode
              bottomMargin={this.props.bottomMargin}
              dropPosition={bottomCodePosition + 1}
            >
              {codeLabelDropZone}
            </BottomCode>
          </div>)
      } else {
        return (<div key={code.id} style={style}>
          {codeLabelDropZone}
        </div>);
      }
  }

  render () {
    const NORMAL_HEIGHT = 52;
    const height = parseInt(this.props.height);
    const codes = (this.props.codes || []).map((code)=>{
      return {
        id: code.id,
        name: code.name,
        collapsed: code.collapsed,
        level: code.level,
        count: code.count
      }
    });

    const hash = hashString(JSON.stringify({
      selcted: this.props.selectedCodes,
      codes: codes
    }));

    // Calculate list height based on number of codes
    const listHeight = this.props.codes.length === 0 
      ? 0
      : this.props.codes.length <= EMPTY_STATE_CODE_THRESHOLD 
        ? NORMAL_HEIGHT * (this.props.codes.length + 2)
        : height - 5;

    return (
      <div style={{height: height -1, overflow: 'hidden'}}>
        <div style={{padding:"0px", paddingLeft: CODE_PADDING_LEFT}}>
          <List
            width={345}
            height={listHeight}
            rowCount={this.props.codes.length + 1}
            rowHeight={this.rowHeight}
            rowRenderer={this.rowRenderer}
            searchText={this.props.searchText}
            disabled={this.props.disabled}
            draggingEnabled={this.props.draggingEnabled}
            dragCode={this.props.dragCode}
            codeHash={hash}
            hideCodeCounts={this.props.hideCodeCounts}
            ref={(ref)=>{this.list=ref}}
            scrollToIndex={this.props.scrollIndex}
            scrollToAlignment={'start'}
          />
        </div>
        <CodeListEmptyState 
          codes={this.props.codes}
          searchText={this.props.searchText}
          showEmptyStateOnboarding={this.props.showEmptyStateOnboarding}
        />
      </div>
    );
  }
}

export default withToastManager(CodeNestedDisplay);
