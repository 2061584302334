import React, {useCallback} from 'react';
import SurveyQuestionText from './SurveyQuestionText';
import SurveyQuestionOption from './SurveyQuestionOption';
import { useSurveySelectCallbacks } from './surveySelectCallbacks';
import { useSurveyAnswer } from 'hooks/surveys/queries'; // Add this import

const SurveyQuestion = ({
  responseId,
  questionText,
  questionType,
  questionId,
  surveyAnswer: initialSurveyAnswer, // Rename to indicate it's the initial data
  excerpts,
  selectedExcerptId,
  select,
  onMouseDown,
  disabled,
  onExcerptSelected,
  onDeselect,
  onOptionClicked,
  onGotoQuestion,
  onSelectText,
  hasEditPermission
}) => {
  // Use the answer ID from the initial data to get the latest version from cache
  const answerId = initialSurveyAnswer?.id;
  
  // Get the answer from the cache if available, otherwise use the initial data
  const { data: cachedAnswer } = useSurveyAnswer(answerId);
  
  // Use the cached version if available, otherwise fall back to the prop
  const surveyAnswer = cachedAnswer || initialSurveyAnswer;

  const onGotoQuestionWithId = useCallback(() => {
    if ( questionId )
      onGotoQuestion(questionId);
  }, [onGotoQuestion, questionId]);

  // (onSelectText, surveyResponseID, surveyAnswerId, surveyQuestionId) => {
  const {
    onSurveyAnswerSelected,
    onParagraphClick
  } = useSurveySelectCallbacks(onSelectText, responseId, surveyAnswer?.id, questionId);

  const textAnswer = questionType === 'text';
  
  if ( !textAnswer ) {
    return <SurveyQuestionOption
      surveyAnswer={surveyAnswer}
      questionId={questionId}
      questionText={questionText}
      disabled={disabled}
      onClick={onOptionClicked}
    />
  }

  return (
    <SurveyQuestionText
      questionText={questionText}
      questionType={questionType}
      surveyQuestionId={questionId}
      surveyAnswer={surveyAnswer}
      excerpts={excerpts}
      selectedExcerptId={selectedExcerptId}
      select={select}
      onParagraphClick={onParagraphClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      onExcerptSelected={onExcerptSelected}
      onDeselect={onDeselect}
      onSurveyAnswerSelected={onSurveyAnswerSelected}
      onTitleClick={onGotoQuestionWithId}
      logo={'align-left'}
      color={"#3D6096"}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default SurveyQuestion;