import AppDispatcher from 'dispatcher/AppDispatcher'
import AuthConstants from 'constants/AuthConstants'
import State from 'stores/State'
import authReducer from 'reducers/AuthReducer'
import forgotPasswordMapper from 'mappers/ForgotPasswordMapper'
import resetPasswordMapper from 'mappers/ResetPasswordMapper'
import acceptInviteMapper from 'mappers/AcceptInviteMapper'
import loginMapper from 'mappers/LoginMapper'
import LocalAPI from 'utils/LocalAPI'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

const TOKEN  = "TOKEN";
// this would be a good env variable
const authLog = false;

function _isSessionValid(session)
{
  if (  session &&
        session['access-token'] &&
        session.uid &&
        session.client &&
        session.expiry
      )
      return true;

  return false;
}

function _buildSession(accessToken, uid, client, expiry)
{
  if ( !accessToken || !uid || !client || !expiry) return null;

  return {
      "access-token":accessToken,
      "uid":uid,
      "client":client,
      "expiry":expiry,
      "token-type":"Bearer",
      "If-Modified-Since": 0 // this is so caching doesn't happen. This messes with the access token
  }
}

function _buildTokenFromSession(session)
{
  if ( !session ) return null;
  if ( !_isSessionValid(session)) return null;

  return {
    "access-token":session["access-token"],
    "uid":session.uid,
    "client":session.client,
    "expiry":session.expiry
  }
}

function _setSession(session)
{
  if ( authLog ) console.log("session: " + JSON.stringify(session));
  var token = _buildTokenFromSession(session);
  if ( authLog ) console.log("token: " + JSON.stringify(token));
  if ( !token )
    localStorage.removeItem(TOKEN);

  localStorage.setItem(TOKEN, JSON.stringify(token));
}

function _signOut()
{
  _setSession(null);
  LocalAPI.clearAllTranscriptDrafts();
}

function getSession()
{
  const tokenString = localStorage.getItem(TOKEN);
  if ( !tokenString ) return null;

  var token = null;
  try {
    token = JSON.parse(tokenString);
   } catch(e) {
     token = null;
     localStorage.removeItem(TOKEN);
  }

  if ( !token ) return null;

  const session = _buildSession(token['access-token'], token.uid, token.client, token.expiry);
  if ( !session ) return null;
  return session;
}

function _recievedSession(data)
{
  const session = _buildSession(data.headers['access-token'],
                            data.headers.uid,
                            data.headers.client,
                            data.headers.expiry,
                          );

  _setSession(session);
}

function _updateToken(data)
{
  var newSession = {};
  const headers = ["access-token", "uid", "client", "expiry"];

  for ( var i = 0; i < headers.length; i++ )
  {
    var header = headers[i];
    if ( data[header] )
      newSession[header] = data[header];
  }

  const storedSession = getSession();
  const session = Object.assign({}, storedSession, newSession);
  _setSession(session);
}

var AuthStore = _.extend({}, EventEmitter.prototype, {
  updateToken: function(data) {
    _updateToken(data);
  },

  getAcceptInviteState: function() {
    return acceptInviteMapper(State.get());
  },

  getResetPasswordState: function() {
    return resetPasswordMapper(State.get());
  },

  getForgotPasswordState: function() {
    return forgotPasswordMapper(State.get());
  },

  getLoginState: function() {
    return loginMapper(State.get());
  },

  getSignupState: function() {
      return State.get().signupState;
  },

  getPasswordState: function() {
    return State.get().passwordState;
  },

  isLoggedIn: function() {
    const session = getSession();
    return session != null;
  },

  getCableURL: function() {
    const session = getSession();
    var location = window.location.host;
    if ( _isSessionValid(session))
      return `${SOCKET_URL_PREFIX}://${location}/cable?uid=${encodeURIComponent(session.uid)}&token=${session['access-token']}&client=${session.client}`
    else
      return null;
  },

  getHeader: function() {
    const session = getSession();
    if ( authLog ) console.log("getHeader: " + JSON.stringify(session));
    return {headers:session}
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case AuthConstants.AUTH_UPDATE_TOKEN:
      _updateToken(action.data);
      return true;
      break
    case AuthConstants.AUTH_SIGN_OUT_RESPONSE:
      _signOut();
      State.set(authReducer(action, State.get()));
    break;
    case AuthConstants.AUTH_SIGN_UP_RESPONSE:
    case AuthConstants.AUTH_SIGN_IN_RESPONSE:
      _recievedSession(action.data);
    case AuthConstants.AUTH_SIGN_IN:
    case AuthConstants.AUTH_SIGN_UP:
    case AuthConstants.AUTH_SIGN_IN_ERROR:
    case AuthConstants.AUTH_SIGN_UP_ERROR:
    case AuthConstants.AUTH_NEW_PASSWORD_ERROR:
    case AuthConstants.AUTH_NEW_PASSWORD_RESPONSE:
    case AuthConstants.AUTH_CHANGE_PASSWORD_MODAL:
    case AuthConstants.AUTH_RESET_PASSWORD_RESPONSE:
    case AuthConstants.AUTH_RESET_PASSWORD_ERROR:
    case AuthConstants.AUTH_FORGOT_PASSWORD_ERROR:
    case AuthConstants.AUTH_FORGOT_PASSWORD_RESPONSE:
    case AuthConstants.AUTH_ACCEPT_INVITE_ERROR:
    case AuthConstants.AUTH_ACCEPT_INVITE_RESPONSE:
      State.set(authReducer(action, State.get()));
    break;
    default:
      return true;
  }

  AuthStore.emitChange();
  return true;
});

export default AuthStore
