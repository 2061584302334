import React from 'react';
import ProjectInfoWrapper from '../ProjectInfoWrapper';
import SNavigation from '../SNavigation';

const GenericRoute = ({ projectID, transcriptID, page, subscriptionState, isSubscriptionActive, codeID, rightSideBar = false, setProjectID }) => (
  <ProjectInfoWrapper
    projectID={projectID}
    isSubscriptionActive={isSubscriptionActive}
    setProjectID={setProjectID}
  >
    <SNavigation
      transcriptID={transcriptID}
      projectID={projectID}
      page={page}
      rightSideBar={rightSideBar}
      subscriptionState={subscriptionState}
      isSubscriptionActive={isSubscriptionActive}
      codeID={codeID}
    />
  </ProjectInfoWrapper>
);

export default GenericRoute;
