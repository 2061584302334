// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useContext, useCallback} from 'react';

import CodeDisplay from './CodeDisplay'
import CodingActions from 'actions/CodingActions';
import CodeableContext from 'contexts/CodeableContext';

var _ = require('underscore');

// function react component named CodeContainer below
function CodeContainer({
  disabled,
  hideCodeCounts,
  projectID,
  height,
  showEmptyStateOnboarding
}) {
  const {
    selectedCodeIds,
    onNewCode,
    select,
    onAddCode,
    onRemoveCode
  } = useContext(CodeableContext);

  const onClick = useCallback((code_id, selected) => {
    if ( !disabled ) {
      if ( !selected )
        onAddCode(code_id);
      else
        onRemoveCode(code_id);
    } 
    
    CodingActions.expandParents(projectID, code_id);
  }, [disabled, onAddCode, onRemoveCode, projectID]);

  return (
    <CodeDisplay
      disabled={disabled}
      selectedCodes={disabled ? [] : selectedCodeIds}
      onClick={onClick}
      addNewCode={onNewCode}
      projectID={projectID}
      height={height}
      select={select}
      hideCodeCounts={hideCodeCounts}
      showEmptyStateOnboarding={showEmptyStateOnboarding}
    />
  );
}

export default CodeContainer;