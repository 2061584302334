import { Switch, Route } from 'react-router-dom';
import React from 'react';

import CashierStore from 'stores/CashierStore'
import ErrorStore from 'stores/ErrorStore'
import UserStore from 'stores/UserStore'

import PageConstants from 'constants/PageConstants'
import PageParserUtil from 'utils/PageParserUtil'

import QualCodeActions from 'actions/QualCodeActions'
import CodingActions from 'actions/CodingActions'
import FilterActions from 'actions/FilterActions'
import UpgradeActions from 'actions/UpgradeActions'
import CashierActions from 'actions/CashierActions'
import FeatureFlagActions from 'feature_flags/FeatureFlagActions';

import TranscriptRoute from './routes/TranscriptRoute';
import ProjectRoute from './routes/ProjectRoute';
import GenericRoute from './routes/GenericRoute';
import CableLayer from './CableLayer';
import TranscriptPrintContainer from './TranscriptPrintContainer';
import ProjectInfoWrapper from './ProjectInfoWrapper';

class NetworkLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pageChange = this.pageChange.bind(this);
    this._onChange = this._onChange.bind(this);
    this.setProjectID = this.setProjectID.bind(this);

    setTimeout(function() { // Run after dispatcher has finished
      QualCodeActions.fetchUser();
      CashierActions.fetchSubscriptions();
      FeatureFlagActions.getFeatureFlags()
    }, 0);
  }

  setProjectID(projectID) {
    this.setState({
      projectID: projectID
    });
  }

  _onChange (props) {
    this.setState({
        transcriptNotFoundError: ErrorStore.getTranscriptNotFound(this.state.transcriptID),
        projectNotFoundError: ErrorStore.getProjectNotFound(this.state.projectID),
        subscriptionState: (CashierStore.getSubscriptions() || {}).subscriptionState
    });
  }

  componentDidMount() {
    var thisPage = PageParserUtil.parse(this.props.match, this.props.location);
    var that = this;
    setTimeout(function() { // Run after dispatcher has finished
      if ( that )
        that.pageChange(null, thisPage);
    }, 0);
    ErrorStore.addChangeListener(this._onChange);
    CashierStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    ErrorStore.removeChangeListener(this._onChange);
    CashierStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
  }

  loadCode(codeID, projectID)
  {
    CodingActions.getCode(codeID, projectID);
  }

  fetchUserPage()
  {
    const userPromise = QualCodeActions.fetchUser();
    UpgradeActions.fetchUpgrades();

    const subscriptionPromise = CashierActions.fetchSubscriptions();

    Promise.all([userPromise, subscriptionPromise]).then((values)=>{
      const subscriptionState = CashierStore.getSubscriptions().subscriptionState;

      if ( subscriptionState.subscription && subscriptionState.subscription.isOwner )
      {
        CashierActions.fetchSeats(subscriptionState.subscription.id);
      }
    });
  }

  loadTranscriptPage(transcriptID)
  {
    this.setState({
      transcriptID: transcriptID,
    })

    QualCodeActions.loadTranscript(transcriptID).then((response)=>{
      const {project_id} = response;
      if (!project_id) return;

      this.setState({
        transcriptID: transcriptID,
        projectID: project_id
      })

    }).catch((error)=>{
    })
  }

  pageChange(previousPage, nextPage)
  {
    if ( previousPage == null )
    {
      QualCodeActions.loadProjects();
    }

    this.setState({
      page: nextPage?.page
    });

    switch (nextPage?.page)
    {
      case PageConstants.ROOT_PAGE:
        this.setState({
          transcriptID: null,
          projectID: null,
        })
      break;
      case PageConstants.ANALYSIS_PAGE:
      case PageConstants.CHAT_PAGE:
      {
        const projectID = nextPage.params.projectID;
        QualCodeActions.requestDescriptors(projectID);
        QualCodeActions.fetchProjectSelections(projectID);
        FilterActions.filterByCodes(projectID);
      }
      case PageConstants.PROJECT_PAGE:
      case PageConstants.CODE_BOOK_PAGE:
      case PageConstants.CODE_BOOK_CODE_PAGE:
      case PageConstants.SEARCH_PAGE:
      case PageConstants.NEW_TRANSCRIPT_PAGE:
      case PageConstants.NEW_SURVEY_PAGE:
      {
        const projectID = nextPage.params.projectID;
        const codeID = nextPage.params.codeID;

        if ( codeID && projectID)
        {
          this.loadCode(codeID, projectID);
        }

        if ( projectID )
        {
          this.setState({
            transcriptID: null,
            projectID: projectID,
            codeID: codeID,
          })
        }
        else
        {
          this.setState({
            transcriptID: null,
            projectID: null,
            codeID: codeID,
          })
        }
      }
      break;
      case PageConstants.TRANSCRIPT_PAGE:
      case PageConstants.CODED_BY_ME_PAGE:
      case PageConstants.COMPARISON_PAGE:
      case PageConstants.DEMOGRAPHICS_PAGE:
      case PageConstants.EDIT_TRANSCRIPT_PAGE:
      {
        if ( nextPage.params.transcriptID )
        {
          this.loadTranscriptPage(nextPage.params.transcriptID);
        }
        else
        {
          this.setState({
            transcriptID: null,
            projectID: null
          })
        }
      }
      break;
      case PageConstants.USER_PAGE:
      {
        // TODO: should I wipe it out like this, might be good to not wipe stuff out...
        this.setState({
          transcriptID: null,
          projectID: null
        })

        this.fetchUserPage();
      }
      break;
      default: {
        this.setState({
          transcriptID: null
        })
      };
      break;
    }
  }

  componentWillReceiveProps (nextProps) {
    var nextPage = PageParserUtil.parse(nextProps.match, nextProps.location);
    var thisPage = PageParserUtil.parse(this.props.match, this.props.location);

    if ( !PageParserUtil.isEqual(nextPage, thisPage) )
    {
      var that = this;
      setTimeout(function() { // Run after dispatcher has finished
        that.pageChange(thisPage, nextPage);
      }, 0);
    }
  }


  isSubscriptionActive() {
    const subscription = (this.state.subscriptionState || {}).subscription;
    if ( !subscription ) return false;
    return subscription.status !== 'canceled';
  }

  render() {
    const { transcriptID, projectID, page, subscriptionState, codeID, transcriptNotFoundError, projectNotFoundError } = this.state;
    const isSubscriptionActive = this.isSubscriptionActive();
    
    return (
      <div>
        { !!SOCKET_URL_PREFIX && <CableLayer projectID={projectID}/> }
        <Switch>
          <Route
            path="/transcripts/:transcriptID/print"
            render={() => (
              <div style={{ width: "700px" }}>
                <ProjectInfoWrapper
                  projectID={projectID}
                  isSubscriptionActive={isSubscriptionActive}
                >
                  <TranscriptPrintContainer
                    isSubscriptionActive={isSubscriptionActive}
                    transcriptID={transcriptID}
                    projectID={projectID}
                    page={page}
                    {...this.props}
                    width={window.innerWidth}
                    height={window.innerHeight}
                  />
                </ProjectInfoWrapper>
              </div>
            )}
          />
          <Route
            path="/transcripts/:transcriptID/compare"
            render={() => (
              <TranscriptRoute
                transcriptID={transcriptID}
                projectID={projectID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                transcriptNotFoundError={transcriptNotFoundError}
                rightSideBar={false}
              />
            )}
          />
          <Route
            path="/transcripts/:transcriptID"
            render={() => (
              <TranscriptRoute
                transcriptID={transcriptID}
                projectID={projectID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                transcriptNotFoundError={transcriptNotFoundError}
                rightSideBar={true}
              />
            )}
          />
          <Route
            path="/surveys/:surveyID"
            render={() => (
              <GenericRoute
                projectID={projectID}
                transcriptID={transcriptID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                rightSideBar={false}
                setProjectID={this.setProjectID}
              />
            )}
          />
          <Route
            path="/survey_responses/:surveyResponseID"
            render={() => (
              <GenericRoute
                projectID={projectID}
                transcriptID={transcriptID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                rightSideBar={true}
                setProjectID={this.setProjectID}
              />
            )}
          />
          <Route
            path="/survey_questions/:surveyQuestionID"
            render={() => (
              <GenericRoute
                projectID={projectID}
                transcriptID={transcriptID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                rightSideBar={true}
                setProjectID={this.setProjectID}
              />
            )}
          />
          <Route
            path="/projects/:projectID/analysis"
            render={() => (
              <ProjectRoute
                projectID={projectID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                projectNotFoundError={projectNotFoundError}
                setProjectID={this.setProjectID}
                rightSideBar={true}
                requiresExcerpts={true}
              />
            )}
          />
          <Route
            path="/projects/:projectID/chat"
            render={() => (
              <ProjectRoute
                projectID={projectID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                projectNotFoundError={projectNotFoundError}
                setProjectID={this.setProjectID}
                rightSideBar={true}
                requiresExcerpts={true}
              />
            )}
          />
          <Route 
            path='/users'
            render={() => (
              <GenericRoute
                projectID={projectID}
                transcriptID={transcriptID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                rightSideBar={false}
                setProjectID={this.setProjectID}
              />
            )}
          />
          <Route 
            render={() => (
              <ProjectRoute
                projectID={projectID}
                page={page}
                subscriptionState={subscriptionState}
                isSubscriptionActive={isSubscriptionActive}
                codeID={codeID}
                projectNotFoundError={projectNotFoundError}
                setProjectID={this.setProjectID}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default NetworkLayer;
