import React from 'react';
import {
  ACTIVE_BUTTON_BLUE
} from 'constants/ColorConstants';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '16px',
    width: '100%',
    minWidth: '150px', // Minimum width
    marginLeft: 'auto' // This aligns it to the right
  },
  step: {
    height: '4px',
    flex: 1,           // Each step takes equal space
    borderRadius: '999px',
    backgroundColor: '#E5E7EB'
  },
  activeStep: {
    backgroundColor: ACTIVE_BUTTON_BLUE
  }
};

const ProgressSteps = ({ currentStep = 1, totalSteps = 3 }) => {
  return (
    <div style={styles.container}>
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          style={{
            ...styles.step,
            ...(index + 1 <= currentStep ? styles.activeStep : {})
          }}
        />
      ))}
    </div>
  );
};

export default ProgressSteps;