import React from 'react';
import ProjectInfoWrapper from '../ProjectInfoWrapper';
import SNavigation from '../SNavigation';

const ProjectRoute = ({ projectID, page, subscriptionState, isSubscriptionActive, codeID, projectNotFoundError, setProjectID, rightSideBar, requiresExcerpts }) => (
  <ProjectInfoWrapper
    projectID={projectID}
    isSubscriptionActive={isSubscriptionActive}
    setProjectID={setProjectID}
  >
    <SNavigation
      projectID={projectID}
      page={page}
      rightSideBar={rightSideBar}
      subscriptionState={subscriptionState}
      isSubscriptionActive={isSubscriptionActive}
      codeID={codeID}
      projectNotFoundError={projectNotFoundError}
      requiresExcerpts={requiresExcerpts}
    />
  </ProjectInfoWrapper>
);

export default ProjectRoute;
