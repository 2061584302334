import React, { useState, useEffect } from 'react';
import Question from './Question';
import { useHistory } from 'react-router-dom'; 
import DelveAccountBody from '../projects/components/DelveAccountBody';

import ROLE from './role.json';
import USAGE from './usage.json';
import JOBS from './jobs.json';
import SubscriptionRedirectComponent from 'projects/components/SubscriptionRedirectComponent';
import {Button} from 'react-bootstrap';
import { ACTIVE_BUTTON_BLUE } from '../constants/ColorConstants';
import SplitContainer from 'sign_up/SplitContainer';
import PQLLeftContainer from 'sign_up/PQLLeftContainer';

const QUESTIONS = [ROLE, USAGE, JOBS];

const initializeAnswers = () => ({
    [ROLE.type]: null,
    [USAGE.type]: null,
    [JOBS.type]: null
});

const shouldRandomize = (type) => {
    return [ROLE.type, JOBS.type].includes(type)
}

function PQL({}) {
    const history = useHistory();
    const [hasInteracted, setHasInteracted] = useState(false);
    const [answers, setAnswers] = useState(() => {
        try {
            const savedAnswers = localStorage.getItem('intro_survey_answers');
            return savedAnswers ? JSON.parse(savedAnswers) : initializeAnswers();
        } catch (error) {
            console.error('Error accessing localStorage', error);
            return initializeAnswers();
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem('intro_survey_answers', JSON.stringify(answers));
        } catch (error) {
            console.error('Error accessing localStorage', error);
        }
    }, [answers]);

    const onResponse = (type, value) => {
        setHasInteracted(true);
        setAnswers(prevAnswers => ({ ...prevAnswers, [type]: value }));
    };

    const getAnswer = (type) => answers[type];

    useEffect(() => {
        if (!process.env['PQL_FLAG']) {
            history.push('/subscribe');
        }
    }, [history]);

    const allQuestionsAnswered = Object.keys(answers)
        .filter(key => QUESTIONS.some(question => question.type === key))
        .map(key => answers[key])
        .every(answer => answer !== null);

    useEffect(() => {
        if ( allQuestionsAnswered && !hasInteracted) {
            history.push('/subscribe');
        }
    }, [allQuestionsAnswered, hasInteracted, history]);

    const handleNextClick = () => {
        if (allQuestionsAnswered) {
            history.push('/subscribe');
        }
    };

    return (
        <SplitContainer
            leftContent={<PQLLeftContainer />}
        >
            <DelveAccountBody
                subHeader="Tell us about you to get the most out of Delve."
                currentStep={2}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
                    <SubscriptionRedirectComponent />
                    <div style={{ textAlign: 'left', padding: '5px' }}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            {QUESTIONS.map((question, index) => (
                                <Question
                                    key={index}
                                    question={question.question}
                                    answers={question.answers}
                                    type={question.type}
                                    onResponse={onResponse}
                                    answer={getAnswer(question.type)}
                                    randomize={shouldRandomize(question.type)}
                                />
                            ))}
                        </form>

                        <div style={{color: ACTIVE_BUTTON_BLUE}}>
                            * Required Question
                        </div>
                    </div>

                    <Button 
                        bsSize='large' 
                        bsStyle='blue' 
                        style={{width:'200px', marginTop: '10px'}} 
                        disabled={!allQuestionsAnswered}
                        onClick={handleNextClick}
                    >
                        Next
                    </Button>
                </div>
            </DelveAccountBody>
        </SplitContainer>
    );
}

export default PQL;