import React from 'react';
import PropTypes from 'prop-types';
import FullPageMessage from './FullPageMessage';
import MediaContent from './MediaContent';

function MediaMessage({
  header,
  body,
  onClick,
  buttonText,
  subButtonText,
  intercomArticle,
  mediaType,
  mediaSrc,
  height,
  secondBody,
  maxWidth,
}) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: height,
      overflow: 'hidden'
    }}>
      <div style={{ flexShrink: 0 }}>
        <FullPageMessage
          header={header}
          body={body}
          onClick={onClick}
          buttonText={buttonText}
          intercomArticle={intercomArticle}
          subButtonText={subButtonText}
          subButtoNewWindowLink={mediaType === 'video'}
          secondBody={secondBody}
        />
      </div>
      <div style={{
        width: '80%',
        margin: '80px auto 30px',
        maxHeight: `calc(${height} - 300px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}>
        <MediaContent
          mediaType={mediaType}
          mediaSrc={mediaSrc}
          header={header}
          maxWidth={maxWidth}
        />
      </div>
    </div>
  );
}

MediaMessage.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  subButtonText: PropTypes.string,
  intercomArticle: PropTypes.string,
  mediaType: PropTypes.oneOf(['video', 'image']),
  mediaSrc: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node
};
export default MediaMessage;
