// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import DemographicDescriptorItem from './DemographicDescriptorItem';

var _ = require('underscore');

const ViewOnlyDemographicView = ({ descriptors }) => {
  const messagesEndRef = useRef(null);

  const descriptorElements = descriptors.map((descriptor, index) => (
    <DemographicDescriptorItem 
      key={index}
      descriptor={descriptor}
    />
  ));

  return (
    <div style={{padding:"10px", height:"100%"}}>
      <Scrollbars autoHide style={{height:"100%"}}>
        {descriptorElements}
        <div style={{height:"200px", width:"10px"}}/>
        <div style={{ float:"left", clear: "both" }}
             ref={messagesEndRef}>
        </div>
      </Scrollbars>
    </div>
  );
};

export default ViewOnlyDemographicView;
