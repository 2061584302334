import { v1 as uuidv1 } from 'uuid';

import AppDispatcher from 'dispatcher/AppDispatcher';
import AuthConstants from 'constants/AuthConstants';
import FileConstants from 'constants/FileConstants';
import LocalAPI from 'utils/LocalAPI';
import ParagraphLocator from 'utils/ParagraphLocator';
import ProjectsStore from 'stores/ProjectsStore';
import QualAPI from 'utils/QualAPI';
import SearchAPI from 'apis/SearchAPI'
import QualConstants from 'constants/QualConstants';
import asyncUtil from 'utils/AsyncUtil';
import diffUtil from 'utils/DiffUtil';

const Joi = require('joi');

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var QualCodeActions = {
  /*****************************\
  *
  * AUTH ACTIONS
  *
  \*****************************/
  signUp: function(name, email, password)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_SIGN_UP,
      data: null
    });

    return QualAPI.signUp(name, email, password).then((response) => {
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_SIGN_UP_RESPONSE,
        data: response
      });
    })
    .catch((error) => {
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_SIGN_UP_ERROR,
        data: ((error.response || {}).data || {})
      });
    });
  },

  signIn: function(email, password)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_SIGN_IN,
      data: null
    })

    QualAPI.signIn(email, password).then((response) => {
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_SIGN_IN_RESPONSE,
        data: response
      })
    })
    .catch((error) => {
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_SIGN_IN_ERROR,
        data: (error.response || {}).data
      });
    });
  },

  signOut: function()
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_SIGN_OUT,
      data: null
    });

    QualAPI.signOut().then((response) => {
      AppDispatcher.handleAction({
        actionType:AuthConstants.AUTH_SIGN_OUT_RESPONSE,
        data: null
      })
    })
    .catch(function (error) {
      AppDispatcher.handleAction({
        actionType:AuthConstants.AUTH_SIGN_OUT_RESPONSE,
        data: null
      })
    });
  },

  showPasswordModal: function(show)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_CHANGE_PASSWORD_MODAL,
      data: {
        show: show
      }
    });
  },

  changePassword: function(currentPassword, newPassword, verificationPassword)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_NEW_PASSWORD,
      data: {}
    })

    QualAPI.changePassword(currentPassword, newPassword, verificationPassword).then((response)=>{
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_NEW_PASSWORD_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_NEW_PASSWORD_ERROR,
        data: error.response.data,
        error: true
      })
    });
  },

  forgotPassword: function(email)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_FORGOT_PASSWORD,
      data:{},
    })

    QualAPI.forgotPassword(email).then((response)=>{
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_FORGOT_PASSWORD_RESPONSE,
        data: response.data
      });
    }).catch((error)=>{
      // TODO: sadly lots of ways errors could come in...
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_FORGOT_PASSWORD_ERROR,
        data: error.response,
        errors: error.response.data,
      });
    });
  },

  resetPassword: function(token, newPassword, verificationPassword)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_RESET_PASSWORD,
      data:{},
    })

    QualAPI.resetPassword(token, newPassword, verificationPassword).then((response)=>{
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_RESET_PASSWORD_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      // TODO: sadly lots of ways errors could come in...
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_RESET_PASSWORD_ERROR,
        data: error.response,
        errors: error.response.data,
      })
    });
  },

  acceptInvite: function(token, newPassword, verificationPassword, name)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_ACCEPT_INVITE,
      data:{},
    })

    QualAPI.acceptInvite(token, newPassword, verificationPassword, name).then((response)=>{
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_ACCEPT_INVITE_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      // TODO: sadly lots of ways errors could come in...
      AppDispatcher.handleAction({
        actionType: AuthConstants.AUTH_ACCEPT_INVITE_ERROR,
        data: error.response,
        errors: error.response.data,
      })
    });
  },

  updateAuthToken: function(headers)
  {
    AppDispatcher.handleAction({
      actionType: AuthConstants.AUTH_UPDATE_TOKEN,
      data: headers
    })
  },

  /*****************************\
  *
  * Projects
  *
  \*****************************/
  loadProjects: function()
  {
    return QualAPI.getProjects().then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.LOADED_PROJECTS,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.LOAD_PROJECTS_ERROR,
        error: error
      });
    });
  },

  loadProject: function(projectID)
  {
    const loadState = ProjectsStore.getProjectLoadState(projectID);
    if ( loadState.loading || loadState.loaded )
      return;

    AppDispatcher.handleAction({
      actionType: QualConstants.LOAD_PROJECT_DETAILS,
      data: {project_id: projectID}
    })

    QualAPI.getProject(projectID).then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.LOAD_PROJECT_DETAILS_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.LOAD_PROJECT_DETAILS_ERROR,
        data: {project_id: projectID},
        error: error
      });
    });
  },

  duplicateProject: function(projectId) {
    return QualAPI.duplicateProject(projectId).then((response)=>{
      return response.data;
    })
  },

  newProject: function(projectName, projectDescription)
  {
    const payload = {
      name: projectName,
      description: projectDescription
    }

    AppDispatcher.handleAction({
      actionType: QualConstants.NEW_PROJECT,
      data:payload
    });

    QualAPI.newProject(projectName, projectDescription).
    then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.NEW_PROJECT_RESULT,
        data:response.data
      });
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.NEW_PROJECT_ERROR,
        data:payload,
        error: error
      });
    });
  },

  projectUpdateCable: function(result)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.PROJECT_CABLE,
      data: result
    })
  },

  updateProjectName: function(projectID, name)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_PROJECT_NAME,
      data: {id: projectID, name: name}
    })

    QualAPI.updateProjectName(projectID, name).then((result) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_PROJECT_NAME_RESULT,
        data: result.data
      })
    }).catch(function(error) {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_PROJECT_NAME_ERROR,
        data: error
      })
    });
  },

  loadTranscript: function(transcriptID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.LOAD_TRANSCRIPT,
      data: {
        transcript_id:transcriptID
      }
    })

    return QualAPI.getTranscript(transcriptID).then(response => {
      AppDispatcher.handleAction({
        actionType: QualConstants.LOAD_TRANSCRIPT_RESULTS,
        data: response.data
      })

      return response.data;
    }).catch(error => {
      AppDispatcher.handleAction({
        actionType: QualConstants.LOAD_TRANSCRIPT_ERROR,
        data: {
          transcript_id:transcriptID
        },
        error: error
      })
      throw error;
    });
  },

  updateCodeSynthesis: function(codeID, synthesis)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_CODE_SYNTHESIS,
      data: {
        id: codeID,
        synthesis: synthesis
      }
    });

    asyncUtil.getServerCode(codeID).then((serverCodeID)=>{
      QualAPI.updateCodeSynthesis(serverCodeID, synthesis).then(response => {
        AppDispatcher.handleAction({
          actionType: QualConstants.UPDATE_CODE_SYNTHESIS_RESULT,
          data: response.data,
        })
      })
      .catch(error => {
        console.error(error);
        AppDispatcher.handleAction({
          actionType: QualConstants.UPDATE_CODE_SYNTHESIS_ERROR,
          data: error
        })
      });
    });
  },

  /***********************\
  *
  * Search
  *
  \***********************/
  searchTranscripts: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.TRANSCRIPT_SEARCH,
      data: data
    })

    SearchAPI.searchTranscripts(data.project_id, data.query).then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.TRANSCRIPT_SEARCH_RECEIVED,
        data: {
          project_id: data.project_id,
          ...response.data
        }
      })
    }).catch(error => {
        console.error(error);
        AppDispatcher.handleAction({
          actionType: QualConstants.TRANSCRIPT_SEARCH_RECEIVED_ERROR,
          data: data,
          error: error
        })
    });
  },

  /***********************\
  *
  * Transcripts
  *
  \***********************/
  addTranscriptCable: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.TRANSCRIPT_CREATE_CABLE,
      data: data
    })
  },

  updateTranscriptCable: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_TRANSCRIPT_CABLE,
      data: data
    })
  },

  deleteTranscriptCable: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.DELETE_TRANSCRIPT_CABLE,
      data: data
    })
  },

  updateTranscript: function(transcriptID, name)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_TRANSCRIPT,
      data: {id: transcriptID}
    })
    QualAPI.updateTranscript(transcriptID, {name: name}).then((result) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_TRANSCRIPT_RESULT,
        data: result.data
      })
    }).catch(function(error) {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_TRANSCRIPT_ERROR,
        data: error
      })
    });
  },

  deleteTranscript: function(transcriptID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.DELETE_TRANSCRIPT,
      data: {id: transcriptID}
    })
    QualAPI.deleteTranscript(transcriptID).then((result) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_TRANSCRIPT_RESULT,
        data: {id: transcriptID}
      })
    }).catch(function(error) {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_TRANSCRIPT_ERROR,
        data: error
      })
    });
  },

  /***********************\
  *
  * Prime Code
  *
  \***********************/
  addPrimeCode: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.ADD_PRIME_CODE,
      data: data
    });
  },

  removePrimeCode: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.REMOVE_PRIME_CODE,
      data: data
    });
  },

  /*********************\
  *
  * Demographics
  *
  \*********************/
  _combineChoices(newChoices, updateChoices, deleteChoices)
  {
    deleteChoices = deleteChoices ? deleteChoices.map((choice)=> {return {id:choice.id, destroy:true}}) : [];
    newChoices = newChoices ? newChoices.map((choice)=> {return {choice:choice.choice, id:null}}) : [];
    updateChoices = updateChoices ? updateChoices : [];
    return newChoices.concat(deleteChoices, updateChoices);
  },

  createDescriptor: function(projectID, name, newChoices, updateChoices, deleteChoices)
  {
    const allChoices = this._combineChoices(newChoices, updateChoices, deleteChoices);
    AppDispatcher.handleAction({
      actionType: QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR,
      data: {name: name, multi_choices: allChoices}
    })

    return QualAPI.createDescriptor(projectID, name, allChoices).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR_ERROR,
        data: error
      });
    });
  },

  updateDescriptor: function(descriptorID, name, newChoices, updateChoices, deleteChoices)
  {
    const allChoices = this._combineChoices(newChoices, updateChoices, deleteChoices);

    AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR,
        data: {
          id: descriptorID,
          name: name,
          multi_choices: allChoices
        }
    });

    QualAPI.updateDescriptor(descriptorID, name, allChoices).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT,
        data: response.data
      });
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_ERROR,
        data: error
      })
     console.error(error);
    });
  },

  deleteDescriptor: function(projectID, descriptorID){
    AppDispatcher.handleAction({
      actionType: QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR,
      data: {
        id: descriptorID,
        project_id: projectID
      }
    });

    QualAPI.deleteDescriptor(descriptorID, projectID).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR_ERROR,
        data: error
      })
    });
  },


  getProjectIDFromTranscriptID: function(transcriptID)
  {
    return new Promise((resolve, reject) => {
      const projectID = ProjectsStore.getProjectIDFromTranscriptID(transcriptID);
      if ( projectID ) resolve(projectID);
      else reject();
    }).catch((error) => {
      return this.loadTranscript(transcriptID); // this is hard to remove without making other chains fail
    }).then((result) => {
      const projectID = ProjectsStore.getProjectIDFromTranscriptID(transcriptID);
      if ( projectID ) return projectID;
      else {throw Error("Transcript Details Not Found");}
    })
  },

  requestDescriptors: function(projectID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_DESCRIPTORS,
      data: {
        project_id: projectID
      }
    });

    return QualAPI.fetchDescriptors(projectID).then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_DESCRIPTORS_RESULT,
        data:response.data
      });
    }).catch((error)=>{
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_DESCRIPTORS_ERROR,
        data: {project_id: projectID},
        error
      });
    });
  },

  requestDescriptorsForTranscript: function(transcriptID)
  {
    this.getProjectIDFromTranscriptID(transcriptID).then((projectID)=>{
      return this.requestDescriptors(projectID);
    }).catch((error)=>{
      console.error(error);
      // maybe this error should be logged in some ways
      // But it is likely being dispatched in other places
      /*
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_DESCRIPTORS_ERROR,
        data: {transcript_id: transcriptID},
        error
      });*/
    });
  },

  /*********************\
  *
  * Selections
  *
  \*********************/
  requestSelections: function(transcriptID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_SELECTIONS,
      data: {transcript_id: transcriptID}
    })

    return QualAPI.fetchSelections(transcriptID).then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_SELECTIONS_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_SELECTIONS_ERROR,
        error: error,
        data: {transcript_id: transcriptID}
      })
    });
  },

  fetchProjectSelections: function(projectID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_PROJECT_SELECTIONS,
      data: {project_id: projectID}
    });

    return QualAPI.fetchProjectSelections(projectID).then((response) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_PROJECT_SELECTIONS_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_PROJECT_SELECTIONS_ERROR,
        data: {project_id: projectID},
        error: error
      });
    })
  },

  createMultiChoiceSelection: function(transcriptID, choiceID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CREATE_MULTI_CHOICE_SELECTION,
      data: {
        id: transcriptID,
        multi_choice_selection: {
          multi_choice_id: choiceID,
        }
      }
    })

    return QualAPI.createMultiChoiceSelection(transcriptID, choiceID).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_MULTI_CHOICE_SELECTION_RESULT,
        data: response.data
      })
      return response;
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_MULTI_CHOICE_SELECTION_ERROR,
        data: error
      })
    })
  },

  createMultiChoiceAndSelect: function(transcriptID, descriptorID, choice)
  {
    const tmpID = uuidv1();

    AppDispatcher.handleAction({
      actionType: QualConstants.CREATE_MULTI_CHOICE,
      data: {
        id: descriptorID,
        multi_choice: {
          id:tmpID,
          choice: choice
        }
      }
    });

    QualAPI.createMultiChoice(descriptorID, choice).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_MULTI_CHOICE_RESULT,
        data: {
          multi_choice_temp_id: tmpID,
          response: response.data
        }
      });
      return response.data.multi_choice.id;
    }).catch((error)=>{
      console.error(error);
      throw error;
    }).then((multi_choice_id)=>{
      return this.createMultiChoiceSelection(transcriptID, multi_choice_id)
    });
  },

  // New Project Modal
  showProjectModal: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.SHOW_NEW_PROJECT_MODAL,
      data: {}
    })
  },

  hideProjectModal: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.HIDE_NEW_PROJECT_MODAL,
      data: {}
    })
  },

  // User Actions
  fetchUser: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_USER,
      data: {}
    })

    return QualAPI.fetchUser().then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_USER_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_USER_ERROR,
        data: {},
        error: error
      })
    });
  },

  acceptTermsOfService: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.ACCEPT_TERMS_OF_SERVICE,
      data: {}
    })

    QualAPI.acceptTermsOfService().then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.ACCEPT_TERMS_OF_SERVICE_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.ACCEPT_TERMS_OF_SERVICE_ERROR,
        data: {},
        error: error
      })
    });
  },

  // Code Page Modal
  showCodePageModal: function(codeID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.SHOW_CODE_PAGE_MODAL,
      data:{
        code_id: codeID
      }
    })
  },

  hideCodePageModal: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.HIDE_CODE_PAGE_MODAL,
      data:{},
    })
  },

  /***********************\
  *
  * Collapsing Codes
  *
  \***********************/
  // code book collapse
  collapseCode: function(projectID, codeID)
  {
    asyncUtil.getServerCode(codeID).then((serverCodeID)=>{
      LocalAPI.collapseCode(projectID, serverCodeID);
    });

    AppDispatcher.handleAction({
      actionType: QualConstants.COLLAPSE_CODE,
      data:{
        project_id: projectID,
        code_id: codeID,
      }
    })
  },

  expandCode: function(projectID, codeID)
  {
    asyncUtil.getServerCode(codeID).then((serverCodeID)=>{
      LocalAPI.expandCode(projectID, serverCodeID);
    });

    AppDispatcher.handleAction({
      actionType: QualConstants.EXPAND_CODE,
      data:{
        project_id: projectID,
        code_id: codeID,
      }
    })
  },

  loadCodeBook: function(projectID)
  {
    const codeBook = LocalAPI.getCodeBook(projectID);

    AppDispatcher.handleAction({
      actionType: QualConstants.CODE_BOOK,
      data:{
        code_book: codeBook,
        project_id: projectID,
      }
    })
  },

  loadCodeBar: function(projectID)
  {
    const codeBar = LocalAPI.getCodeBar(projectID);

    AppDispatcher.handleAction({
      actionType: QualConstants.CODE_BAR,
      data:{
        code_bar: codeBar,
        project_id: projectID,
      }
    })
  },

  // EDIT PARAGRAPH
  editParagraph: function(transcript_id, paragraph_id)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.EDIT_PARAGRAPH,
      data:{
        transcript_id: transcript_id,
        paragraph_id: paragraph_id,
        edit: true
      },
    })
  },

  cancelEditParagraph: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CANCEL_EDIT_PARAGRAPH,
      data:{},
    })
  },

  replaceTranscriptText: function(transcriptID, version, newText, diffs, start, end)
  {
    const payload = {
      version: version,
      delete:{
        start: start,
        end: end,
      },
      diffs:diffs,
    };

    return QualAPI.createTranscriptVersion(transcriptID, payload)
  },

  doneEditParagraph: function(transcript_id, paragraph_id, newText)
  {
    // TODO: put this into a test, very sketchy code
    const transcript = ProjectsStore.getEditTranscript(transcript_id).transcript;
    const body = transcript.body;
    const paragraphLocator = new ParagraphLocator(transcript.body);
    const location = paragraphLocator.locate(paragraph_id);
    if ( !location ) return;

    const originalText = body.substring(location.start, location.end);
    const diffs = diffUtil.diff(originalText, newText);

    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_TRANSCRIPT_TEXT,
      data: {id: transcript_id}
    })

    this.replaceTranscriptText(transcript_id, transcript.version, newText, diffs, location.start, location.end).then((response)=>{
      this.loadTranscript(transcript_id).then((result) => {
        AppDispatcher.handleAction({
          actionType: QualConstants.UPDATE_TRANSCRIPT_TEXT_RESULT,
          data: {id: transcript_id}
        })
      });
    },
    (error)=>{
      dispatchError(QualConstants.UPDATE_TRANSCRIPT_TEXT_ERROR, error);
    }
    ).catch((error)=>{
      dispatchError(QualConstants.UPDATE_TRANSCRIPT_TEXT_ERROR, error);
    });
  },

  sendFeedback: function(feedback)
  {
    return QualAPI.sendFeedback(feedback)
  },

  dismissFile(dismiss) {
    if ( dismiss ) {
      AppDispatcher.handleAction({actionType: FileConstants.PARSE_FILES_HIDE});
    } else {
      AppDispatcher.handleAction({actionType: FileConstants.PARSE_FILES_SHOW});
    }
  },

  clearFileUpload() {
    AppDispatcher.handleAction({actionType: FileConstants.PARSE_FILE_CLEAR});
  },

  clearFileError() {
    AppDispatcher.handleAction({
      actionType: FileConstants.PARSE_FILES_ERROR,
      error: null
    })
  },

  filterCoders: function(coders)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.FILTER_CODERS,
      data:{
        coders
      },
    })
  },
}

export default QualCodeActions
