var _ = require('underscore');

// Fallback memory store for sessions
const memoryStore = {};

const CODE_BOOK_KEY = 'code_book';
const CODE_BAR_KEY = 'code_bar';
const TRANSCRIPT_DRAFT_KEY = 'transcript_draft';

function safeJSONParse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

function safeJSONStringify(value) {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return null;
  }
}

var LocalAPI = {
  _getKey(key, projectID)
  {
    return `${key}_${projectID}`;
  },

  _getCodeBookKey(projectID)
  {
    return this._getKey(CODE_BOOK_KEY, projectID)
  },

  _getCodeBarKey(projectID)
  {
    return this._getKey(CODE_BAR_KEY, projectID)
  },

  _getTranscriptDraftKey(projectID) {
    return this._getKey(TRANSCRIPT_DRAFT_KEY, projectID);
  },

  _getFromStorage(key) {
    try {
      if (localStorage) {
        return safeJSONParse(localStorage.getItem(key));
      }
    } catch (e) {
      // If localStorage is unavailable, fallback to memoryStore
    }
    return memoryStore[key] || null;
  },

  _setToStorage(key, value) {
    try {
      if (localStorage) {
        if (value === null || value === undefined) {
          localStorage.removeItem(key);
          return;
        }
        const stringValue = safeJSONStringify(value);
        if (stringValue) {
          localStorage.setItem(key, stringValue);
        }
        return;
      }
    } catch (e) {
      // If localStorage is unavailable, fallback to memoryStore
    }
    if (value === null || value === undefined) {
      delete memoryStore[key];
    } else {
      memoryStore[key] = value;
    }
  },

  _getAllKeysWithPrefix(prefix) {
    const keys = [];
    try {
      if (localStorage) {
        // Get all keys from localStorage object
        Object.keys(localStorage).forEach(key => {
          console.log(key)
          if (key.startsWith(prefix)) {
            keys.push(key);
          }
        });
      }
    } catch (e) {
      // if local storage is not available, it doesn't matter
      return [];
    }
    return keys;
  },

  clearAllTranscriptDrafts() {
    const keys = this._getAllKeysWithPrefix(TRANSCRIPT_DRAFT_KEY);
    
    keys.forEach(key => {
      this._setToStorage(key, null);
    });
  },

  getCodeObject(key)
  {
    return this._getFromStorage(key) || {};
  },

  getCodeBook(projectID)
  {
    return this.getCodeObject(this._getCodeBookKey(projectID), projectID);
  },

  getCodeBar(projectID)
  {
    return this.getCodeObject(this._getCodeBarKey(projectID), projectID);
  },

  saveTranscriptDraft(projectID, name, description) {
    const key = this._getTranscriptDraftKey(projectID);
    this._setToStorage(key, { name, description });
  },

  getTranscriptDraft(projectID) {
    const key = this._getTranscriptDraftKey(projectID);
    return this._getFromStorage(key) || { name: '', description: '' };
  },

  clearTranscriptDraft(projectID) {
    const key = this._getTranscriptDraftKey(projectID);
    this._setToStorage(key, null);
  },

  _setCodeObject(key, originalMapper, codeID, collapse)
  {
    const value = {
      ...originalMapper,
      [codeID]: collapse,
    };
    this._setToStorage(key, value);
  },

  _setCodeBook(projectID, codeID, collapse)
  {
    var key = this._getCodeBookKey(projectID);
    const originalMapper = this.getCodeBook(projectID);
    this._setCodeObject(key, originalMapper, codeID, collapse);
  },

  collapseCode(projectID, codeID)
  {
    this._setCodeBook(projectID, codeID, true);
  },

  expandCode(projectID, codeID)
  {
    this._setCodeBook(projectID, codeID, false);
  },

  _setCodeBar(projectID, codeID, collapse)
  {
    var key = this._getCodeBarKey(projectID);
    const originalMapper = this.getCodeBar(projectID);
    this._setCodeObject(key, originalMapper, codeID, collapse);
  },

  collapseCodeBar(projectID, codeID)
  {
    this._setCodeBar(projectID, codeID, true);
  },

  expandCodeBar(projectID, codeID)
  {
    this._setCodeBar(projectID, codeID, false);
  },

  setTranscriptLocation(transcriptID, loc, page) {
    if (!!transcriptID && !!loc && !!page) {
      const value = { loc, page };
      this._setToStorage(transcriptID, value);
    }
  },

  getTranscriptLocation(transcriptID) {
    if (!!transcriptID) {
      return this._getFromStorage(transcriptID) || {};
    }
    return {};
  },
};

export default LocalAPI;
